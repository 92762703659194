export default {
  title: 'Mes favoris',
  titleMenu: 'Ma liste',
  myListTitleSrOnly: 'Ma liste : Mes favoris et mes alertes Vidéo à la demande, Orange',
  bookmarksTitleSrOnly: 'Mes favoris, Vidéo à la demande, Orange',
  description: 'Liste de Mes favoris et alertes - Orange VOD',
  mosaic: {
    title: 'Mes favoris ({nbArticles})',
    empty: 'Aucune vidéo n’est ajoutée dans « Mes favoris ». <br><br>Constituez une liste des vidéos que vous souhaitez louer ou acheter en utilisant la fonction « Ajouter à ma liste de favoris » visible sur chaque fiche programme, depuis tous vos écrans (TV, PC, mobiles, tablettes).',
    filterEmpty: 'Aucun élément ne correspond à vos critères',
    trash: 'Supprimer favori'
  },
  buttons: {
    toutAfficher: 'Tout afficher',
    toutAfficherSmall: 'Tout'
  }
}
