import pipe from '../../pipe'
import flexivodUtils from '../flexivod.utils'
import _get from 'lodash/get'

/**
 * Wrapper to Pastille Promotion in function to retrieve satisfied promotion items
 * @param userDiscounts
 * @param coupon
 * @param item
 */
function getItemEligiblePromos (userDiscounts, coupon, item) {
  return pipe(
    flexivodUtils.checkCoupon,
    flexivodUtils.filterSatisfied,
    satisfiedDiscounts => _checkPromoInItem(satisfiedDiscounts, item)
  )(userDiscounts, coupon)
}

// -----------------------------------------------------
/**
 * return items with matching satisfied discounts
 * @param satisfiedDiscounts
 * @param item
 * @returns {*}
 * @private
 */
function _checkPromoInItem (satisfiedDiscounts, item) {
  return _get(item, 'price.discounts', [])
    .filter(discount => satisfiedDiscounts
      .some(satisfiedDiscount => satisfiedDiscount.id === discount.discountId))
}

// -----------------------------------------------------

export default getItemEligiblePromos
