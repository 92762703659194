export default {
  /**
   * Set trailer url to store
   * @param state
   * @param trailer
   */
  setTrailer (state, trailer) {
    state.trailer = trailer
  },
  /**
   * Set isTrailerList boolean to store
   * @param state
   * @param isTrailerList
   */
  setIsTrailerList (state, isTrailerList) {
    state.isTrailerList = isTrailerList
  },
  /**
   *  Set video url to store
   * @param state
   * @param video
   */
  setVideo (state, video) {
    state.video = video
  },
  /**
   * set playlist to store
   * @param  {Object} state
   * @param {Playlist} playlist
   */
  setPlaylist (state, playlist) {
    state.playlist = playlist
  },
  /**
   * Set certificateSafari
   * @param state
   * @param certificate
   */
  setCertificateSafari (state, { certificate }) {
    const byteArray = new Uint8Array(certificate)
    const string = String.fromCharCode.apply(null, byteArray)
    state.certificateSafari = btoa(string)
  },
  /**
   * Set certificateChrome
   * @param state
   * @param certificate
   */
  setCertificateChrome (state, { certificate }) {
    const byteArray = new Uint8Array(certificate)
    const string = String.fromCharCode.apply(null, byteArray)
    state.certificateChrome = btoa(string)
  },
  /**
   * End the current trailer played
   * @param commit
   * @returns {Promise<any | never>}
   */
  setEndOfTrailer (state, isEndOfTrailer) {
    state.endTrailer = isEndOfTrailer
  }
}
