export default {
  fetchCategoryById ({ commit, dispatch }, { categoryId }) {
    return this.$api.tvm.categories.fetchCategoryById(categoryId)
      .then(category => commit('setSelectedCategory', category))
      .catch((err) => {
        dispatch('errors/setAxiosError', { error: { ...err, categoryId } }, { root: true })
        commit('setSelectedCategory', {})
      })
  }
}
