import alerts from './alerts'
import footer from './footer'
import bookmarks from './bookmarks'
import bookmarksAlerts from './bookmarksAlerts'
import corner from './corner'
import filters from './filters'
import fip from './fip'
import global from './global'
import herozone from './herozone'
import meaCategory from './meaCategory'
import menu from './menu'
import mosaic from './mosaic'
import payment from './payment'
import player from './player'
import prepaidAccount from './prepaidAccount'
import utils from './utils'

export default {
  alerts,
  footer,
  bookmarks,
  bookmarksAlerts,
  corner,
  filters,
  fip,
  global,
  herozone,
  meaCategory,
  menu,
  mosaic,
  payment,
  player,
  prepaidAccount,
  utils
}
