import _get from 'lodash/get'
import RouteUtils from '~/route.utils'
import Vue from 'vue'

export function getIntentFilter (pageName, pageArgs, natureCategory) {
  // Add the current page to Intent Filter, and params if they are
  let intentFilter = ''
  switch (pageName) {
    case 'category':
      natureCategory === 'TERMINAL' ? intentFilter += '/categoryTerminal/' + pageArgs.id : intentFilter += '/category/' + pageArgs.id
      break
    case RouteUtils.ROUTE.FIP.MOVIE:
      intentFilter += '/movieDescription/' + pageArgs.id
      break
    case RouteUtils.ROUTE.FIP.EPISODE:
      intentFilter += '/episodeDescription/' + pageArgs.id
      break
    case RouteUtils.ROUTE.FIP.SEASON:
      intentFilter += '/seasonDescription/' + pageArgs.id
      break
    case RouteUtils.ROUTE.FIP.PACKAGE:
      intentFilter += '/packageDescription/' + pageArgs.id
      break
    case 'myVideos':
      intentFilter += '/myVod'
      break
    case 'myBookmarks':
      intentFilter += '/myBookmarks'
      break
    case 'myAccount':
      intentFilter += '/vodTickets'
      break
    default:
      intentFilter += '/home'
  }

  return intentFilter
}

function fallbackToStore (storeUrl) {
  window.location = storeUrl
}

function launchApp (urlScheme) {
  window.location = urlScheme
}

export function launchOrDownloadApp (binding, config) {
  const pageName = _get(binding, 'value.pageName', null)
  const pageArgs = _get(binding, 'value.pageArgs', null)
  const natureCategory = _get(binding, 'value.natureCategory', null)
  const os = binding.value.osName.toLowerCase()

  const serverBaseUrl = _get(config, 'confetti.public.urls.otvpBaseUrl')

  const intentFilter = serverBaseUrl + '/#vod' + getIntentFilter(pageName, pageArgs, natureCategory)
  // Launch intent filter, if error fallback to google play
  if (os === 'android') {
    const fallBackUrl = _get(config, 'confetti.public.urls.stores.android')
    const urlScheme = 'intent://' + intentFilter + '#Intent;scheme=otvp;S.browser_fallback_url=' + fallBackUrl + ';end;' // No need fallback maybe
    launchApp(urlScheme)
    // Launch intent filter, if error fallback to app store
    // ajout de mac os pour gérer la redirection sur ipadOS qui peut avoir le même UA qu'un safari mac
  } else if (os === 'ios' || os === 'mac os') {
    const fallBackUrl = _get(config, 'confetti.public.urls.stores.apple')
    const urlScheme = 'otvp://' + intentFilter
    launchApp(urlScheme)
    setTimeout(() => fallbackToStore(fallBackUrl), 5000)
  }
}

// ----------------------------------------------------

export default ({ store, $config }) => {
  Vue.directive('appMobile', {
    bind (el, binding) {
      // pour le téléchargement d'une vidéo
      el.addEventListener('click',
        launchOrDownloadApp.bind(null, binding, $config),
        { ...binding.modifiers } // eventListener options https://developer.mozilla.org/fr/docs/Web/API/EventTarget/addEventListener#Param.C3.A8tres
      )
    }
  })
}
