import _get from 'lodash/get'
import API_CONFIG from '../../api.constants'

export default {
  getAlerts ({ commit, dispatch }) {
    return this.$api.gateway.alerts.getAlerts()
      .then((response) => {
        if (response.error) {
          dispatch('errors/setAxiosError', { error: response }, { root: true })
          return commit('setAlerts', { data: [], config: this.$config })
        }
        return commit('setAlerts', { data: response, config: this.$config })
      })
  },

  getAlertById ({ commit, dispatch }, videoId) {
    return this.$api.gateway.alerts.getAlertById(videoId)
      .then((response) => {
        if (response.error) {
          if (response.error.status !== 404) {
            dispatch('errors/setAxiosError', { error: response }, { root: true })
          }
          return commit('setAlert', { data: {}, config: this.$config })
        }
        return commit('setAlert', { data: response, config: this.$config })
      })
  },

  setAlert ({ commit, dispatch }, videoId) {
    return this.$api.gateway.alerts.setAlert(videoId)
      .then((response) => {
        // If not auth, allow auth modal to show
        if (response.error) {
          const isNotAuth = API_CONFIG.ERRORS_CODES.TVM_NOT_AUTHENTIFIED.includes(String(_get(response, 'error.data.code', '-')))
          dispatch('errors/setAxiosError', { error: { ...response, videoId }, bypass: !isNotAuth }, { root: true })
          return Promise.reject(isNotAuth)
        }

        return Promise.resolve()
      })
  },

  deleteAlert ({ commit, dispatch }, videoId) {
    return this.$api.gateway.alerts.deleteAlert(videoId)
      .then((response) => {
        if (response.error && response.error.status !== 404) {
          dispatch('errors/setAxiosError', { error: { ...response, videoId }, bypass: true }, { root: true })
          return Promise.reject()
        } else {
          return commit('setAlert', { data: {}, config: this.$config })
        }
      })
  },

  deleteNotifications ({ commit, dispatch }) {
    return this.$api.gateway.alerts.deleteNotifications()
      .then((response) => {
        if (response.error && response.error.status !== 404) {
          dispatch('errors/setAxiosError', { error: { ...response }, bypass: true }, { root: true })
        } else {
          return commit('setCounter', 0)
        }
      })
  },

  getCounterNotification ({ commit, dispatch }) {
    return this.$api.gateway.alerts.getCounterNotification()
      .then((response) => {
        if (response.error) {
          if (response.error.status !== 404) {
            dispatch('errors/setAxiosError', { error: { ...response }, bypass: true }, { root: true })
          }
        }
        return commit('setCounter', response.count)
      })
  }
}
