import _get from 'lodash/get'
import fingerprint from '../../components/shared/fingerprint'

export default {
  getUser (state) {
    return state.user
  },
  getAccountStatus (state) {
    return _get(state, 'user.accountStatus')
  },
  getUserDiscounts (state) {
    return state.userDiscounts
  },
  getActivatedCoupon (state) {
    return state.activatedCoupon
  },
  getMyVideos (state) {
    return state.myVideos
  },
  getMyVideosCategory (state) {
    return state.myVideosCategory
  },
  getCouponCode (state) {
    return state.couponCode
  },
  getApplication (state) {
    return _get(state, 'user.application')
  },
  getPrepaidAccount (state) {
    return state.prepaidAccount
  },
  getHouseholdId (state) {
    return state.user.householdId
  },
  // On utilise fingerprint pour générer un uniqueId identique a ce que faut le hasPlayer (voir méthode getTerminalId() sur leur github)
  // On retourne un fake terminalId si pas de householdId (c'est à dire user deconnecté).
  getUniqueId (state, getters, rootState) {
    const browser = fingerprint.browser()
    const os = fingerprint.os()
    const householdId = _get(state.user, 'householdId')
    const suffixFP = rootState.useragent.userAgent.os?.name.includes('Mac') &&
                     rootState.useragent.userAgent.ua.includes('Safari') &&
                     !rootState.useragent.userAgent.ua.includes('Chrome')
      ? '_FP'
      : ''
    const suffixOPT = rootState.useragent.userAgent.ua && rootState.useragent.userAgent.ua?.includes('OPT') ? '_OPT' : ''
    const terminalId = householdId
      ? [
          os.name.replace(/[()]/g, ''),
          os.bits,
          browser.name,
          householdId
        ].join('-')
      : ''
    return (terminalId + suffixFP + suffixOPT).replace(' ', '')
  },
  getStatsId (state, getters, rootState, rootGetters) {
    return (config) => {
      const terminalModel = rootGetters['terminal/getTerminalModel']
      const appVersion = 'v' + config.appVersion
      const chromeVersion = rootState.useragent.userAgent.browser.name + rootState.useragent.userAgent.browser.major
      const osVersion = rootState.useragent.userAgent.os.name + rootState.useragent.userAgent.os.version

      return 'VOD' + [terminalModel, appVersion, chromeVersion, osVersion].join('_')
    }
  },
  getMyArticlesIds (state) {
    return state.myArticlesIds
  },
  isArticle (state) {
    return articleId => state.myArticlesIds.includes(articleId)
  },
  findMyArticleById (state) {
    return articleId => state.myArticles.find(article => article.id === articleId)
  },
  getPlayHistory (state) {
    return state.playHistory
  },
  findPlayHistoryById (state) {
    return articleId => state.playHistory.find(item => item.id === articleId)
  },
  getApplicationVariant (state) {
    return state.generation?.experience?.element1
  },
  getUserQualification (state) {
    return state.generation?.visitorQualified
  }
}
