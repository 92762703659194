import API from '../../../../api.constants'
import _get from 'lodash/get'
import _has from 'lodash/has'
import pipe from '../../pipe'
import flexivodUtils from '../flexivod.utils'

function infoPromo (userDiscounts, coupon, contentDefinitions, promoType) {
  return pipe(
    flexivodUtils.checkCoupon,
    flexivodUtils.filterSatisfied,
    satisfiedDiscounts => _getContentPrices(satisfiedDiscounts, contentDefinitions, promoType),
    contentPrices => _getContentPricesWithDescription(contentPrices, userDiscounts, contentDefinitions, promoType)
  )(userDiscounts, coupon)
}

// -----------------------------------------------------

/**
 * Get content prices
 * @param satisfiedDiscounts
 * @param definitions
 * @param promoType
 * @returns {{price: (*|number|price|{discounts}), usage: *, discountPrice: *, name: *, description: *, discountId: *, quality: *}[]}
 * @private
 */
function _getContentPrices (satisfiedDiscounts, definitions, promoType) {
  return _flattenPrices(definitions, promoType).map((contentPrice) => {
    const matchingDiscountItem = _getMatchingDiscountItem(contentPrice.discounts, satisfiedDiscounts)
    const matchingSatisfiedDiscount = _getMatchingSatisfiedDiscount(satisfiedDiscounts, matchingDiscountItem)
    return {
      commercializationId: contentPrice.commercializationId,
      terminalModels: contentPrice.terminalModels,
      type: contentPrice.type,
      quality: contentPrice.quality,
      price: contentPrice.price,
      usage: contentPrice.usage,
      hasDiscount: _has(matchingDiscountItem, 'price'),
      discountPrice: matchingDiscountItem && typeof matchingDiscountItem.price !== 'undefined' ? matchingDiscountItem.price : null,
      discountId: _get(matchingDiscountItem, 'discountId', null),
      description: _get(matchingSatisfiedDiscount, 'description', null),
      name: _get(matchingSatisfiedDiscount, 'name', null),
      summary: _get(matchingSatisfiedDiscount, 'summary', null)
    }
  })
}

/**
 * Apply Correct Description in content Price
 * @param contentPrices
 * @param userDiscounts
 * @param contentDefinitions
 * @param promoType
 * @returns {*}
 * @private
 */
function _getContentPricesWithDescription (contentPrices, userDiscounts, contentDefinitions, promoType) {
  return contentPrices.map((contentPrice) => {
    if (!contentPrice.discountId) {
      let nameDescription = _getNameDescriptionFromSatisfied(contentPrices, contentPrice.quality)
      if (!nameDescription.name && !nameDescription.summary) {
        nameDescription = _getNameDescriptionFromUserDiscounts(userDiscounts, contentDefinitions, promoType)
      }
      contentPrice.summary = nameDescription.summary
      contentPrice.name = nameDescription.name
    }

    return contentPrice
  })
}

// -----------------------------------------------------

/**
 * Flatten content prices
 * @param definitions
 * @param promoType
 * @returns {Array}
 * @private
 */
function _flattenPrices (definitions, promoType) {
  const contentPrices = []

  definitions.forEach((definition) => {
    const tvodCommercialization = definition.commercializations.filter(commercialization => commercialization.type === promoType)

    tvodCommercialization.forEach((commercialization) => {
      const contentPrice = {}
      contentPrice.commercializationId = commercialization.id
      contentPrice.terminalModels = commercialization.terminalModels
      contentPrice.type = commercialization.type
      contentPrice.quality = definition.quality
      contentPrice.price = commercialization.price
      contentPrice.usage = commercialization.usage
      contentPrice.discounts = commercialization.discounts
      contentPrices.push(contentPrice)
    })
  })

  return contentPrices
}

/**
 * Get Matching discount items when comparing with satisfied discounts
 * @param itemDiscounts
 * @param satisfiedDiscounts
 * @returns {*}
 * @private
 */
function _getMatchingDiscountItem (itemDiscounts, satisfiedDiscounts) {
  return itemDiscounts.find(itemDiscount => satisfiedDiscounts.some(satisfiedDiscount => satisfiedDiscount.id === itemDiscount.discountId))
}

/**
 * get Matching satisfied discount with a matching discountItem
 * @param matchingDiscountItem
 * @param satisfiedDiscounts
 * @returns {null}
 * @private
 */
function _getMatchingSatisfiedDiscount (satisfiedDiscounts, matchingDiscountItem) {
  return matchingDiscountItem ? satisfiedDiscounts.find(satisfiedDiscount => satisfiedDiscount.id === matchingDiscountItem.discountId) : null
}

/**
 * Get Description based on various case
 * @param contentPrices
 * @param contentPriceQuality
 * @returns {null|*}
 * @private
 */
function _getNameDescriptionFromSatisfied (contentPrices, contentPriceQuality) {
  const contentPricesWithPromo = contentPrices.filter(contentPrice => contentPrice.discountId)
  const sameQualityContentPricesWithPromo = contentPricesWithPromo.filter(contentPrice => contentPrice.quality === contentPriceQuality)
  const sameQualityContentPriceSellPromo = sameQualityContentPricesWithPromo.find(contentPrice => contentPrice.usage === API.PROMO.USAGE_SELL)
  const contentPriceSellPromo = contentPricesWithPromo.find(contentPrice => contentPrice.usage === API.PROMO.USAGE_SELL)

  if (sameQualityContentPriceSellPromo) {
    // Priority 1: Same quality - SELL promo -
    return { summary: sameQualityContentPriceSellPromo.summary, name: sameQualityContentPriceSellPromo.name }
  } else if (sameQualityContentPricesWithPromo.length !== 0) {
    // Priority 2: Same quality
    return { summary: sameQualityContentPricesWithPromo[0].summary, name: sameQualityContentPricesWithPromo[0].name }
  } else if (contentPriceSellPromo) {
    // Priority 3: SELL Promo
    return { summary: contentPriceSellPromo.summary, name: contentPriceSellPromo.name }
  } else if (contentPricesWithPromo.length !== 0) {
    // Priority 4: Default: promo available
    return { summary: contentPricesWithPromo[0].summary, name: contentPricesWithPromo[0].name }
  } else {
    return { summary: null, name: null }
  }
}

/**
 * Retrieve description for user discount when not satisfied
 * @param userDiscounts
 * @param contentDefinitions
 * @param promoType
 * @returns {null}
 * @private
 */
function _getNameDescriptionFromUserDiscounts (userDiscounts, contentDefinitions, promoType) {
  const contentPrices = _flattenPrices(contentDefinitions, promoType)
  const discounts = [].concat(...contentPrices.map(prices => prices.discounts))
  const userDiscountsSatisfiedOrNot = userDiscounts.filter(userDiscount => (userDiscount.eligibility === API.PROMO.NOT_SATISFIED) || (userDiscount.eligibility === API.PROMO.SATISFIED))
  const userDiscountMatching = userDiscountsSatisfiedOrNot.find(userDiscountSatisfiedOrNot => discounts.some(discount => discount.discountId === userDiscountSatisfiedOrNot.id))

  return userDiscountMatching ? { summary: userDiscountMatching.summary, name: userDiscountMatching.name } : { summary: null, name: null }
}

// -----------------------------------------------------

export default infoPromo
