import _get from 'lodash/get'

export default {
  /**
   * Get terminals
   * @returns {Promise<T | never>}
   * @param category
   * @param osForTerminalModel
   * @param manufacturer
   * @param osAndVersion
   */
  getTerminalModel (category, osForTerminalModel, manufacturer, osAndVersion) {
    const path = _get(this.$config, 'confetti.public.api.tvm.paths.terminals')
    const method = 'api.terminals.getTerminalModel()'
    const options = {
      params: {
        category,
        manufacturer,
        type: category,
        os: osForTerminalModel,
        osVersion: osAndVersion
      },
      cache: {
        exclude: { query: false }
      }
    }

    return this.$http.tvm.notAuth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  }
}
