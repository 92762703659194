import _get from 'lodash/get'
export default {
  getTerminalModel (state) {
    return _get(state, 'terminalModel', 'BROWSER_WIN')
  },
  getCategory (state, getters, rootState, rootGetters) {
    let category = 'BROWSER'
    const browserName = rootGetters['useragent/getBrowserName']
    // Parfois ua-parser met undefined sur le type du device, souvent pour les tablette
    // Du coup si le cas se produit et que l'os est mobile on renvoit TABLET pour eviter PC
    if (browserName) {
      if (browserName.includes('Safari') || browserName.includes('FxiOS') || browserName.includes('CriOS')) {
        category = 'SAFARI'
      } else {
        category = 'BROWSER'
      }
    }
    return category
  },
  getOSForTerminalModel (state, getters, rootState, rootGetters) {
    let osForTerminalModel = 'WIN'
    const osName = rootGetters['useragent/getOsName']
    const browserName = rootGetters['useragent/getBrowserName']
    if (osName === 'iOS' || osName === 'Android') {
      osForTerminalModel = osName.toUpperCase()
    } else if (osName === 'Mac OS' && browserName === 'Safari') {
      osForTerminalModel = 'MAC'
    } else if (osName === 'Mac OS') {
      osForTerminalModel = 'MAC'
    }

    return osForTerminalModel
  },
  getManufacturer (state, getters, rootState, rootGetters) {
    return rootGetters['useragent/getBrowserName'] + '_' + rootGetters['useragent/getBrowserMajor']
  },
  getOsAndVersion (state, getters, rootState, rootGetters) {
    return rootGetters['useragent/getOsName'] + rootGetters['useragent/getOsVersion']
  },
  getTerminal (state, getters, rootState, rootGetters) {
    return rootGetters['terminals/getCategory'] + '_' + rootGetters['terminals/getOSForTerminalModel']
  }
}
