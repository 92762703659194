import pipe from '../../pipe'
import flexivodUtils from '../flexivod.utils'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _get from 'lodash/get'

/**
 * Wrapper to filter Promotion in function to retrieve satisfied promotion items
 * @param userDiscounts
 * @param coupon
 * @param items
 * @param articleIds
 */
function filterPromo (userDiscounts, coupon, items, articleIds) {
  return pipe(
    flexivodUtils.checkCoupon,
    flexivodUtils.filterSatisfied,
    satisfiedDiscounts => _checkPromoInItem(satisfiedDiscounts, items),
    items => _filterAlreadyBought(items, articleIds)
  )(userDiscounts, coupon)
}

// -----------------------------------------------------

/**
 * return items with matching satisfied discounts
 * @param satisfiedDiscounts
 * @param items
 * @returns {*}
 * @private
 */
function _checkPromoInItem (satisfiedDiscounts, items) {
  return _filter(items,
    item => _find(_get(item, 'price.discounts'),
      discount => _find(satisfiedDiscounts,
        satisfiedDiscount => satisfiedDiscount.id === discount.discountId)))
}

/**
 *
 * @param items
 * @param articleIds
 * @returns {*}
 * @private
 */
function _filterAlreadyBought (items, articleIds = []) {
  return items.filter(item => !articleIds.includes(item.id))
}

// -----------------------------------------------------

export default filterPromo
