export default {
  incompatibleConfiguration: {
    title: 'Problème de configuration',
    content: 'La configuration de votre navigateur ne permet pas de visionner vos vidéos.',
    button: {
      label: 'Configurations compatibles',
      aria: 'Ouvrir l\'aide sur les configurations compatibles'
    }
  },
  streamIssue: {
    title: 'Une erreur est survenue',
    content: 'Merci d\'essayer à nouveau. Si le problème persiste, veuillez contacter le support via le lien "Contact" du menu Aide.',
    button: {
      label: 'Relancer la lecture',
      aria: 'Relancer la lecture'
    }
  },
  mediaRestricted: {
    title: 'Une erreur est survenue',
    content: 'Votre configuration ne permet pas de lire certaines vidéos protégées. Nous vous invitons à changer de navigateur ou à déconnecter vos écrans externes.',
    button: {
      label: 'Relancer la lecture',
      aria: 'Relancer la lecture'
    }
  },
  others: {
    title: 'Une erreur est survenue',
    content: 'Nous vous conseillons d\'actualiser la page. Si le problème persiste, veuillez contacter le support via le lien "Contact" du menu Aide.',
    button: {
      label: 'Recharger la page',
      aria: 'Recharger la page'
    }
  },
  default: {
    title: 'Une erreur est survenue',
    content: 'Merci d\'essayer à nouveau. Si le problème persiste, veuillez contacter le support via le lien "Contact" du menu Aide.',
    button: {
      label: 'Relancer la lecture',
      aria: 'Relancer la lecture'
    }
  }
}
