function onClickBookmarksTitle () {
  return {
    track_page: 'mes_favoris',
    track_zone: 'bandeau',
    track_nom: 'titre-mes_favoris',
    track_type: 'clic',
    track_cible: 'mes favoris',
    track_detail: 'clic-titre-bandeau-mes_favoris'
  }
}

function onClickBookmarksButton () {
  return {
    track_page: 'mes_favoris',
    track_zone: 'bandeau',
    track_nom: 'tout_afficher-mes_favoris',
    track_type: 'clic',
    track_cible: 'mes favoris',
    track_detail: 'clic-tout_afficher-bandeau-mes_favoris'
  }
}

function onClickAlertsTitle () {
  return {
    track_page: 'mes_favoris',
    track_zone: 'bandeau',
    track_nom: 'titre-alerte',
    track_type: 'clic',
    track_cible: 'mes alertes',
    track_detail: 'clic-titre-bandeau-mes_alertes'
  }
}

function onClickAlertsButton () {
  return {
    track_page: 'mes_favoris',
    track_zone: 'bandeau',
    track_nom: 'tout_afficher-alerte',
    track_type: 'clic',
    track_cible: 'mes alertes',
    track_detail: 'clic-tout_afficher-bandeau-mes_alertes'
  }
}

function onClickDeleteItemFromBookmarksAlerts (item) {
  return {
    page: 'mes_favoris',
    zone: 'bandeau',
    area: 'supprimer',
    cible: `${item.type}-${item.id}-${item.title}`,
    type: 'clic'
  }
}

export default {
  onClickBookmarksTitle,
  onClickBookmarksButton,
  onClickAlertsTitle,
  onClickAlertsButton,
  onClickDeleteItemFromBookmarksAlerts
}
