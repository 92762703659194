import { DateTime } from 'luxon'

export default function (dateYmd) {
  if (!dateYmd || dateYmd < 0) {
    return ''
  }

  const date = DateTime.fromISO(`${dateYmd}`)
  return date.toFormat('dd/MM/yyyy')
}
