import _get from 'lodash/get'
import urlJoin from 'url-join'
import logger from '@wptv/logger'

export default {
  /**
   * Get user
   * @returns {Promise<T | never>}
   */
  getUser () {
    const path = _get(this.$config, 'confetti.public.api.tvm.paths.users')
    const options = {
      params: {
        applicationFamily: _get(this.$config, 'confetti.public.api.tvm.applicationFamily')
      }
    }
    const method = 'api.user.getUser()'

    return this.$http.tvm.auth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  },

  /**
   * Get Myvideos information
   * @returns {Promise<T | never>}
   */
  getMyVideos () {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.myVideos'),
      _get(this.$config, 'confetti.public.api.tvm.universeId'))
    const method = 'api.user.getMyVideos()'

    return this.$http.tvm.auth.get(path)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method, bypass: true }))
  },

  /**
   * Get articles from my-videos
   * @returns {Promise<T | never>}
   */
  fetchMyArticles () {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.myVideos'),
      _get(this.$config, 'confetti.public.api.tvm.universeId'),
      '/articles?view=simple'
    )
    const method = 'api.myvideos.fetchMyArticles()'

    return this.$http.tvm.auth.get(path)
      .then(response => this.$httpUtils.parseResponse(response, true))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method, bypass: true }))
  },

  /**
   * Fetch article from my-videos with a specific articleId
   * @param articleId
   * @returns {Promise<any | never>}
   */
  fetchMyArticleById (articleId) {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.myVideos'),
      _get(this.$config, 'confetti.public.api.tvm.universeId'),
      'articles',
      articleId
    )
    const method = 'api.myvideos.fetchMyArticleById()'

    return this.$http.tvm.auth.get(path)
      .then(response => this.$httpUtils.parseResponse(response))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method, bypass: true }))
  },

  /**
   * Get MyvideosCategory information
   * @returns {Promise<T | never>}
   */
  getMyVideosCategory (categoryId) {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.myVideos'),
      _get(this.$config, 'confetti.public.api.tvm.universeId'),
      _get(this.$config, 'confetti.public.api.tvm.paths.categories'),
      categoryId
    )
    const method = 'api.user.getMyVideosCategory()'

    return this.$http.tvm.auth.get(path)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method, bypass: true }))
  },

  /**
   * Get user discounts
   * @returns {Promise<T | never>}
   */
  getUserDiscounts () {
    const path = _get(this.$config, 'confetti.public.api.tvm.paths.discounts')
    const method = 'api.user.getUserDiscounts'

    return this.$http.tvm.auth.get(path)
      .then(response => this.$httpUtils.parseResponse(response, true))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method, bypass: true }))
  },

  /**
   * Check the coupon entered for the discounts
   * @returns {Promise<T | never>}
   */
  getCouponDiscount (coupon) {
    const path = urlJoin(
      _get(this.$config, 'confetti.public.api.tvm.paths.coupon'),
      `/${coupon}`)
    const method = 'api.user.getCouponDiscount()'

    return this.$http.tvm.auth.get(path)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  },

  /**
 * Get Prepaid Account informations
 * @returns {Promise<T | never>}
 */
  getPrepaidAccount () {
    const path = _get(this.$config, 'confetti.public.api.tvm.paths.prepaidAccount')
    const method = 'api.user.getPrepaidAccount()'
    return this.$http.tvm.auth.get(path)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  },

  /**
   * Check the ticket entered for the Prepaid Account
   * @returns {Promise<T | never>}
   */
  putPrepaidAccount (ticketCode) {
    const path = _get(this.$config, 'confetti.public.api.tvm.paths.prepaidAccount')
    const method = 'api.user.putPrepaidAccount()'

    return this.$http.tvm.auth.put(path, { vodTicketCode: ticketCode })
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  },

  /**
   * Post the error logs encountered by the user
   * @returns {Promise<T | never>}
   */
  postErrorLogs (error) {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.errorLogs'))

    return this.$http.tvm.auth.post(path, error)
      .catch((responseError) => {
        // we don't catch error with http-utils because we don't want to log neither show it
        const message = _get(responseError, 'message', 'no message')
        logger.error(`Error::api.user.postErrorLogs():${message}:${path}`)
        logger.debug(responseError)
      })
  },

  /**
   * Get payment infos
   * @returns {Promise<T | never>}
   */
  getPaymentInfos (price) {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.paymentInfos'))
    const method = 'api.user.getPaymentInfos()'

    return this.$http.tvm.auth.get(path, { params: price })
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  },
  /**
   * Post order
   * @returns {Promise<T | never>}
   */
  createOrder (params) {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.order'))
    const method = 'api.user.createOrder()'

    return this.$http.tvm.auth.post(path, params)
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  }
}
