function onClickFaq (faq) {
  return {
    track_page: 'mon_cpp',
    track_zone: 'ticket_video',
    track_nom: 'faq',
    track_type: 'clic',
    track_cible: faq,
    track_detail: 'clic-faq-ticket_video-mon_cpp'
  }
}

function onClickCgu (termsOfService) {
  return {
    track_page: 'mon_cpp',
    track_zone: 'ticket_video',
    track_nom: 'conditions_generales_utilisation',
    track_type: 'clic',
    track_cible: termsOfService,
    track_detail: 'clic-conditions_generales_utilisation-ticket_video-mon_cpp'
  }
}

function onClickLearnMore (learnMore) {
  return {
    track_page: 'mon_cpp',
    track_zone: 'ticket_video',
    track_nom: 'en_savoir_plus',
    track_type: 'clic',
    track_cible: learnMore,
    track_detail: 'clic-en_savoir_plus-ticket_video-mon_cpp'
  }
}

function onClickBuyTicket () {
  return {
    track_page: 'mon_cpp',
    track_zone: 'ticket_video',
    track_nom: 'acheter',
    track_type: 'clic',
    track_cible: '',
    track_detail: 'clic-acheter-ticket_video-mon_cpp'
  }
}

export default {
  onClickFaq,
  onClickCgu,
  onClickLearnMore,
  onClickBuyTicket
}
