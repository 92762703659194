<template>
  <transition name="fade">
    <div
      v-show="visible"
      id="vodSearchEngineModal">
      <div id="vodSearchEngine" />
      <button
        id="vodSearchEngineClose"
        v-tealium:click="{
          track_page: 'toutes_pages',
          track_zone: 'recherche',
          track_nom: 'fermer_cartouche',
          track_type: 'clic',
          track_cible: '',
          track_detail: 'clic-fermer_cartouche-recherche-toutes_pages'
        }"
        :title="$t('general.menu.searchCloseCrossLabel')"
        data-wptv="recherche-fermer_cartouche"
        @click="toggle()">
        <wptv-icon
          :invert="true"
          name="close-cross"
          height="22px"
          width="22px" />
      </button>
    </div>
  </transition>
</template>

<script>
import { WptvIcon } from '@wptv/components/ui/icon'

export default {
  name: 'VodSearch',
  components: { WptvIcon },
  data () {
    return {
      visible: false
    }
  },
  methods: {
    /**
     * Switch visibility and focus on search input
     * using querySelectorAll due to two elements when init on mobile so always taking the last one
     */
    toggle () {
      this.visible = !this.visible

      if (this.visible) {
        this.$nextTick(() => {
          const searchInputElements = document.querySelectorAll('.o-search-input')

          if (searchInputElements && searchInputElements.length !== 0) {
            searchInputElements[searchInputElements.length - 1].focus()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  #vodSearchEngineModal {
    z-index: 20;
    display: flex;
    align-content: center;
    flex-direction: column;
    background: $black;
    position: absolute;
    width: 100%;

    &:before {
      content: '';
      width: 100%;
      border-top: 1px #888 solid;
    }

    #vodSearchEngine {
      padding: 30px 0;
      margin: 0 auto;
      margin-top: 15px;
    }

    #vodSearchEngineClose {
      border: none;
      background: none;
      position: absolute;
      bottom: 70px;
      right: 10px;
      cursor: pointer;

      &:hover, &:focus {
        outline: none;

        ::v-deep svg {
          fill: $primary !important;
        }
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

</style>
