import _get from 'lodash/get'

export default {
  /**
   * Return the redirectTo Url obj
   * @param state
   * @returns {*}
   */
  getRedirectTo (state) {
    return state.redirectTo ? state.redirectTo : { path: '/' }
  },
  /**
   * Get previous and next fip datas for a specific one
   * @param state
   * @returns {Function}
   */
  getPreviousNext: state => (id) => {
    const historyItems = state.history[state.history.length - 1] || []
    const currentIdx = historyItems.indexOf(historyItems.find((item) => {
      return (
        item.id === id ||
        item.browsingSeasonId === id ||
        !!_get(item, 'seasons', []).find(season => season.id === id)
      )
    }))
    const lastItem = _get(historyItems, `[${historyItems.length - 1}]`, null)
    const firstItem = _get(historyItems, '[0]', null)

    return {
      previous: _get(historyItems, `[${currentIdx - 1}]`, lastItem),
      next: _get(historyItems, `[${currentIdx + 1}]`, firstItem)
    }
  },
  /**
   * return store history items
   * @param state
   * @returns {*}
   */
  getHistory (state) {
    return state.history
  },
  /**
   * return selectedQuality
   * @param state
   * @returns {String}
   */
  getSelectedQuality (state) {
    return state.selectedQuality
  }
}
