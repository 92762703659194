import _get from 'lodash/get'
import API from './api.constants'
import getDecoratedTitle from './plugins/filters/getDecoratedTitle/getDecoratedTitle.filter'

export default {
  ROUTE: {
    HOME: 'home',
    CORNER: 'corner',
    VIDEOS: 'myVideos',
    LIST: 'myBookmarksAlerts',
    ACCOUNT: 'myAccount',
    FIP: {
      CATEGORY: 'category',
      EPISODE: 'episode',
      MOVIE: 'film',
      PACKAGE: 'package',
      SEASON: 'season'
    }
  },

  /**
  * Retrieve correct page route name for given VOD item
  * @param type
  * @param videoType
  * @returns {string}
  */
  getPageRouteName (type, videoType) {
    switch (type) {
      case API.RESOURCE_TYPES.CATEGORY:
      case API.RESOURCE_TYPES.DISCOUNT_CATEGORY:
        return this.ROUTE.FIP.CATEGORY
      case API.RESOURCE_TYPES.PACKAGE:
        return this.ROUTE.FIP.PACKAGE
      case API.RESOURCE_TYPES.SEASON:
      case API.RESOURCE_TYPES.SERIES:
        return this.ROUTE.FIP.SEASON
      case API.RESOURCE_TYPES.VIDEO:
        return videoType === API.RESOURCE_TYPES.EPISODE ? this.ROUTE.FIP.EPISODE : this.ROUTE.FIP.MOVIE
      case API.RESOURCE_TYPES.CORNER:
        return this.ROUTE.CORNER
      default:
        return ''
    }
  },

  /**
   * Get parameters for the link based on the historyItem
   * @param item
   * @returns {{itemTitle: string, id: (*)}|{episodeTitle: string, id: (*)}}
   */
  getPageRouteParams (item, promo) {
    const itemType = item.videoType || item.type
    switch (itemType) {
      case API.RESOURCE_TYPES.SERIES:
      {
        const browsingId = promo?.browsingInfo?.browsingSeasonId || item.browsingSeasonId
        const browsingSeason = item.seasons?.find(season => season.id === browsingId) || item.seasons?.[0]
        return {
          id: browsingId,
          itemTitle: getDecoratedTitle(browsingSeason.title)
        }
      }
      case API.RESOURCE_TYPES.EPISODE:
        return {
          seasonId: _get(item, 'seasonInfo.id'),
          seasonTitle: getDecoratedTitle(_get(item, 'seasonInfo.title')),
          id: item.id,
          episodeTitle: getDecoratedTitle(item.title)
        }
      default:
        return {
          id: item.id,
          itemTitle: getDecoratedTitle(item.title)
        }
    }
  },

  /**
   *
   * @param category
   * @returns {{name: string, params: {itemTitle: (*|null), id}}}
   */
  createRouteFromConfigMenu (category) {
    return {
      name: this.ROUTE.FIP.CATEGORY,
      params: { id: category.id, itemTitle: this.decorateTitle(category.title) }
    }
  },

  /**
   *
   * @param title
   * @returns {*|null}
   */
  decorateTitle (title) {
    return title ? getDecoratedTitle(title) : null
  }
}
