import _get from 'lodash/get'
import urlJoin from 'url-join'

export default {
  /**
   * Get Bookmarks
   * @returns {Promise<T | never>}
   */
  getBookmarks () {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.bookmarks'))
    const method = 'api.bookmarks.getBookmarks()'

    return this.$http.tvm.auth.get(path)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method, bypass: true }))
  },

  /**
   * Add Bookmark
   * @param article
   * @returns {Promise<T | never>}
   */
  addBookmark (article) {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.bookmarks'))
    const method = 'api.bookmarks.addBookmark()'

    return this.$http.tvm.auth.post(path, { articleType: article.type, articleId: article.id })
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  },

  /**
   *  Delete Bookmarks
   *  @param bookmarkId
   *  @returns {Promise<T | never>}
   */
  deleteBookmark (bookmarkId) {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.bookmarks'),
      bookmarkId)
    const method = 'api.bookmarks.deleteBookmark()'

    return this.$http.tvm.auth.delete(path)
      .then(() => Promise.resolve('success'))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method, bypass: true }))
  }
}
