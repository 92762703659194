export default {
  /**
   * Set datas for catempg categories
   * getting only needed data to avoid large bundle with unused data
   * @param state
   * @param catalogs
   */
  setCategories (state, catalogs) {
    if (catalogs && catalogs.categories) {
      state.categories = catalogs.categories.map(({ id, nature, title, name, highlights, type }) => {
        highlights.items = highlights.items.map(({ parentCategories, eligibleTerminalModels, ...item }) => item)
        return { id, nature, title, name, highlights, type, isTop: id === this.$config.confetti.public.topCategory }
      })
    } else {
      state.categories = []
    }
  },
  setHerozone (state, herozone) {
    state.herozone = herozone
  }
}
