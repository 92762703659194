import _get from 'lodash/get'
import urlJoin from 'url-join'

export default {
  /**
   * Fetch video url
   * @param playId
   * @param terminalId
   * @param terminalModel
   * @param giftId
   * @returns {Promise<T | never>}
   */
  fetchVideo (terminalModel, terminalId, playId, giftId) {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.users'),
      _get(this.$config, 'confetti.public.api.tvm.paths.playableVideos'),
      playId
    )

    const options = {
      params: {
        deliveryMode: 'STREAMING',
        uniqueId: terminalId,
        terminalModel
      }
    }

    if (giftId) {
      options.params.giftId = giftId
    }
    const method = 'api.playableVideo.fetchVideo()'

    return this.$http.tvm.auth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  },

  /**
   * End a session with a specific id
   * @param sessionId
   * @returns {Promise<void | never>}
   */
  endSession (sessionId) {
    const path = urlJoin(
      _get(this.$config, 'confetti.public.api.tvm.paths.playSessions'),
      sessionId
    )
    const method = 'api.playableVideo.endSession()'

    return this.$http.tvm.notAuth.delete(path)
      .then(() => Promise.resolve())
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  }
}
