import API from '../../../api.constants'

/**
 * Put satisfied eligibility into Coupon CLIENT_RESPONSIBILITY if matching
 * @param userDiscounts
 * @param coupon
 * @returns {*}
 */
function checkCoupon (userDiscounts, coupon) {
  if (userDiscounts) {
    return userDiscounts.map((element) => {
      if (element.eligibility === API.PROMO.CLIENT_RESPONSIBILITY && element.id === coupon) {
        element.eligibility = API.PROMO.SATISFIED
      }
      return element
    })
  }
}

/**
 * Filter only satisfied discounts
 * @param discountsWithCoupon
 * @returns {*}
 */
function filterSatisfied (discountsWithCoupon) {
  if (discountsWithCoupon) {
    return discountsWithCoupon.filter(discount => discount.eligibility === API.PROMO.SATISFIED)
  }
}

// -----------------------------------------------

export default {
  filterSatisfied,
  checkCoupon
}
