import _get from 'lodash/get'
import externalCertificate from './external/certificate'
import gatewayAlerts from './gateway/alerts'
import generations from './maxymiser/generations'
import playback from './gateway/playback'
import playHistory from './gateway/playHistory'
import tvmBookmarks from './tvm/bookmarks'
import tvmCatalogs from './tvm/catalogs'
import tvmCategories from './tvm/categories'
import tvmCorners from './tvm/corners'
import tvmPackages from './tvm/packages'
import tvmPlayableTrailers from './tvm/playableTrailers'
import tvmPlayableVideos from './tvm/playableVideos'
import tvmRecommendations from './tvm/recommendations'
import tvmSeasons from './tvm/seasons'
import tvmTerminals from './tvm/terminals'
import tvmUsers from './tvm/users'
import tvmVideos from './tvm/videos'

/**
 * Factory to create api object for specific module
 * @param $config
 * @param $http
 * @param $httpUtils
 * @param store
 * @param req
 * @param env
 * @returns *
 */
function apiFactory ($config, $http, $httpUtils, store, req, env) {
  // Helper to retrieve user application from store and fallback with confetti
  const getApplication = () => store.getters['user/getApplication'] || _get($config, 'confetti.public.api.tvm.defaultApplication')

  return module => ({ $config, $http, $httpUtils, getApplication, ...module })
}

/**
 * Plugin to set $api
 * @param app
 * @param req
 * @param store
 * @param $config
 * @param $http
 * @param $httpUtils
 * @param env
 * @param inject
 */
export default ({ app, req, store, $config, $http, $httpUtils, env }, inject) => {
  const api = apiFactory($config, $http, $httpUtils, store, req, env)

  inject('api', {
    external: {
      certificate: api(externalCertificate)
    },
    tvm: {
      terminals: api(tvmTerminals),
      bookmarks: api(tvmBookmarks),
      catalogs: api(tvmCatalogs),
      categories: api(tvmCategories),
      corners: api(tvmCorners),
      packages: api(tvmPackages),
      playableTrailers: api(tvmPlayableTrailers),
      playableVideos: api(tvmPlayableVideos),
      recommendations: api(tvmRecommendations),
      seasons: api(tvmSeasons),
      users: api(tvmUsers),
      videos: api(tvmVideos)
    },
    gateway: {
      alerts: api(gatewayAlerts),
      playHistory: api(playHistory),
      playback: api(playback)
    },
    maxymiser: {
      generations: api(generations)
    }
  })
}
