<template>
  <div
    id="modalThematique"
    class="thematic-filters"
    tabindex="-1">
    <div class="thematic-filters-content">
      <button
        id="back"
        data-wptv="back-button-filters-modal"
        class="back-button hide-from-medium"
        @click="hideFilters(true)">
        <wptv-icon name="back" />
        Retour
      </button>
      <button
        id="cross"
        aria-label="fermer"
        data-wptv="close-button-filters-modal"
        class="close-button show-from-medium"
        @click="hideFilters(true)">
        <wptv-icon
          :specific-alt="''"
          name="close-cross"
          height="15px"
          width="15px" />
      </button>
      <h3 class="title no-signature">
        {{ $t('sort.popupTitle') }}
      </h3>
      <wptv-chips-bar
        :items="filtersItems"
        :checked-items-list="checkedFilters"
        data-wptv="filters-chips-modal"
        @chipsbar-list-updated="updateList" />
      <button
        class="disable-filters"
        data-wptv="reset-button-filters-modal"
        @click="resetFilters()">
        <span class="icon icon-delete" />
        Désactiver tous les filtres ({{ filtersToApply.length }})
      </button>
      <div class="validate">
        <wptv-button
          data-wptv="validate-button-filters-modal"
          type="wired"
          @click.native="applyFilters()">
          {{ !sortedItems.length ?
            'Aucun résultat' :
            `Afficher ${sortedItems.length > 1 ? 'les' : ''} ${sortedItems.length} résultat${sortedItems.length > 1 ? 's' : ''}` }}
        </wptv-button>
      </div>
    </div>
  </div>
</template>

<script>
import { WptvChipsBar } from '@wptv/components/ui/chipsBar'
import { WptvButton } from '@wptv/components/ui/button'
import { WptvIcon } from '@wptv/components/ui/icon'
import { mapGetters } from 'vuex'
import SortAndFilterUtils from '../../shared/sortAndFilter/sortAndFilter.utils'
import { setFocusOnFirstFocusableChild } from '@wptv/components/utils'
import CONSTANT from '~/api.constants'

export default {
  name: 'ThematiqueFilters',
  components: {
    WptvChipsBar,
    WptvButton,
    WptvIcon
  },
  props: {
    itemsToSortAndFilter: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      filtersToApply: [...this.$store.getters['categories/getSelectedCategories']],
      sortedItems: []
    }
  },
  computed: {
    ...mapGetters({
      categories: 'categories/getFiltersOptionsAvailable',
      selectedFilters: 'categories/getSelectedCategories',
      userDiscounts: 'user/getUserDiscounts',
      activatedCoupon: 'user/getActivatedCoupon',
      articlesIds: 'user/getMyArticlesIds'
    }),
    filtersItems () {
      const maxRentPrice = this.$config.confetti.public.rentMaxPrice.replace('.', ',')
      const maxBuyPrice = this.$config.confetti.public.buyMaxPrice.replace('.', ',')
      return [
        { id: 'popup-all', label: 'Tout' },
        ...this.categories.map((e) => {
          if (e.label) {
            return {
              id: `popup-${e.value}`,
              label: e.label
            }
          } else {
            return {
              id: `popup-${e.value}`,
              label: this.$t(`filters.filterOptions.${e.value}`, { maxRentPrice, maxBuyPrice })
            }
          }
        })
      ]
    },
    checkedFilters () {
      if (this.filtersToApply && this.filtersToApply[0]?.length > 0) {
        return this.filtersToApply.map(e => e.includes('popup') ? e : `filter-chip-popup-${e}`)
      } else {
        return []
      }
    }
  },
  mounted () {
    if (this.filtersToApply && this.filtersToApply[0]?.length > 0 && !this.filtersToApply[0].includes('-popup-')) {
      this.filtersToApply = this.filtersToApply.map(e => `filter-chip-popup-${e}`)
    }
    this.sortedItems = SortAndFilterUtils.filtersItems(this.itemsToSortAndFilter, this.filtersToApply.map(e => e.slice(18)), this.userDiscounts, this.activatedCoupon, this.articlesIds, this.$config.confetti.public)
    this.focusModal()
    window.addEventListener('resize', this.focusModal)
  },
  methods: {
    updateList (val) {
      const allIndex = val.indexOf(CONSTANT.CHIP_ALL_POPUP_VALUE)
      const previousFilters = [...this.filtersToApply]
      if (val.length === 0 || (val.length === 1 && allIndex === 0) || val[val.length - 1] === CONSTANT.CHIP_ALL_POPUP_VALUE) {
        this.filtersToApply = [CONSTANT.CHIP_ALL_POPUP_VALUE]
      } else if (val.includes(CONSTANT.CHIP_ALL_POPUP_VALUE)) {
        this.filtersToApply = val.slice(0, allIndex).concat(val.slice(allIndex + 1))
      } else {
        this.filtersToApply = val
      }
      this.sortedItems = SortAndFilterUtils.filtersItems(this.itemsToSortAndFilter, this.filtersToApply.map(e => e.slice(18)), this.userDiscounts, this.activatedCoupon, this.articlesIds, this.$config.confetti.public)
      this.updateSendStats(val, previousFilters)
    },
    updateSendStats (val, previousFilters) {
      // stats
      if (val && (val[val.length - 1] !== CONSTANT.CHIP_ALL_POPUP_VALUE || val[val.length - 1] !== 'all')) {
        if (val.length === previousFilters.length + 1) {
          this.sendStats(this.$stats.filters.onActivateFilter(val[val.length - 1].slice(18), this.filtersToApply.map(e => e.slice(18)).join(',')))
        } else if (val.length === previousFilters.length - 1) {
          let deletedItem
          let i = 0
          while (!deletedItem && i <= previousFilters.length - 1) {
            if (!this.filtersToApply.find(e => e === previousFilters[i])) {
              deletedItem = previousFilters[i].slice(18)
            }
            i++
          }
          this.sendStats(this.$stats.filters.onDisactivateFilter(deletedItem, this.filtersToApply.map(e => e.slice(18)).join(',')))
        }
      }
    },
    hideFilters (byCloseButton = false) {
      if (byCloseButton) {
        this.sendStats(this.$stats.filters.onCloseModalFilters())
      } else {
        this.sendStats(this.$stats.filters.onApplyMultipleFilters(this.sortedItems.length, this.filtersToApply.join(',')))
      }
      this.$root.$emit('showFilters', false, this.sortedItems)
      this.focusFilters()
    },
    resetFilters () {
      this.sendStats(this.$stats.filters.onResetAllFilters())
      this.filtersToApply = [CONSTANT.CHIP_ALL_POPUP_VALUE]
      this.sortedItems = SortAndFilterUtils.filtersItems(this.itemsToSortAndFilter, this.filtersToApply.map(e => e.slice(18)), this.userDiscounts, this.activatedCoupon, this.articlesIds, this.$config.confetti.public)
    },
    applyFilters () {
      const filters = [...this.selectedFilters]
      this.filtersToApply = this.filtersToApply.map(e => e.slice(18))
      let isSame = this.filtersToApply.length === filters.length
      if (isSame) {
        filters.forEach((filter) => {
          if (!this.filtersToApply.includes(filter)) {
            isSame = false
          }
        })
      }
      if (!isSame) {
        this.$store.commit('mosaicItems/setFilteredItemsFromModal', this.sortedItems)
        this.$store.commit('categories/setSelectedCategories', { categories: this.filtersToApply, router: this.$router, route: this.$route })
      }
      this.hideFilters()
    },
    focusModal () {
      // add all the elements inside modal which you want to make focusable
      if (document.querySelector('#modalThematique')) {
        const focusableElements =
          'button, [tabindex]:not([tabindex="-1"])'
        const modal = document.querySelector('#modalThematique') // select the modal by it's id

        const focusableContent = [...modal.querySelectorAll(focusableElements)]
        const focusableContentArray = Array.from(focusableContent)
        const buttonCross = document.getElementById('cross')
        const firstFocusableElement = window.getComputedStyle(buttonCross).display === 'none' ? focusableContentArray[0] : focusableContentArray[1] // get first element to be focused inside modal
        window.getComputedStyle(buttonCross).display === 'none' ? focusableContentArray.splice(focusableContentArray.find(e => e.id === 'cross'), 1) : focusableContent.splice(focusableContent.find(e => e.id === 'back'), 1)
        const lastFocusableElement = focusableContentArray[focusableContentArray.length - 1] // get last element to be focused inside modal

        document.addEventListener('keydown', function (e) {
          const isTabPressed = e.key === 'Tab' || e.keyCode === 9
          if (!isTabPressed) {
            return
          }

          if (e.shiftKey) { // if shift key pressed for shift + tab combination
            if (document.activeElement === firstFocusableElement) {
              lastFocusableElement.focus() // add focus for the last focusable element
              e.preventDefault()
            }
          } else if (document.activeElement === lastFocusableElement) { // if tab key is pressed
            // if focused has reached to last focusable element then focus first focusable element after pressing tab
            e.preventDefault()
            firstFocusableElement.focus() // add focus for the first focusable element
          }
        })
        firstFocusableElement.focus()
      }
    },
    focusFilters () {
      // [WEBTV-5988] we want to focus "plus de filtres" after closing modal
      setFocusOnFirstFocusableChild(document.querySelector('#btnMore'))
    }
  }
}
</script>

<style lang="scss" scoped>
.thematic-filters {
  position: fixed;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);

  .thematic-filters-content {
    width: 100%;
    background: $white;
    @include mediaQueries('position', (medium: absolute));
    @include mediaQueries('top', (medium: 50%));
    @include mediaQueries('left', (medium: 50%));
    @include mediaQueries('transform', (medium: translate(-50%, -50%)));
    @include mediaQueries('max-width', (medium: 590px, large: 770px));
    @include mediaQueries('min-height', (xsmall: 100%, medium: auto));
    @include mediaQueries('padding', (xsmall: 20px 15px, medium: 50px 45px));
    &:focus {
      outline-color: transparent;
    }
    &:focus-visible {
      outline: solid 2px $primary;
    }
  }

  .close-button {
    position: absolute;
    top: 30px;
    right: 30px;
    border: none;
    background: none;
    cursor: pointer;
    outline: none;
    &:focus {
      outline-color: transparent;
    }
    &:focus-visible {
      outline: solid 2px $primary;
    }
  }

  .back-button {
    border: none;
    background: none;
    color: $black;
    font-size: 14px;
    font-weight: 700;
    margin-top: 30px;
    cursor: pointer;
    outline: none;
    &:focus {
      outline-color: transparent;
    }
    &:focus-visible {
      outline: solid 2px $primary;
    }
  }

  .title {
    font-size: 22px;
    font-weight: 700;
    @include mediaQueries('margin-top', (xsmall: 30px, medium: 0));
  }

  .filters {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: left;

    .thematique-item-wrapper {
      margin: 15px 0 0 0;
      padding-left: 0;
      padding-right: 15px;

      h2 {
        font-size: inherit;
      }
    }
  }

  .disable-filters {
    position: relative;
    font-weight: 700;
    display: flex;
    align-items: center;
    width: 250px;
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
    &:focus {
      outline-color: transparent;
    }
    &:focus-visible {
      outline: solid 2px $primary;
    }

    .icon-delete {
      font-size: 0.75rem;
      margin-right: 10px;
    }
  }

  .validate {
    display: flex;
    position: relative;
    margin-top: 20px;
    @include mediaQueries('margin-top', (medium: 40px));
    justify-content: center;
    &:focus {
      outline-color: transparent;
    }
    &:focus-visible {
      outline: solid 2px $primary;
    }
  }
}
</style>
