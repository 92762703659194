export default {
  title: 'Mes vidéos',
  myVideosTitleSrOnly: 'Mes Videos, Vidéo à la demande, Orange',
  myVideosCatTitleSrOnly: '{title}, Vidéo à la demande, Orange',
  description: 'Retrouvez tous vos films et séries disponibles en streaming et téléchargement, sur tous vos écrans.',
  mosaique: {
    titre: 'Mes vidéos ({nbArticles})'
  },
  empty: 'Vous n\'avez pas de vidéo en cours de location / achat',
  filterEmpty: 'Aucun élément ne correspond à vos critères',
  remainingTime: 'Encore {time}{unit}'
}
