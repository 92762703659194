export default {
  title: '{title}',
  evenement2: {
    accessibility: {
      list: 'Liste de contenu mis en avant',
      defaultItem: 'Plus d\'informations',
      item: 'Plus d\'informations sur {title}'
    }
  },
  trailer: 'Regarder les bandes-annonces'
}
