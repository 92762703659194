import CONSTANTS from '../../../api.constants'

/**
 * Check if user is in Ue
 * @param profile
 * @returns {Promise<void>|Promise<never>}
 */
function isUe (profile) {
  return profile.isUe ? Promise.resolve() : Promise.reject({ componentName: 'OutOfEu' })
}

/**
 * Check if user is not suspended
 * Pass isOwner value if suspended, showing different wording
 * @param profile
 * @returns {Promise<void>|Promise<never>}
 */
function isNotSuspended (profile) {
  return !profile.isAuthenticated || profile.suspended === CONSTANTS.SUSPENDED_CODES.NA
    ? Promise.resolve()
    : Promise.reject({ componentName: 'Suspended', props: { suspended: profile.suspended } })
}

/**
 * Check if user has tv rights
 * If not, show modal, long one on first render, short one when navigating
 * @param profile
 * @param firstRender
 * @returns {Promise<void>|Promise<never>}
 */
function hasTvRights (profile, firstRender) {
  return !profile.isAuthenticated || (profile.hasTvRights && !profile.isMobile) ? Promise.resolve() : Promise.reject({ componentName: firstRender ? 'Blocage' : 'LightBlocage' })
}

/**
 * Check if token tv init is on error
 * @param store
 * @returns {Promise<void>|Promise<never>}
 */
function hasErrorOnTokenTvInit (store) {
  return !store.getters['core/getErrorOnTvTokenInit'] ? Promise.resolve() : Promise.reject({ componentName: 'ErrorTvInitModal' })
}

/**
 * Check if user is authenticated | and if route requires auth
 * @param requiresAuth
 * @param profile
 * @returns {Promise<void>|Promise<never>}
 */
function isAuthenticated (requiresAuth, profile) {
  return profile.isAuthenticated || !requiresAuth ? Promise.resolve() : Promise.reject({ componentName: 'Authentication' })
}

// ------------------------------------------------------------------------

export default {
  isUe,
  isNotSuspended,
  hasTvRights,
  hasErrorOnTokenTvInit,
  isAuthenticated
}
