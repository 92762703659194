export default {
  /**
   * Set filtered items from modal
   * @param {Object} state
   * @param {Array} items
   */
  setFilteredItemsFromModal (state, items) {
    state.filteredItemsFromModal = items
  },
  /**
   * Set filtered items from modal
   * @param {Object} state
   */
  resetFilteredItemsFromModal (state) {
    state.filteredItemsFromModal = []
  }
}
