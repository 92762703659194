/* eslint-disable */
/*   Copyright (C) 2011,2012,2013,2014 John Kula */

/*
    This program is free software: you can redistribute it and/or modify
    it under the terms of the GNU General Public License as published by
    the Free Software Foundation, either version 3 of the License, or
    (at your option) any later version.

    This program is distributed in the hope that it will be useful,
    but WITHOUT ANY WARRANTY; without even the implied warranty of
    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
    GNU General Public License for more details.

    You should have received a copy of the GNU General Public License
    along with this program.  If not, see <http://www.gnu.org/licenses/>.
const browser = fingerprint.browser()
      const os = fingerprint.os()
      const householdId = _get(state.user, 'householdId')
      const terminalModel = 'WEB_PC'

      const terminalId = householdId ? [
        os.name,
        os.bits,
        browser.name,
        householdId
      ].join('-') : undefined
    All trademarks and service marks contained within this document are
    property of their respective owners.

    Version 2014.07.23

    Updates may be found at: http:\\www.darkwavetech.com

*/

/* This function returns the browser and version number by using the navigator.useragent object */

function fingerprint_browser() {
  "use strict";
  var userAgent,
      name,
      version;

  try {

      userAgent = navigator.userAgent.toLowerCase();

      if (/msie (\d+\.\d+);/.test(userAgent)) { //test for MSIE x.x;
          version = Number(RegExp.$1); // capture x.x portion and store as a number
          if (userAgent.indexOf("trident/6") > -1) {
              version = 10;
          }
          if (userAgent.indexOf("trident/5") > -1) {
              version = 9;
          }
          if (userAgent.indexOf("trident/4") > -1) {
              version = 8;
          }
          name = "Internet Explorer";
      } else if (userAgent.indexOf("trident/7") > -1) { //IE 11+ gets rid of the legacy 'MSIE' in the user-agent string;
          version = 11;
          name = "Internet Explorer";
      }  else if (/(edge|edg)[\/\s](\d+\.\d+)/.test(userAgent)) { //test for Firefox/x.x or Firefox x.x (ignoring remaining digits);
          version = Number(RegExp.$2); // capture x.x portion and store as a number
          name = "Edge";
      }  else if (/firefox[\/\s](\d+\.\d+)/.test(userAgent)) { //test for Firefox/x.x or Firefox x.x (ignoring remaining digits);
          version = Number(RegExp.$1); // capture x.x portion and store as a number
          name = "Firefox";
      } else if (/(opera|opr)[\/\s](\d+\.\d+)/.test(userAgent)) { //test for Opera/x.x or Opera x.x (ignoring remaining decimal places);
          version = Number(RegExp.$2); // capture x.x portion and store as a number
          name = "Opera";
      } else if (/chrome[\/\s](\d+\.\d+)/.test(userAgent)) { //test for Chrome/x.x or Chrome x.x (ignoring remaining digits);
          version = Number(RegExp.$1); // capture x.x portion and store as a number
          name = "Chrome";
      } else if (/version[\/\s](\d+\.\d+)/.test(userAgent)) { //test for Version/x.x or Version x.x (ignoring remaining digits);
          version = Number(RegExp.$1); // capture x.x portion and store as a number
          name = "Safari";
      } else if (/rv[\/\s](\d+\.\d+)/.test(userAgent)) { //test for rv/x.x or rv x.x (ignoring remaining digits);
          version = Number(RegExp.$1); // capture x.x portion and store as a number
          name = "Mozilla";
      } else if (/mozilla[\/\s](\d+\.\d+)/.test(userAgent)) { //test for Mozilla/x.x or Mozilla x.x (ignoring remaining digits);
          version = Number(RegExp.$1); // capture x.x portion and store as a number
          name = "Mozilla";
      } else if (/binget[\/\s](\d+\.\d+)/.test(userAgent)) { //test for BinGet/x.x or BinGet x.x (ignoring remaining digits);
          version = Number(RegExp.$1); // capture x.x portion and store as a number
          name = "Library (BinGet)";
      } else if (/curl[\/\s](\d+\.\d+)/.test(userAgent)) { //test for Curl/x.x or Curl x.x (ignoring remaining digits);
          version = Number(RegExp.$1); // capture x.x portion and store as a number
          name = "Library (cURL)";
      } else if (/java[\/\s](\d+\.\d+)/.test(userAgent)) { //test for Java/x.x or Java x.x (ignoring remaining digits);
          version = Number(RegExp.$1); // capture x.x portion and store as a number
          name = "Library (Java)";
      } else if (/libwww-perl[\/\s](\d+\.\d+)/.test(userAgent)) { //test for libwww-perl/x.x or libwww-perl x.x (ignoring remaining digits);
          version = Number(RegExp.$1); // capture x.x portion and store as a number
          name = "Library (libwww-perl)";
      } else if (/microsoft url control -[\s](\d+\.\d+)/.test(userAgent)) { //test for Microsoft URL Control - x.x (ignoring remaining digits);
          version = Number(RegExp.$1); // capture x.x portion and store as a number
          name = "Library (Microsoft URL Control)";
      } else if (/peach[\/\s](\d+\.\d+)/.test(userAgent)) { //test for Peach/x.x or Peach x.x (ignoring remaining digits);
          version = Number(RegExp.$1); // capture x.x portion and store as a number
          name = "Library (Peach)";
      } else if (/php[\/\s](\d+\.\d+)/.test(userAgent)) { //test for PHP/x.x or PHP x.x (ignoring remaining digits);
          version = Number(RegExp.$1); // capture x.x portion and store as a number
          name = "Library (PHP)";
      } else if (/pxyscand[\/\s](\d+\.\d+)/.test(userAgent)) { //test for pxyscand/x.x or pxyscand x.x (ignoring remaining digits);
          version = Number(RegExp.$1); // capture x.x portion and store as a number
          name = "Library (pxyscand)";
      } else if (/pycurl[\/\s](\d+\.\d+)/.test(userAgent)) { //test for pycurl/x.x or pycurl x.x (ignoring remaining digits);
          version = Number(RegExp.$1); // capture x.x portion and store as a number
          name = "Library (PycURL)";
      } else if (/python-urllib[\/\s](\d+\.\d+)/.test(userAgent)) { //test for python-urllib/x.x or python-urllib x.x (ignoring remaining digits);
          version = Number(RegExp.$1); // capture x.x portion and store as a number
          name = "Library (Python URLlib)";
      } else if (/appengine-google/.test(userAgent)) { //test for AppEngine-Google;
          version = Number(RegExp.$1); // capture x.x portion and store as a number
          name = "Cloud (Google AppEngine)";
      } else if (/trident/.test(userAgent)) { //test for Trident;
          version = Number(RegExp.$1); // capture x.x portion and store as a number
          name = "Trident";
      } else if (/adventurer/.test(userAgent)) { //test for Orange Adventurer;
          version = Number(RegExp.$1); // capture x.x portion and store as a number
          name = "Adventurer";
      } else {
          version = "unknown";
          name = "unknown";
      }
  } catch (err) {
      name = "error";
      version = "error";
  }

  return {
      name: name.replace(/\s+/g, ''),
      version: version
  };
}

/* This function returns the operating system and number of bits by looking at the navigator.useragent and navigator.platform objects */

function fingerprint_os() {
  "use strict";

  var userAgent,
      platform,
      name,
      bits,
      os = {
          name: "",
          bits: ""
      };

  try {
      /* navigator.userAgent is supported by all major browsers */
      userAgent = navigator.userAgent.toLowerCase();

      if (userAgent.indexOf("windows nt 10.0") !== -1) {
          name = "Windows 10";
      } else if (userAgent.indexOf("windows nt 6.3") !== -1) {
          name = "Windows 8.1";
      } else if (userAgent.indexOf("windows nt 6.2") !== -1) {
          name = "Windows 8";
      } else if (userAgent.indexOf("windows nt 6.1") !== -1) {
          name = "Windows 7";
      } else if (userAgent.indexOf("windows nt 6.0") !== -1) {
          name = "Windows Vista/Windows Server 2008";
      } else if (userAgent.indexOf("windows nt 5.2") !== -1) {
          name = "Windows XP x64/Windows Server 2003";
      } else if (userAgent.indexOf("windows nt 5.1") !== -1) {
          name = "Windows XP";
      } else if (userAgent.indexOf("windows nt 5.01") !== -1) {
          name = "Windows 2000, Service Pack 1 (SP1)";
      } else if (userAgent.indexOf("windows xp") !== -1) {
          name = "Windows XP";
      } else if (userAgent.indexOf("windows 2000") !== -1) {
          name = "Windows 2000";
      } else if (userAgent.indexOf("windows nt 5.0") !== -1) {
          name = "Windows 2000";
      } else if (userAgent.indexOf("windows nt 4.0") !== -1) {
          name = "Windows NT 4.0";
      } else if (userAgent.indexOf("windows nt") !== -1) {
          name = "Windows NT 4.0";
      } else if (userAgent.indexOf("winnt4.0") !== -1) {
          name = "Windows NT 4.0";
      } else if (userAgent.indexOf("winnt") !== -1) {
          name = "Windows NT 4.0";
      } else if (userAgent.indexOf("windows me") !== -1) {
          name = "Windows ME";
      } else if (userAgent.indexOf("win 9x 4.90") !== -1) {
          name = "Windows ME";
      } else if (userAgent.indexOf("windows 98") !== -1) {
          name = "Windows 98";
      } else if (userAgent.indexOf("win98") !== -1) {
          name = "Windows 98";
      } else if (userAgent.indexOf("windows 95") !== -1) {
          name = "Windows 95";
      } else if (userAgent.indexOf("windows_95") !== -1) {
          name = "Windows 95";
      } else if (userAgent.indexOf("win95") !== -1) {
          name = "Windows 95";
      } else if (userAgent.indexOf("ce") !== -1) {
          name = "Windows CE";
      } else if (userAgent.indexOf("win16") !== -1) {
          name = "Windows 3.11";
      } else if (userAgent.indexOf("iemobile") !== -1) {
          name = "Windows Mobile";
      } else if (userAgent.indexOf("wm5 pie") !== -1) {
          name = "Windows Mobile";
      } else if (userAgent.indexOf("windows phone 10.0") !== -1) {
          name = "Windows Phone 10";
      } else if (userAgent.indexOf("windows") !== -1) {
          name = "Windows (Unknown Version)";
      } else if (userAgent.indexOf("openbsd") !== -1) {
          name = "Open BSD";
      } else if (userAgent.indexOf("sunos") !== -1) {
          name = "Sun OS";
      } else if (userAgent.indexOf("ubuntu") !== -1) {
          name = "Ubuntu";
      } else if (userAgent.indexOf("ipad") !== -1) {
          name = "iOS (iPad)";
      } else if (userAgent.indexOf("ipod") !== -1) {
          name = "iOS (iTouch)";
      } else if (userAgent.indexOf("iphone") !== -1) {
          name = "iOS (iPhone)";
      } else if (userAgent.indexOf("mac os x beta") !== -1) {
          name = "Mac O SX Beta";
      } else if (userAgent.indexOf("mac os x 10") !== -1) {
          if (/mac os x 10_(\d+)\_(\d+)/.test(userAgent)) {
              name = "Mac OS X 10." + RegExp.$1;
          } else {
              name = "Mac OS X 10";
          }
      } else if (userAgent.indexOf("mac os x") !== -1) {
          name = "Mac OS X";
      } else if (userAgent.indexOf("mac_68000") !== -1) {
          name = "Mac OS Classic (68000)";
      } else if (userAgent.indexOf("68K") !== -1) {
          name = "Mac OS Classic (68000)";
      } else if (userAgent.indexOf("mac_powerpc") !== -1) {
          name = "Mac OS Classic (PowerPC)";
      } else if (userAgent.indexOf("ppc mac") !== -1) {
          name = "Mac OS Classic (PowerPC)";
      } else if (userAgent.indexOf("macintosh") !== -1) {
          name = "Mac OS Classic";
      } else if (userAgent.indexOf("googletv") !== -1) {
          name = "Android (GoogleTV)";
      } else if (userAgent.indexOf("xoom") !== -1) {
          name = "Android (Xoom)";
      } else if (userAgent.indexOf("htc_flyer") !== -1) {
          name = "Android (HTC Flyer)";
      } else if (userAgent.indexOf("android") !== -1) {
          name = "Android";
      } else if (userAgent.indexOf("symbian") !== -1) {
          name = "Symbian";
      } else if (userAgent.indexOf("series60") !== -1) {
          name = "Symbian (Series 60)";
      } else if (userAgent.indexOf("series70") !== -1) {
          name = "Symbian (Series 70)";
      } else if (userAgent.indexOf("series80") !== -1) {
          name = "Symbian (Series 80)";
      } else if (userAgent.indexOf("series90") !== -1) {
          name = "Symbian (Series 90)";
      } else if (userAgent.indexOf("x11") !== -1) {
          name = "UNIX";
      } else if (userAgent.indexOf("nix") !== -1) {
          name = "UNIX";
      } else if (userAgent.indexOf("linux") !== -1) {
          name = "Linux";
      } else if (userAgent.indexOf("qnx") !== -1) {
          name = "QNX";
      } else if (userAgent.indexOf("os/2") !== -1) {
          name = "IBM OS/2";
      } else if (userAgent.indexOf("beos") !== -1) {
          name = "BeOS";
      } else if (userAgent.indexOf("blackberry95") !== -1) {
          name = "Blackberry (Storm 1/2)";
      } else if (userAgent.indexOf("blackberry97") !== -1) {
          name = "Blackberry (Bold)";
      } else if (userAgent.indexOf("blackberry96") !== -1) {
          name = "Blackberry (Tour)";
      } else if (userAgent.indexOf("blackberry89") !== -1) {
          name = "Blackberry (Curve 2)";
      } else if (userAgent.indexOf("blackberry98") !== -1) {
          name = "Blackberry (Torch)";
      } else if (userAgent.indexOf("playbook") !== -1) {
          name = "Blackberry (Playbook)";
      } else if (userAgent.indexOf("wnd.rim") !== -1) {
          name = "Blackberry (IE/FF Emulator)";
      } else if (userAgent.indexOf("blackberry") !== -1) {
          name = "Blackberry";
      } else if (userAgent.indexOf("palm") !== -1) {
          name = "Palm OS";
      } else if (userAgent.indexOf("webos") !== -1) {
          name = "WebOS";
      } else if (userAgent.indexOf("hpwos") !== -1) {
          name = "WebOS (HP)";
      } else if (userAgent.indexOf("blazer") !== -1) {
          name = "Palm OS (Blazer)";
      } else if (userAgent.indexOf("xiino") !== -1) {
          name = "Palm OS (Xiino)";
      } else if (userAgent.indexOf("kindle") !== -1) {
          name = "Kindle";
      } else if (userAgent.indexOf("wii") !== -1) {
          name = "Nintendo (Wii)";
      } else if (userAgent.indexOf("nintendo ds") !== -1) {
          name = "Nintendo (DS)";
      } else if (userAgent.indexOf("playstation 3") !== -1) {
          name = "Sony (Playstation Console)";
      } else if (userAgent.indexOf("playstation portable") !== -1) {
          name = "Sony (Playstation Portable)";
      } else if (userAgent.indexOf("webtv") !== -1) {
          name = "MSN TV (WebTV)";
      } else if (userAgent.indexOf("inferno") !== -1) {
          name = "Inferno";
      } else {
          name = "Unknown";
      }

      /* navigator.platform is supported by all major browsers */
      platform = navigator.platform.toLowerCase();

      if (platform.indexOf("x64") !== -1) {
          bits = "64";
      } else if (userAgent.indexOf("x86_64") !== -1) {
          bits = "64";
      } else if (userAgent.indexOf("x86-64") !== -1) {
          bits = "64";
      } else if (userAgent.indexOf("win64") !== -1) {
          bits = "64";
      } else if (userAgent.indexOf("x64;") !== -1) {
          bits = "64";
      } else if (userAgent.indexOf("amd64") !== -1) {
          bits = "64";
      } else if (userAgent.indexOf("wow64") !== -1) {
          bits = "64";
      } else if (userAgent.indexOf("x64_64") !== -1) {
          bits = "64";
      } else if (userAgent.indexOf("ia65") !== -1) {
          bits = "64";
      } else if (userAgent.indexOf("sparc64") !== -1) {
          bits = "64";
      } else if (userAgent.indexOf("ppc64") !== -1) {
          bits = "64";
      } else if (userAgent.indexOf("irix64") !== -1) {
          bits = "64";
      } else if (userAgent.indexOf("irix64") !== -1) {
          bits = "64";
      } else {
          bits = "32";
      }
  } catch (err) {
      name = "error";
      bits = "error";
  }

  return {
      name: name.replace(/\s+/g, ''),
      bits: "x" + bits
  };
}

export default {
  os: fingerprint_os,
  browser: fingerprint_browser
}
