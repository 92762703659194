import formatCategoryUtils from '../shared/formatCategory.utils'
import filterOptions from '../../components/shared/sortAndFilter/filterOptions'
import sortOptions from '../../components/shared/sortAndFilter/sortOptions'
import _get from 'lodash/get'
export default {
  setBookmarks (state, bookmarks) {
    // transform bookmarks into a category like object
    // TODO: undo when api will return a real category
    const bookmarksCategory = {
      articles: {
        items: _get(bookmarks, 'normal', []).map((item) => {
          return {
            ...item.article,
            bookmarkId: item.bookmarkId
          }
        })
      }
    }

    // set default filters
    const defaultFilters = formatCategoryUtils.getFormattedOptions({
      options: [
        filterOptions.pressScore,
        filterOptions.rentMaxPrice,
        filterOptions.buyMaxPrice,
        filterOptions.spectatorScore,
        filterOptions.promotion,
        filterOptions.everyone,
        filterOptions.duration
      ],
      translate: _get(this.app, 'i18n')
    })

    // set default sorting criterias
    const defaultSortingCriterias = formatCategoryUtils.getFormattedOptions({
      options: [
        sortOptions.aToZ,
        sortOptions.zToA,
        sortOptions.datesDecreasing,
        sortOptions.pressRating,
        sortOptions.spectatorRating,
        sortOptions.boxOffice
      ],
      translate: _get(this.app, 'i18n')
    })

    // populate state with formatted bookmarks category with filters and sorting criterias
    state.bookmarks = formatCategoryUtils.formatCategory(bookmarksCategory, defaultFilters, defaultSortingCriterias)
  }
}
