<template>
  <nav ref="nav" class="ob1-menu-page desktop-menu">
    <categories-menu :items="menu" />
    <user-menu />
  </nav>
</template>

<script>
import CategoriesMenu from './categoriesMenu/CategoriesMenu'
import UserMenu from './userMenu/UserMenu'

export default {
  name: 'DesktopMenu',
  components: {
    UserMenu,
    CategoriesMenu
  },
  props: {
    menu: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .desktop-menu {
    max-width: 1440px;
    margin: 0 auto;
    @include mediaQueries('padding', (medium: 0 0.75rem 0 25px, large: 0 2rem 0 45px, xlarge: 0 2.5rem 0 60px));
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    ul {
      padding: 0;
      margin-bottom: -1px; // need to stick the border, there is no other padding or margin
    }
  }
</style>
