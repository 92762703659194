import _get from 'lodash/get'
import formatCategoryUtils from '../shared/formatCategory.utils'
import filterOptions from '../../components/shared/sortAndFilter/filterOptions'
import sortOptions from '../../components/shared/sortAndFilter/sortOptions'
import { parseItemResponse } from '../../components/shared/api.utils'

export default {
  setAlerts (state, { data, config }) {
    const { articles = {}, ...alertsData } = data
    const { items = [], ...articlesData } = articles
    const parsedItems = items.map(item => parseItemResponse(item, config))
    const alerts = {
      articles: {
        items: parsedItems,
        ...articlesData
      },
      ...alertsData
    }
    // set default filters
    const defaultFilters = formatCategoryUtils.getFormattedOptions({
      options: [
        filterOptions.pressScore,
        filterOptions.spectatorScore,
        filterOptions.everyone,
        filterOptions.duration
      ],
      translate: _get(this.app, 'i18n')
    })
    // set default sorting criterias
    const defaultSortingCriterias = formatCategoryUtils.getFormattedOptions({
      options: [
        sortOptions.aToZ,
        sortOptions.zToA,
        sortOptions.datesDecreasing,
        sortOptions.pressRating,
        sortOptions.spectatorRating,
        sortOptions.boxOffice
      ],
      translate: _get(this.app, 'i18n')
    })
    // populate state with formatted alerts category with filters and sorting criterias
    state.alerts = formatCategoryUtils.formatCategory(alerts, defaultFilters, defaultSortingCriterias)
  },
  setAlert (state, { data, config }) {
    state.alert = parseItemResponse(data, config)
  },
  setCounter (state, counter) {
    state.counter = parseInt(counter)
  }
}
