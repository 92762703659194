export default function (to, from, savedPosition) {
  return new Promise((resolve) => {
    setTimeout(() => {
      // savedPosition is only available on navigator back/next and router.back()
      // see https://router.vuejs.org/guide/advanced/scroll-behavior.html#async-scrolling
      let position = { x: 0, y: 0 }
      const navigationNode = this.app.$root.$store.getters['navik/getNodeToRestore']

      if (to.name === from.name && to.path === from.path) {
        position = { x: null, y: null }
      } else if (to.params.keepPosition) {
        position = false
      } else if (savedPosition) {
        position = savedPosition
      } else if (navigationNode && navigationNode.state.lazyHeights.length === 0) {
        position = {
          x: navigationNode.state.scrollPosition.x,
          y: navigationNode.state.scrollPosition.y
        }
      }

      resolve(position)
    }, 50)
  })
}
