function onClickNextEpisode (item) {
  return {
    track_page: 'player',
    track_zone: 'control',
    track_nom: 'episode_suivant',
    track_type: 'clic',
    track_detail: `${item.videoType}-${item.id}-${item.title}`
  }
}

export default {
  onClickNextEpisode
}
