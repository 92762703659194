<template>
  <div id="app">
    <div class="error">
      <maintenance v-if="error.statusCode === 503" />
      <error v-else :error="error" />
    </div>
    <wptv-footer :used-o-s="os" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import commonElements from '../components/shared/commonElements/commonElements.mixin'
import Error from '../components/shared/errorPages/Error'
import Maintenance from '../components/shared/errorPages/Maintenance'
import { WptvFooter } from '@wptv/components/ui/footer'

export default {
  name: 'AppError',
  components: {
    Error,
    Maintenance,
    WptvFooter
  },
  mixins: [commonElements],
  layout: 'Default',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      os: ''
    }
  },
  computed: {
    ...mapGetters({
      osName: 'useragent/getOsName'
    })
  },
  mounted () {
    // done to avoid error if we use the getter 'getOsName' before we set the useragent
    this.os = this.osName
  }
}
</script>
<style lang="scss" scoped>
  .error {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
</style>
