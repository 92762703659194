import filterPromo from './filterPromo/filterPromo'
import pastillePromo from './pastillePromo/pastillePromo'
import infoPromo from './infoPromo/infoPromo'
import getItemEligiblePromos from './getItemEligiblePromos/getItemEligiblePromos'
import getFirstEligibleSeasonId from './getFirstEligibleSeasonId/getFirstEligibleSeasonId'
import rejectNotEligiblePromoCategory from './rejectNotEligiblePromoCategory/rejectNotEligiblePromoCategory'

export default {
  infoPromo,
  filterPromo,
  pastillePromo,
  getItemEligiblePromos,
  getFirstEligibleSeasonId,
  rejectNotEligiblePromoCategory
}
