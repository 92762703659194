export default {
  aToZ: {
    label: 'sort.sortOptions.aToZ',
    value: 'aToZ',
    criteria: 'A_Z'
  },
  zToA: {
    label: 'sort.sortOptions.zToA',
    value: 'zToA',
    criteria: 'Z_A'
  },
  datesDecreasing: {
    label: 'sort.sortOptions.datesDecreasing',
    value: 'datesDecreasing',
    criteria: 'PUBLICATION_DATE'
  },
  boxOffice: {
    label: 'sort.sortOptions.boxOffice',
    value: 'boxOffice',
    criteria: 'BOX_OFFICE'
  },
  pressRating: {
    label: 'sort.sortOptions.pressRating',
    value: 'pressRating',
    criteria: 'RATING_PRESS'
  },
  spectatorRating: {
    label: 'sort.sortOptions.spectatorRating',
    value: 'spectatorRating',
    criteria: 'RATING_VIEWERS'
  },
  IncreasingEpisodes: {
    label: 'sort.sortOptionsSeason.increaseOrder',
    value: 'increaseOrder',
    criteria: 'INCREASING_EPISODES'
  },
  DecreasingEpisodes: {
    label: 'sort.sortOptionsSeason.decreaseOrder',
    value: 'decreaseOrder',
    criteria: 'DECREASING_EPISODES'
  }
}
