<template>
  <div class="container">
    <div class="left-section">
      <div class="content">
        {{ errorTitle }}
      </div>

      <div v-if="error && error.statusCode === 404">
        <div class="redir-text">
          {{ $t('errors.generic.redirection', {count: count}) }}
        </div>
        <div class="redir-text">
          {{ $t('errors.generic.or') }}
        </div>
      </div>

      <div v-else class="unknown-text">
        {{ $t('errors.generic.unknownMessage') }}
      </div>

      <div class="redir-link">
        <a :href="homePage">{{ $t('errors.generic.link') }}</a>
      </div>
    </div>
    <div class="right-section show-from-medium">
      <img
        :src="notFoundImage"
        class="logo"
        alt="404">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      count: 5,
      notFoundImage: '/images/error.jpg',
      homePage: '/'
    }
  },
  meta: {
    oData: { page: '404' }
  },
  computed: {
    errorTitle () {
      return this.error && this.error.statusCode === 404 ? this.$t('errors.generic.notFound') : this.$t('errors.generic.unknown')
    }
  },
  mounted () {
    if (this.error && this.error.statusCode === 404) {
      const countInterval = setInterval(() => {
        if (this.count > 0) {
          this.count--
        } else {
          window.location.assign(this.homePage)
          clearInterval(countInterval)
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    background-color: $white;
    display: flex;
    flex: 1;
    align-items: center;
    padding: 20px;
    justify-content: center;

    .left-section {
      text-align: center;
      @include mediaQueries('width', (xsmall: 100%, medium: 50%));
    }

    .content {
      font-size: 45px;
      margin-bottom: 20px;
      line-height: 1.2;
    }

    .unknown-text,
    .redir-text,
    .redir-link {
      margin-top: 10px;
      font-size: 20px;
    }

    .redir-link {
      a {
        font-weight: bold;
        color: $primary2;
      }
    }
  }
</style>
