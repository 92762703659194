export default {
  title: 'Mes Alertes',
  alertsTitleSrOnly: 'Mes Alertes, Vidéo à la demande, Orange',
  mosaic: {
    title: 'Mes alertes ({nbArticles})',
    empty: 'Créez des alertes pour les films actuellement au cinéma et recevez une notification le jour de leur sortie en VOD',
    filterEmpty: 'Aucun élément ne correspond à vos critères',
    trash: 'Supprimer alerte',
    disabled: 'La fonctionnalité "Alerte" arrive bientôt sur la VOD d\'Orange.'
  },
  successAdd: 'Vous serez alerté par SMS et e-mail le jour de la disponibilité VOD.',
  successDelete: 'L’alerte a bien été supprimée.',
  error: 'Une erreur est survenue. Veuillez réessayer.',
  status: {
    published: 'Disponible'
  }
}
