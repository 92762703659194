<template>
  <wptv-menu>
    <wptv-menu-item
      :selected="$route.name === RouteUtils.ROUTE.HOME">
      <router-link
        v-tealium:click="$stats.menu.onClickHome()"
        :aria-label="$t('general.menu.home')"
        :to="{ name: RouteUtils.ROUTE.HOME }"
        class="nav-link"
        data-wptv="menu-item-home">
        {{ $t('general.menu.home') }}
      </router-link>
    </wptv-menu-item>
    <wptv-menu-item
      v-for="(category, index) in items"
      :key="index"
      :selected="$route.params.id === category.id">
      <router-link
        v-tealium:click="$stats.menu.onClickCategory(category.id, category.isTop, userQualification)"
        :aria-label="$t('general.menu.accessibility.item', { title: category.title })"
        :to="RouteUtils.createRouteFromConfigMenu(category)"
        class="nav-link"
        data-wptv="menu-item-category">
        {{ category.label }}
      </router-link>
    </wptv-menu-item>
  </wptv-menu>
</template>

<script>
import { WptvMenu, WptvMenuItem } from '@wptv/components/ui/menu'
import RouteUtils from '../../../../../route.utils'
import { mapGetters } from 'vuex'

export default {
  name: 'CategoriesMenu',
  components: {
    WptvMenu,
    WptvMenuItem
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      RouteUtils
    }
  },
  computed: {
    ...mapGetters({
      userQualification: 'user/getUserQualification'
    })
  }
}
</script>
