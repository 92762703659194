<template>
  <div class="container ">
    <div class="small">
      <h1>Nous revenons bientôt</h1>
    </div>
    <div class="image" />
    <div class="infos">
      <div class="large">
        <h1>Nous revenons bientôt</h1>
      </div>
      <div>
        Nous sommes désolés, le service Vidéo à la Demande d'Orange est en cours de maintenance.
      </div>
      <div>
        Veuillez réessayer ultérieurement.
      </div>
      <div class="div-button">
        <input
          class="button"
          type="button"
          value="Accéder à l'accueil"
          onclick="javascript:location.href='https://www.orange.fr'">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Maintenance',
  layout: 'Default',
  head () {
    return {
      meta: [
        { name: 'robots', content: 'noindex' }
      ],
      title: 'Service en maintenance'
    }
  },
  meta: {
    oData: { page: 'maintenance' }
  }
}
</script>

<style lang="css" scoped>
  h1 {
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: bold;
    line-height: normal;
  }

  .container {
    background-color: white;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 630px;
    width: 100%;
  }

  .image {
    background: url('/maintenance/maintenance.jpg');
    width: 100%;
    flex:1;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    max-height: 100%;
    height: inherit !important;
  }

  .infos {
    margin: 10px 15px;
    line-height: 1.5;
  }

  .infos div {
    margin-bottom: 15px;
  }

  .large {
    display: none;
  }

  .small {
    margin: 5px 15px;
  }

  .div-button {
    text-align: center;
  }

  @media screen and (min-width: 768px) {

    .container{
      flex-direction: row;
      justify-content: stretch;
      height: 100%;
    }
    .image {
      overflow: hidden;
      background-repeat: no-repeat;
      background-position: center right;
      background-size: cover;
      height: auto !important;
    }

    .infos {
      flex: 1;
      margin: auto 20px;
    }

    .large {
      display: block;
    }
    .small {
      display: none;
    }

    .div-button {
      text-align: left;
    }
  }

  @media screen and (min-width: 1440px) {
    .image {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .button {
    border: 1px solid #ff7900;
    justify-content: center;
    cursor: pointer;
    line-height: 1.38;
    padding: 14px 30px;
    font-weight: 700;
    font-size: 16px;
    width: 100%;
    max-width: 290px;
    height: 50px;
    background: #ff7900;
    color: #fff;
    margin-top: 20px;
  }
</style>
