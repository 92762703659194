import API from '../../api.constants'

export default {
  /**
   * Set the redirectTo url
   * @param state
   * @param redirectTo
   */
  setRedirectTo (state, redirectTo) {
    state.redirectTo = redirectTo
  },
  /**
   * Reset the redirectTo url - when leaving the fip
   * @param state
   */
  resetRedirectTo (state) {
    state.redirectTo = null
  },
  /**
   * Add history items to the history fip store
   * @param state
   * @param items
   */
  pushHistory (state, items) {
    if (items) {
      const historyItems = items
        .map(({ id, title, type, browsingSeasonId, videoType, seasonInfo, seasons }) => ({ id, title, type, browsingSeasonId, videoType, seasonInfo, seasons }))
        .filter(item => item.type !== API.RESOURCE_TYPES.LINK && item.type !== API.RESOURCE_TYPES.CATEGORY)

      state.history.push(historyItems)
    }
  },
  /**
   * Clean history; removing only the items after the current one
   * @param state
   * @param itemId
   */
  cleanHistory (state, itemId) {
    let itemFound = false
    // Reverse and filter to clear all items after the current one
    state.history = state.history.reverse().filter((items) => {
      if (!itemFound && items.some(item => item.id === itemId || item.browsingSeasonId === itemId)) {
        itemFound = true
      }
      return itemFound
    })
  },
  /**
   * Reset fip history
   * @param state
   */
  resetHistory (state) {
    state.history = []
  },
  /**
   * set selectedQuality
   * @param state
   * @param quality
   */
  setSelectedQuality (state, quality) {
    state.selectedQuality = quality
  }
}
