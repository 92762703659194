import pipe from '../../pipe'
import API from '../../../../api.constants'
import flexivodUtils from '../flexivod.utils'

import _reject from 'lodash/reject'
import _find from 'lodash/find'

/**
 * Wrapper to retrieve eligible promo items then reject not eligible ones
 * @param userDiscounts
 * @param coupon
 * @param items
 */

function rejectNotEligiblePromoCategory (userDiscounts, coupon, items = []) {
  return pipe(
    flexivodUtils.checkCoupon,
    flexivodUtils.filterSatisfied,
    satisfiedDiscounts => _rejectNotEligible(satisfiedDiscounts, items)
  )(userDiscounts, coupon, items)
}

// -----------------------------------------------------

/**
 * return items array rejecting the promo ones that don't match any user discount
 * @param satisfiedDiscounts
 * @param items
 * @return {*}
 * @private
 */
function _rejectNotEligible (satisfiedDiscounts, items) {
  return _reject(items,
    (item) => {
      return item.type === API.PROMO.DISCOUNT_CATEGORY && !_find(satisfiedDiscounts,
        satisfiedDiscount => satisfiedDiscount.id === item.id
      )
    }
  )
}

// -----------------------------------------------------

export default rejectNotEligiblePromoCategory
