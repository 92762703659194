import Router from 'vue-router'
import RouteUtils from './route.utils'
import scrollBehavior from './router.scrollBehavior.js'
import Vue from 'vue'
import Navik from '@wptv/navik'

const Home = () => import(/* webpackChunkName: "home" */ './components/home/Home').then(m => m.default || m)
const Category = () => import(/* webpackChunkName: "category" */ './components/category/Category').then(m => m.default || m)
const Corner = () => import(/* webpackChunkName: "corner" */ './components/corner/Corner').then(m => m.default || m)
const FipPack = () => import(/* webpackChunkName: "fip-pack" */ './components/fip/fipPack/FipPack').then(m => m.default || m)
const FipSeason = () => import(/* webpackChunkName: "fip-season" */ './components/fip/fipSeason/FipSeason').then(m => m.default || m)
const FipEpisode = () => import(/* webpackChunkName: "fip-episode" */ './components/fip/fipEpisode/FipEpisode').then(m => m.default || m)
const FipSingle = () => import(/* webpackChunkName: "fip-single" */ './components/fip/fipSingle/FipSingle').then(m => m.default || m)
const MyAccount = () => import(/* webpackChunkName: "my-account" */ './components/myAccount/MyAccount').then(m => m.default || m)
const MyBookmarks = () => import(/* webpackChunkName: "my-bookmarks" */ './components/myBookmarksAlerts/myBookmarks/MyBookmarks').then(m => m.default || m)
const MyAlerts = () => import(/* webpackChunkName: "my-alerts" */ './components/myBookmarksAlerts/myAlerts/MyAlerts').then(m => m.default || m)
const MyVideos = () => import(/* webpackChunkName: "my-videos" */ './components/myVideos/MyVideos').then(m => m.default || m)
const MyVideosCategory = () => import(/* webpackChunkName: "my-videos-category" */ './components/myVideos/myVideosCategory/MyVideosCategory').then(m => m.default || m)
const Payment = () => import(/* webpackChunkName: "payment" */ './components/payment/Payment').then(m => m.default || m)
const MyBookmarksAlerts = () => import(/* webpackChunkName: "my-bookmarks-alerts" */ './components/myBookmarksAlerts/MyBookmarksAlerts').then(m => m.default || m)

Vue.use(Router)

export function createRouter () {
  const router = new Router({
    mode: 'history',
    routes: [
      {
        name: 'home',
        path: '/',
        component: Home,
        meta: {
          navik: {
            nodeType: Navik.CONSTANT.NODE_TYPE.ROOT,
            hasBackLink: false
          }
        }
      },
      {
        name: 'category',
        path: '/categorie/:id/:itemTitle?',
        component: Category,
        props: true,
        meta: {
          navik: {
            nodeType: Navik.CONSTANT.NODE_TYPE.NODE,
            hasBackLink: false
          }
        }
      },
      {
        name: RouteUtils.ROUTE.FIP.PACKAGE,
        path: '/collection-film-serie/:id/:itemTitle?',
        component: FipPack,
        props: true,
        meta: {
          navik: {
            nodeType: Navik.CONSTANT.NODE_TYPE.NODE,
            hasBackLink: true
          }
        }
      },
      {
        name: RouteUtils.ROUTE.FIP.SEASON,
        path: '/serie/:id/:itemTitle?',
        component: FipSeason,
        props: true,
        meta: {
          navik: {
            nodeType: Navik.CONSTANT.NODE_TYPE.NODE,
            hasBackLink: true
          }
        }
      },
      {
        name: RouteUtils.ROUTE.FIP.EPISODE,
        path: '/serie/:seasonId/:seasonTitle/episode/:id/:episodeTitle?',
        component: FipEpisode,
        props: true,
        meta: {
          navik: {
            nodeType: Navik.CONSTANT.NODE_TYPE.LEAF,
            hasBackLink: true
          }
        }
      },
      {
        name: RouteUtils.ROUTE.FIP.MOVIE,
        path: '/film/:id/:itemTitle?',
        component: FipSingle,
        props: true,
        meta: {
          navik: {
            nodeType: Navik.CONSTANT.NODE_TYPE.LEAF,
            hasBackLink: true
          }
        }
      },
      {
        name: 'myAccount',
        path: '/mon-compte-prepaye',
        component: MyAccount,
        meta: {
          requiresAuth: true,
          navik: {
            nodeType: Navik.CONSTANT.NODE_TYPE.NODE,
            hasBackLink: false
          }
        }
      },
      {
        name: 'myBookmarks',
        path: '/ma-liste/mes-favoris',
        component: MyBookmarks,
        meta: {
          requiresAuth: true,
          navik: {
            nodeType: Navik.CONSTANT.NODE_TYPE.NODE,
            hasBackLink: false
          }
        }
      },
      {
        name: 'myAlerts',
        path: '/ma-liste/mes-alertes',
        component: MyAlerts,
        meta: {
          requiresAuth: true,
          navik: {
            nodeType: Navik.CONSTANT.NODE_TYPE.NODE,
            hasBackLink: false
          }
        }
      },
      {
        name: 'myVideos',
        path: '/mes-videos',
        component: MyVideos,
        meta: {
          requiresAuth: true,
          navik: {
            nodeType: Navik.CONSTANT.NODE_TYPE.NODE,
            hasBackLink: false
          }
        }
      },
      {
        name: 'myVideosCategory',
        path: '/mes-videos/:id/:itemTitle?',
        component: MyVideosCategory,
        props: true,
        meta: {
          requiresAuth: true,
          navik: {
            nodeType: Navik.CONSTANT.NODE_TYPE.NODE,
            hasBackLink: false
          }
        }
      },
      {
        name: 'payment',
        path: '/payment/:id',
        component: Payment,
        props: true,
        meta: {
          requiresAuth: true,
          requiresCapabilities: true,
          navik: {
            nodeType: Navik.CONSTANT.NODE_TYPE.NODE,
            hasBackLink: false
          }
        }
      },
      {
        name: 'myBookmarksAlerts',
        path: '/ma-liste',
        component: MyBookmarksAlerts,
        props: true,
        meta: {
          requiresAuth: true,
          navik: {
            nodeType: Navik.CONSTANT.NODE_TYPE.NODE,
            hasBackLink: false
          }
        }
      },
      {
        name: 'corner',
        path: '/corner/:id/:itemTitle?',
        component: Corner,
        props: true,
        meta: {
          navik: {
            corner: true,
            nodeType: Navik.CONSTANT.NODE_TYPE.NODE,
            hasBackLink: true
          }
        }
      }
    ],
    scrollBehavior
  })

  return router
}
