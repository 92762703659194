import _get from 'lodash/get'
import API_CONSTANTS from '../../../api.constants'
import { mapGetters } from 'vuex'

export default {
  name: 'playerMixin',
  data () {
    return {
      playerVod: null,
      isPlaying: false,
      isTrailer: false,
      playerOptions: [],
      videoToPlay: null,
      trailerToPlay: null
    }
  },
  computed: {
    ...mapGetters({
      trailer: 'player/getTrailer',
      video: 'player/getVideo',
      user: 'user/getUser',
      uniqueId: 'user/getUniqueId',
      accountStatus: 'user/getAccountStatus',
      terminal: 'terminals/getTerminal',
      profile: 'core/getProfile',
      token: 'core/getTokenTvInit',
      wassupHeaders: 'core/getWassupHeaders',
      isSafari: 'useragent/isSafari',
      isCompatibleMobile: 'useragent/isCompatibleMobile',
      isCertificateAvailable: 'player/isCertificateAvailable',
      certificateSafari: 'player/getCertificateSafari',
      certificateChrome: 'player/getCertificateChrome',
      playlist: 'player/getPlaylist'
    }),
    headers () {
      return Object.assign({ tv_token: `Bearer ${this.token}` }, this.wassupHeaders)
    },
    SDOnly () {
      // SD is allowed for trailer only when user is authentified with highestDefinition === 'SD'
      return this.profile.isAuthenticated &&
        this.user?.highestDefinition === API_CONSTANTS.QUALITIES.SD
    },
    confettiConfig () {
      return _get(this.$config, 'confetti.public.player', {})
    },
    isHDEW () {
      return this.$config?.confetti?.public?.enableHDEW
    },
    isTrailerHDAvailable () {
      let trailerHDIsAvailable = false

      // Corner Barker case : 'trailers' key isn't in the JSON file
      if (this.trailerToPlay?.trailers !== undefined) {
        if (this.trailerToPlay?.trailers[0]?.quality === API_CONSTANTS.QUALITIES.HD) {
          trailerHDIsAvailable = true
        }
      }

      return trailerHDIsAvailable
    },
    isVideoHDAvailable () {
      return this.videoToPlay?.orderInfo?.quality === API_CONSTANTS.QUALITIES.HD || this.videoToPlay?.orderInfo?.quality === API_CONSTANTS.QUALITIES.UHD4K
    },
    isHD () {
      return (this.isTrailer && !this.SDOnly && this.isTrailerHDAvailable) ||
      (!this.isTrailer && this.isHDEW && this.isVideoHDAvailable)
    }
  },
  mounted () {
    this.$root.$on('watch', options => this.onWatch(options))
    this.$root.$on('watchTrailer', options => this.onWatchTrailer(options))
  },
  methods: {
    /**
     * fetchTrailer before playing it
     * @param {Object} trailer
     */
    onWatchTrailer ({ trailer }) {
      const playId = _get(trailer, 'id', null)

      return playId
        ? this.$store.dispatch('player/fetchTrailer', playId)
          .then(() => this.playTrailer({ trailer, playId }))
        : this.setPlayIdError()
    },
    /**
     * Play the trailer
     * @param {Object} trailer
     * @param {string} playId
     */
    playTrailer ({ trailer, playId }) {
      this.trailerToPlay = trailer
      this.enableSeeingStatsForPlayer = false
      this.isTrailer = true
      const licence = this.$config?.confetti?.public?.player?.playerLicence || ''
      this.playerOptions = [
        this.trailer,
        trailer,
        playId,
        0,
        false,
        0,
        this.SDOnly,
        this.getBitrateConfigSet(),
        licence
      ]
      this.playVideo()
    },
    /**
     * fetch video before playing it
     * @param {string} playId
     * @param {string} giftId
     * @param {Object} videoToPlay
     * @param {number} timecode
     */
    onWatch ({ giftId, videoToPlay, timecode }) {
      const movieId = videoToPlay?.id

      return movieId
        ? this.$store.dispatch('player/fetchVideo',
          {
            movieId,
            giftId,
            usage: _get(videoToPlay, 'orderInfo.usage', 'SELL')
          }).then(() => this.playMovie({ movieId, videoToPlay, timecode }))
        : this.setPlayIdError()
    },
    /**
     * Play the movie
     * @param {string} playId
     * @param {Object} videoToPlay
     * @param {number} timecode
     */
    playMovie ({ movieId, videoToPlay, timecode }) {
      this.videoToPlay = videoToPlay
      this.enableSeeingStatsForPlayer = true
      this.isTrailer = false
      const licence = this.$config?.confetti?.public?.player?.playerLicence || ''
      this.playerOptions = [
        this.video,
        { ...videoToPlay, duration: videoToPlay.duration * 60 },
        movieId,
        timecode,
        true,
        0,
        false,
        this.getBitrateConfigSet(),
        licence
      ]
      this.playVideo()
    },
    /**
     * Load the player and certificates if not loaded yet and play video/trailer
     */
    playVideo () {
      if (this.playerVod) {
        this.isPlaying = true
        // Launch init player
        if (this.$refs.player !== undefined) {
          this.$refs.player.init(...this.playerOptions)
        } else {
          this.playerVod = () => import('@wptv/player')
        }
      } else if (!this.isCertificateAvailable) {
        return this.$store.dispatch('player/getCertificates').then(() => {
          this.playerVod = () => import('@wptv/player')
        })
      } else {
        this.playerVod = () => import('@wptv/player')
      }
    },
    /**
     * Set a contentError if playId not found
     */
    setPlayIdError () {
      return this.$store.dispatch('errors/setContentError', { errorCode: 'CONTENT_PLAYID_NOT_FOUND' })
    },
    /**
     * Reload - used when an error occurred on player side
     * @param {number} timecode
     * @param {Object} videoToPlay
     */
    reload ({ timecode, videoToPlay }) {
      this.isTrailer
        ? this.onWatchTrailer({
          trailer: videoToPlay
        })
        : this.onWatch({
          timecode,
          videoToPlay
        })
    },
    /**
     * Send a request when ending the session
     * @param {string} sessionId
     */
    onEndSession (sessionId) {
      if (sessionId) {
        return this.$store.dispatch('player/endSession', sessionId)
      }
    },
    /**
     * End the current trailer played
     */
    onNextTrailer (isEndOfTrailer) {
      return this.$store.commit('player/setEndOfTrailer', isEndOfTrailer)
    },
    /**
     * When closing the player
     */
    onClosePlayer () {
      this.isPlaying = false
      this.$emit('on-stop')
    },
    /**
     * On error - used when player send an error
     * @param {Object} error
     */
    onPlayerError (error) {
      return this.$store.dispatch('errors/setPlayerError', { error })
    },
    /**
     *
     * @param {Object} timecodeInfos
     * @param {boolean} isOnReset
     */
    onUpdateTimecode (timecodeInfos) {
      return this.$store.dispatch('player/updateTimecode', timecodeInfos).then(() => {
        this.updateItemPlayHistory(timecodeInfos.articleId)
      })
    },
    /**
     * updates the video watched time
     * @param {string} itemId
     */
    updateItemPlayHistory (itemId) {
      return this.$store.dispatch('user/fetchSinglePlayHistory', { itemId })
    },
    getBitrateConfigSet () {
      const bitrateConfig = JSON.parse(this.$config?.confetti?.public?.player?.bitrateLevelConfig)
      return this.isHD ? bitrateConfig?.HD : bitrateConfig?.SD
    },
    onReloadManifest ({ item }) {
      return this.$store.dispatch('player/fetchVideo',
        {
          movieId: item.id,
          videoToPlay: item
        }).then((video) => {
        this.player.updateManifestUrl(video.url)
      })
    }
  }
}
