export default () => ({
  user: {},
  myVideos: {},
  myVideosCategory: {},
  userDiscounts: [],
  activatedCoupon: {},
  couponCode: '',
  prepaidAccount: {},
  myArticles: [],
  myArticlesIds: [],
  playHistory: [],
  generation: {}
})
