<template>
  <div
    id="app"
    :class="[{ 'playing': isPlaying }]">
    <Menu class="menu" />
    <nuxt />
    <wptv-scroll-top-button
      :title="$t('general.scrollTop')"
      :tealium="$stats.global.onClickScrollTopButton()"
      data-wptv="mosaique-retour_haut" />

    <thematique-filters
      v-if="showFilters"
      ref="filters"
      :items-to-sort-and-filter="items" />

    <div
      v-if="playerVod"
      :class="['player-wrapper', { 'active': isPlaying }]">
      <div
        class="player-content">
        <component
          :is="playerVod"
          ref="player"
          :confetti-config="confettiConfig"
          :show-csa-panel="true"
          :enable-seeing-stats="enableSeeingStatsForPlayer"
          :unique-id="uniqueId"
          :user="user"
          :headers="headers"
          :is-safari="isSafari"
          :is-compatible-mobile="isCompatibleMobile"
          :certificate-safari="certificateSafari"
          :certificate-chrome="certificateChrome"
          :auto-fullscreen="false"
          :playlist="isTrailer ? {} :playlist"
          @mounted="playVideo"
          @close-player="onClosePlayer"
          @end-session="onEndSession"
          @end-trailer="onNextTrailer(true)"
          @reload="reload"
          @error="onPlayerError"
          @update-timecode="onUpdateTimecode"
          @reload-manifest="onReloadManifest" />
      </div>
    </div>

    <component
      :is="activeModal"
      v-if="showModal"
      ref="activeModal"
      v-bind="activeModalProps"
      @closed="closeModal" />

    <wptv-footer :used-o-s="os" />
    <wptv-snackbar />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { WptvScrollTopButton } from '@wptv/components/ui/scrollTopButton'
import { WptvSnackbar } from '@wptv/components/ui/snackbar'
import { WptvFooter } from '@wptv/components/ui/footer'
import Menu from '../components/shared/menu/Menu'
import commonElements from '../components/shared/commonElements/commonElements.mixin'
import modals from '../components/shared/modals/modals.mixin'
import mseEmeCapabilities from '../components/shared/mseEmeCapabilities.mixin'
import ThematiqueFilters from '../components/shared/thematique/ThematiqueFilters'
import { profileHasAccess } from '../middleware/checkups'
import vodPlayerMixin from '../components/shared/player/vodPlayer.mixin'

export default {
  name: 'AppVod',
  components: {
    WptvScrollTopButton,
    Menu,
    WptvSnackbar,
    WptvFooter,
    ThematiqueFilters
  },
  mixins: [
    commonElements,
    modals,
    mseEmeCapabilities,
    vodPlayerMixin
  ],
  data () {
    return {
      items: [],
      showFilters: false,
      os: ''
    }
  },
  computed: {
    ...mapGetters({
      maintenanceCode: 'errors/getMaintenanceCode',
      profile: 'core/getProfile',
      accountStatus: 'user/getAccountStatus',
      terminal: 'terminals/getTerminal',
      osName: 'useragent/getOsName'
    })
  },
  watch: {
    maintenanceCode (newValue) {
      if (newValue && (newValue === 'ALL_SERVICE' || newValue === 'PC_SERVICE')) {
        this.$nuxt.error({ statusCode: 503 })
      }
    }
  },
  /**
   * Retrieve/init client side data
   */
  mounted () {
    this.$store.commit('useragent/setUserAgent')

    this.$store.commit('terminals/setTerminalModel', this.terminal)
    // done to avoid error if we use the getter 'getOsName' before we set the useragent
    this.os = this.osName

    if (profileHasAccess(this.profile)) {
      this.$store.dispatch('bookmarks/getBookmarks')
      this.$store.dispatch('user/fetchAllPlayHistory')
    }

    this.$root.$on('showFilters', (toggle, itemsList) => {
      this.onShowFilters(toggle)
      this.items = toggle ? itemsList : []
    })
    this.$root.$on('selectFilters', () => { this.showFilters = false })
    this.$root.$on('page-loaded', () => { this.onLoaded() })
  },
  methods: {
    onShowFilters (toggle) {
      this.showFilters = toggle
    },
    onLoaded () {
      this.$navik.restorePageState(this.$store)
    }
  }
}
</script>
<style lang="scss" scoped>
.menu {
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 98;
  width: 100%;
}

#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;

 &.playing {
   height: 100vh;
   width: 100vw;
   overflow: hidden;
 }

 .footer {
    margin-top: 0px;
  }
}

@keyframes pop {
  from {
    height: 0;
    width: 0;
  }

  to {
    height: 100vh;
    width: 100vw;
  }
}

.player-wrapper:not(.active) {
  display: none;
}

.player-wrapper.active {
  position: fixed;
  background: $black;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: 100vw;
  animation: pop 0.5s ease-out;

  .player-content {
    height: 100%;
  }

  ::v-deep .oneplayerui {
    height: 100%;
    width: 100%;
  }

  ::v-deep .oneplayerui .oneplayerui__loading-cover {
    object-fit: cover;
  }

  @media screen and (orientation: portrait),
  screen and (max-device-width: 1200px) and (orientation: landscape) {
    .player-content {
      margin-top: 7.5vh;
      height: 85%;
      width: 100%;
    }
  }
}
</style>
