function onClickFooter () {
  return {
    track_page: 'home',
    track_zone: 'visuel_footer_everywhere',
    track_nom: 'decouvrir',
    track_type: 'clic',
    track_cible: '',
    track_detail: 'clic-decouvrir-footer_everywhere-home'
  }
}

export default {
  onClickFooter
}
