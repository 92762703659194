export default {
  category: 'la catégorie {name}',
  season: 'la saison {name}',
  pack: 'le pack {name}',
  corner: 'le corner {name}',
  home: 'l\'accueil',
  myVideos: 'mes vidéos',
  myList: 'ma liste',
  myBookmarks: 'mes favoris',
  myAlerts: 'mes alertes',
  myVideosCategory: '{name}'
}
