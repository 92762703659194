function onClickDeleteBookmark (bookmark) {
  return {
    page: 'mes_favoris',
    zone: 'mosaique-favoris',
    area: 'supprimer',
    cible: `${bookmark.type}-${bookmark.bookmarkId}-${bookmark.title}`,
    type: 'clic'
  }
}

function onSelectBookmark (bookmarked, item) {
  return {
    page: 'fip',
    zone: 'banniere',
    area: bookmarked ? 'favoris-retirer' : 'favoris-ajouter',
    cible: item.type + (item.videoType ? '/' + item.videoType : '') + '-' + item.id + '-' + item.title,
    type: 'clic'
  }
}

export default {
  onClickDeleteBookmark,
  onSelectBookmark
}
