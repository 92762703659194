export default {
  /**
   * Get filtered items from modal
   * @param {Object} state
   * @returns {Array}
   */
  getFilteredItemsFromModal (state) {
    return state.filteredItemsFromModal
  }
}
