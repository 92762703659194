export default {
  openGraph: {
    name: 'VOD ORANGE - Vidéo à la demande',
    defaultTitle: 'VOD ORANGE - Vidéo à la demande',
    defaultDescription: 'Vos films préférés en VOD avec Orange'
  },
  twitter: {
    defaultTitle: 'VOD ORANGE - Vidéo à la demande',
    defaultDescription: 'Plus de 20 000 vidéos disponibles en location ou à l&#39;achat : nouveautés films, séries récentes. Visionnage en ligne ou téléchargement, sur PC, MAC, smartphone, tablette et sur la TV Orange'
  }
}
