export default {
  /**
     * Get anonymous recommendations
     * @param dispatch
     * @param itemId
     * @returns {Promise<* | never>}
     */
  getAnonymRecommendations ({ dispatch }, { itemId }) {
    return this.$api.tvm.videos.getAnonymRecommendations(itemId)
      .then((response) => {
        if (response.error) {
          dispatch('errors/setAxiosError', { error: { ...response, videoId: itemId }, bypass: true }, { root: true })
        }

        return response.error ? [] : response
      })
  },
  /**
   * Retrieve user recommedations
   * @param commit
   * @param dispatch
   * @param itemId
   * @returns {Promise<T | never>}
   */
  getRecommendations ({ commit, dispatch }, { itemId }) {
    return this.$api.tvm.recommendations.getRecommendations(itemId)
      .then((response) => {
        if (response.error) {
          dispatch('errors/setAxiosError', { error: { ...response, videoId: itemId }, bypass: true }, { root: true })
        }

        return response.error ? [] : response
      })
  }
}
