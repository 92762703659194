import _get from 'lodash/get'

/**
 * Parse api response for an alert item
 * Add domain if image urls are relative
 * @param item
 * @param config
 * @returns {*}
 */
export function parseItemResponse (item, config) {
  if (Object.keys(item).length !== 0) {
    return Object.assign({}, item, { isAlert: true }, {
      images: _get(item, 'images', []).map((image) => {
        const urlWithoutDomain = image.url.replace(/^.*\/\/[^/]+/, '') // remove domain from url if any

        return {
          format: image.format,
          url: `${config.confetti.public.imageDomain}${urlWithoutDomain[0] !== '/' ? '/' : ''}${urlWithoutDomain}`
        }
      }),
      covers: _get(item, 'covers', []).map((cover) => {
        const urlWithoutDomain = cover.url.replace(/^.*\/\/[^/]+/, '') // remove domain from url if any

        return {
          format: cover.format,
          url: `${config.confetti.public.imageDomain}${urlWithoutDomain[0] !== '/' ? '/' : ''}${urlWithoutDomain}`
        }
      })
    })
  } else {
    return item
  }
}

/**
 * It parses the corner to change (if needed) all known image url to add domain
 * @param corner
 * @param config
 * @returns {string}
 */
export function parseCornerItemImages (corner, config) {
  if (corner.background && corner.items?.length > 0) {
    const isBackgroundAbsolute = corner.background.url.includes('http') // If it doesn't start with https://proxy... it is relative so we must construct url image with image Domain from config

    const parsedItems = corner.items.map((item) => {
      const isAbsolute = item.image.includes('http')// Same for all the items within the corner
      return {
        ...item,
        image: isAbsolute ? item.image : addDomain(item.image, config)
      }
    })

    return Object.assign(
      {},
      corner,
      {
        background: {
          type: corner.background.type,
          url: isBackgroundAbsolute ? corner.background.url : addDomain(corner.background.url, config)
        }
      },
      {
        items: parsedItems
      }
    )
  } else if (!corner.background && corner.items?.length > 0) {
    const parsedItems = corner.items.map((item) => {
      const isAbsolute = item.image.includes('http')// Same for all the items within the corner
      return {
        ...item,
        image: isAbsolute ? item.image : addDomain(item.image, config)
      }
    })

    return Object.assign(
      {},
      corner,
      {
        background: {
          type: null,
          url: null
        }
      },
      {
        items: parsedItems
      }
    )
  }

  return corner
}

/**
 *
 * @param image
 * @param config
 * @returns {string}
 * @private
 */
export function addDomain (image, config) {
  return `${config.confetti.public.imageDomain}${image[0] !== '/' ? '/' : ''}${image}`
}
