import fipUtils from '../../components/fip/shared/fip.utils'

/**
 * Select the correct api to call for catalog fetch
 * @param type
 * @param isArticle
 * @param api
 * @param itemId
 * @returns {default.getVideo|_getVideo}
 */
export function fetchArticle (type, isArticle, api, itemId) {
  if (isArticle) {
    return api.tvm.users.fetchMyArticleById(itemId)
  }

  // catalog
  switch (type) {
    case fipUtils.FIP_TYPE.MOVIE:
    case fipUtils.FIP_TYPE.EPISODE:
      return api.tvm.videos.getVideo(itemId)
    case fipUtils.FIP_TYPE.SEASON:
      return api.tvm.seasons.getSeason(itemId)
    case fipUtils.FIP_TYPE.PACKAGE:
      return api.tvm.packages.getPackage(itemId)
  }
}
