export default {
  /**
   * Get Show modal
   * @param state
   * @returns {boolean|(() => void)}
   */
  getShowModal (state) {
    return state.showModal
  },
  /**
   * Get first  modal to open if any
   * @param state
   * @returns {*|null}
   */
  getFirstModalToOpen (state) {
    return state.modalsToOpen.length !== 0 ? state.modalsToOpen[0] : null
  },

  getMaxymiserTest (state) {
    return state.maxymiserTest
  }
}
