import { fetchArticle } from './fip.interface'

export default {
  /**
   * Get item either by calling myvideos or catalog api
   * @param dispatch
   * @param rootGetters
   * @param articleId
   * @param type
   * @param itemId
   * @returns {Q.Promise<unknown>|Promise<T>}
   */
  fetchItem ({ commit, dispatch, rootGetters }, { itemId, type }) {
    const isArticle = rootGetters['user/isArticle'](itemId)

    return fetchArticle(type, isArticle, this.$api, itemId)
      .then((data) => {
        // set videos article in package when bought separately
        if (data.type === 'PACKAGE') {
          data.videos.forEach((video) => {
            const videoArticle = rootGetters['user/findMyArticleById'](video.id)
            Object.assign(video, videoArticle)
          })
        }
        data.parentCategories.forEach((el) => {
          el.isTop = el.id === this.$config.confetti.public.topCategory
        })
        return data
      })
      .catch((error) => {
        dispatch('errors/setAxiosError', { error: { ...error, itemId } }, { root: true })
        return error
      })
  }
}
