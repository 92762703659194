import urlJoin from 'url-join'
import _get from 'lodash/get'
import API from '../../../../api.constants'

export default {
  /**
   * Get Recommendations
   * @param articleId
   * @returns {Promise<any | never>}
   */
  getRecommendations (articleId) {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.recommendations'))
    const method = 'api.recommendations.getRecommendations()'

    return this.$http.tvm.auth.get(path, {
      params: {
        catalogId: _get(this.$config, 'confetti.public.api.tvm.universeId'),
        articleId,
        limit: _get(this.$config, 'confetti.public.api.tvm.params.recommendationsLimit', API.RECOMMENDATIONS.DEFAULT_LIMIT)
      }
    })
      .then(response => this.$httpUtils.parseResponse(response, true))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method, bypass: true }))
  }
}
