function onClickTitleStrip (category, isTop, page, userQualification) {
  return {
    track_page: page,
    track_zone: 'bandeau',
    track_nom: 'titre',
    track_type: isTop && userQualification ? 'clic-top' : category.id === 'MA_LISTE_CATEGORY_ID' ? 'clic-liste' : 'clic',
    track_cible: _getTrackCategory(category),
    track_detail: 'clic-titre-bandeau-' + page
  }
}

function onClickAllButton (category, isTop, page, userQualification) {
  return {
    track_page: page,
    track_zone: 'bandeau',
    track_nom: 'tout_afficher',
    track_type: isTop && userQualification ? 'clic-top' : category.id === 'MA_LISTE_CATEGORY_ID' ? 'clic-liste' : 'clic',
    track_cible: _getTrackCategory(category),
    track_detail: 'clic-tout_afficher-bandeau-' + page
  }
}

function onClickElement (category, item, page, userQualification) {
  return {
    track_page: page,
    track_zone: 'bandeau',
    track_nom: 'element_mea',
    track_type: _isInTopCategory(item.parentCategories) && userQualification ? 'clic-top' : category.id === 'MA_LISTE_CATEGORY_ID' ? 'clic-liste' : 'clic',
    track_cible: _getTrackCategory(category) + '-' + _getTrackVideo(item),
    track_detail: 'clic-element_mea-bandeau-' + page
  }
}

function _getTrackVideo (item) {
  return item.type + (item.videoType ? '/' + item.videoType : '') + '-' + item.id + '-' + item.title
}

function _getTrackCategory (category) {
  return category.title + '-' + category.id
}
// ------------------------------------------
function _isInTopCategory (items) {
  return !!items?.find(cat => cat.isTop)
}
// ----------------------------------------

export default {
  onClickTitleStrip,
  onClickAllButton,
  onClickElement
}
