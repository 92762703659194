export default {
  title: 'Filtre',
  filterOptions: {
    altTitle: 'Filtrer la catégorie',
    default: 'Aucun',
    rentMaxPrice: 'Location moins de {maxRentPrice}€',
    buyMaxPrice: 'Achat moins de {maxBuyPrice}€',
    pressScore: 'Notes presse 3 et +',
    spectatorScore: 'Notes spectateurs 3 et +',
    promotion: 'Promotion',
    everyone: 'Tous publics',
    duration: 'Moins d\'1h30'
  }
}
