import _get from 'lodash/get'

export default {
  getSelectedCategory (state) {
    return state.selectedCategory
  },
  getCategoryItems (state) {
    return _get(state.selectedCategory.articles, 'items')
  },
  getSelectedCategoryNature (state) {
    return _get(state.selectedCategory, 'nature')
  },
  /**
   * Get Selected Filters
   * @param {Object} state
   * @returns {Array}
   */
  getSelectedCategories (state) {
    return state.selectedCategories
  },
  /**
   * Get Avalable Filters options
   * @param {Object} state
   * @returns {Array}
   */
  getFiltersOptionsAvailable (state) {
    return state.availableFiltersOptions
  }
}
