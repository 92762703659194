import _get from 'lodash/get'

export default {
  getPageInfo (state) {
    return {
      pageName: _get(state, 'name', 'no page'),
      fullPath: _get(state, 'fullPath', 'no full path')
    }
  }
}
