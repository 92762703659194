import { parseCornerItemImages } from '../../components/shared/api.utils'

export default {
  /**
   * Store a corner if not stored yet
   * @param  {} state
   * @param  {} corner
   * @param config
   */
  setCachedCorner (state, { corner, config }) {
    if (!corner || !corner.id) {
      return
    }

    const cachedCorners = state.cachedCorners

    if (!cachedCorners.find(c => c.id === corner.id)) {
      cachedCorners.push(parseCornerItemImages(corner, config))
    }
  }
}
