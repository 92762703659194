import _get from 'lodash/get'
export default {
  getBookmarks (state) {
    return state.bookmarks
  },
  getBookmarkById: state => (itemId) => {
    return _get(state.bookmarks, 'articles.items', []).find((bookmark) => {
      return bookmark.id === itemId
    })
  }
}
