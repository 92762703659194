function onClickCornerItem (item, template) {
  return {
    track_page: `corner_${template}`,
    track_zone: 'bandeau',
    track_nom: 'video',
    track_type: 'clic',
    track_cible: `${item.type}-${item.id}-${item.title}`
  }
}
function onClickLaunchTrailerButton () {
  return {
    track_page: 'corner_BARKER',
    track_zone: 'player',
    track_nom: 'launch-trailer-button',
    track_type: 'clic',
    track_cible: 'launch-trailer-list'
  }
}

export default {
  onClickCornerItem,
  onClickLaunchTrailerButton
}
