<template>
  <div
    v-if="show"
    :class="[
      'notification',
      { 'notification-menu': notificationMenu },
      { 'notification-cover': !notificationMenu }]" />
</template>

<script>
export default {
  name: 'Notification',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    notificationMenu: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.notification {
  position: absolute;
  border: solid 1px $white;
  background-color: $alert;
  border-radius: 50%;

  &.notification-cover {
    z-index: 9;
    top: 0;
    right: 0;
    transform: translate(35%, -35%);
    @include mediaQueries('width', (xsmall: 15px, medium: 20px));
    @include mediaQueries('height', (xsmall: 15px, medium: 20px));
  }

  &.notification-menu {
    @include mediaQueries('top', (xsmall: 0.85rem, large: 0.46rem));
    @include mediaQueries('right', (xsmall: -0.3rem, large: -0.3rem));
    width: 10px;
    height: 10px;
  }
}

</style>
