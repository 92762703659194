import {
  formatAxiosError,
  formatPlayerError,
  formatGenericError,
  ContentError
} from '../../components/shared/errors/formatErrors/formatErrors'

export default {
  setPlayerError ({ rootState, rootGetters, dispatch, commit }, { error, bypass = false }) {
    commit('setNbErrorsInSession')
    const nbInSession = rootGetters['errors/getNbErrorsInSession']
    const pageInfo = rootGetters['route/getPageInfo']
    const playerError = formatPlayerError(error, {
      ...pageInfo,
      nbInSession,
      portalVersion: this.$config.appVersion
    })
    dispatch('user/postErrorLogs', { error: playerError }, { root: true })

    if (!bypass) {
      commit('setError', playerError)
    }
  },
  setAxiosError ({ rootState, rootGetters, dispatch, commit }, { error, bypass = false }) {
    commit('setNbErrorsInSession')
    const nbInSession = rootGetters['errors/getNbErrorsInSession']
    const pageInfo = rootGetters['route/getPageInfo']
    const axiosError = formatAxiosError(error, {
      ...pageInfo,
      nbInSession,
      portalVersion: this.$config.appVersion
    })
    dispatch('user/postErrorLogs', { error: axiosError }, { root: true })

    if (!bypass) {
      commit('setError', axiosError)
    }
  },
  setGenericError ({ rootState, rootGetters, dispatch, commit }, { error, bypass = false }) {
    commit('setNbErrorsInSession')
    const nbInSession = rootGetters['errors/getNbErrorsInSession']
    const pageInfo = rootGetters['route/getPageInfo']
    const genericError = formatGenericError(error, {
      ...pageInfo,
      nbInSession,
      portalVersion: this.$config.appVersion
    })
    dispatch('user/postErrorLogs', { error: genericError }, { root: true })

    if (!bypass) {
      commit('setError', genericError)
    }
  },
  setContentError ({ rootGetters, dispatch, commit }, { errorCode, bypass = false }) {
    commit('setNbErrorsInSession')
    const nbInSession = rootGetters['errors/getNbErrorsInSession']
    const pageInfo = rootGetters['route/getPageInfo']
    const portalVersion = this.$config.appVersion
    const contentError = new ContentError({
      errorCode,
      ...pageInfo,
      nbInSession,
      portalVersion
    })
    dispatch('user/postErrorLogs', { error: contentError }, { root: true })

    if (!bypass) {
      commit('setError', contentError)
    }
  }
}
