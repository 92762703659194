import _has from 'lodash/has'
import _get from 'lodash/get'
import axios from 'axios'
import { tokenIsEpired } from '../shared/utils'

/**
 * Prepare TVM Catchup NOT AUTH API
 * @param $config
 * @param store
 * @param headers
 * @param env
 * @returns {AxiosInstance}
 * @private
 */
function _getTvmCatchupNotAuthApi ({ $config, store, headers = {} }) {
  return _createAxiosInstance({
    baseURL: process.server ? _get($config, 'confetti.private.api.catchup.notAuthServerBaseUrl') : _get($config, 'confetti.public.api.catchup.notAuthBaseUrl')
  }, store, headers)
}

/**
 * Prepare TVM Catchup AUTH API
 * @param $config
 * @param reqHeaders
 * @param store
 * @returns {AxiosInstance}
 * @private
 */
function _getTvmCatchupAuthApi ({ $config, headers, store }) {
  return _createAxiosInstance({
    baseURL: process.server ? _get($config, 'confetti.private.api.catchup.authServerBaseUrl') : _get($config, 'confetti.public.api.catchup.authBaseUrl'),
    withCredentials: true
  }, store, headers, true)
}

/**
 * Prepare Gateway wassup API
 * @param $config
 * @param store
 * @param headers
 * @param module
 * @returns {any}
 * @private
 */
function _getGatewayAuthApi ({ $config, store, headers }, module) {
  let baseURL = _get($config, `confetti.public.api.gateway.${module}.authBaseUrl`)

  if (process.server && _has($config, `confetti.private.api.gateway.${module}.authServerBaseUrl`)) {
    baseURL = _get($config, `confetti.private.api.gateway.${module}.authServerBaseUrl`)
  }

  return _createAxiosInstance({
    headers: headers || {},
    baseURL,
    withCredentials: true
  }, store, headers, true)
}

/**
 * Prepare Gateway wassup API
 * @param $config
 * @param module
 * @returns {any}
 * @private
 */
function _getGatewayNotAuthApi ({ $config, headers }, module) {
  let baseURL = _get($config, `confetti.public.api.gateway.${module}.notAuthBaseUrl`)

  if (process.server && _has($config, `confetti.private.api.gateway.${module}.notAuthServerBaseUrl`)) {
    baseURL = _get($config, `confetti.private.api.gateway.${module}.notAuthServerBaseUrl`)
  }

  return _createAxiosInstance({ baseURL }, headers)
}

/**
 * Prepare TVM RP API
 * @param $config
 * @returns {any}
 * @private
 */
function _getTvmNotAuthApi ({ $config, headers }) {
  return _createAxiosInstance({
    baseURL: process.server ? _get($config, 'confetti.private.api.tvm.notAuthServerBaseUrl') : _get($config, 'confetti.public.api.tvm.notAuthBaseUrl')
  }, headers)
}

/**
 * Prepare TVM Wassup API for logs
 * @param $config
 * @param store
 * @param headers
 * @returns {any}
 * @private
 */
function _getTvmAuthApiErrorLogs ({ $config, store, headers }) {
  return _createAxiosInstance({
    headers: headers || {},
    baseURL: process.server ? _get($config, 'confetti.private.api.tvm.logServerBaseUrl') : _get($config, 'confetti.public.api.tvm.logBaseUrl'),
    withCredentials: true
  }, store, headers, true, $config)
}

/**
 * Prepare TVM Wassup API
 * @param $config
 * @param store
 * @param headers
 * @returns {any}
 * @private
 */
function _getTvmAuthApi ({ $config, store, headers }) {
  return _createAxiosInstance({
    headers: headers || {},
    baseURL: process.server ? _get($config, 'confetti.private.api.tvm.authServerBaseUrl') : _get($config, 'confetti.public.api.tvm.authBaseUrl'),
    withCredentials: true
  }, store, headers, true, $config)
}

/**
 * Prepare User API
 * @param $config
 * @param store
 * @param headers
 * @returns {AxiosInstance}
 * @private
 */
function _getUserAuthApi ({ $config, store, headers }) {
  return _createAxiosInstance({
    headers: headers || {},
    baseURL: process.server ? _get($config, 'confetti.private.api.user.authServerBaseUrl') : _get($config, 'confetti.public.api.user.authBaseUrl'),
    withCredentials: true
  }, store, headers, true)
}

/**
 * Prepare Internal API
 * @param $config
 * @returns {any}
 * @private
 */
function _getInternalApi ({ $config, headers }) {
  return _createAxiosInstance({
    baseURL: process.server ? _get($config, 'confetti.private.api.internal.serverBaseUrl') : _get($config, 'confetti.public.api.internal.baseUrl')
  },headers)
}

/**
 *
 * @param $config
 * @returns {any}
 * @private
 */
function _getMagTvApi ({ $config, headers }) {
  return _createAxiosInstance({
    baseURL: process.server ? _get($config, 'confetti.private.api.magTv.serverBaseUrl') : _get($config, 'confetti.public.api.magTv.baseUrl'),
    timeout: _get($config, 'confetti.public.api.magTv.timeout')
  }, headers)
}

/**
 * Prépare Erable wassup API
 * @param {*} param0
 */
function _getErableApi ({ $config, store, headers }) {
  return _createAxiosInstance({
    headers: {
      'X-Orange-User-Id': `0x${headers.x_wassup_ulv}`,
      'session-id': headers['x-wassup-sid'],
      'client-ip': headers['client-ip'],
      'X-Orange-OriginId': _get($config, 'confetti.public.originId'), //FVD -> VOD / FTD --> Replay
      'Authorization': `Bearer ${process.env.TORII_ACCESS_TOKEN}`
    },
    baseURL: _get($config, `confetti.private.api.erable.serverBaseUrl`),
    withCredentials: true
  }, store, headers)
}

/**
 * Prepare Maxymiser API
 * @param $config
 * @param store
 * @param reqHeaders
 * @returns {any}
 * @private
 */
function _getMaxymiserApi ({ $config, store, headers }) {
  return _createAxiosInstance({
    baseURL: _get($config, 'confetti.private.api.maxymiser.serverBaseUrl')
  }, store, headers)
}

/**
 * Add Interceptor to set Maintenance Code if needed
 * @param options
 * @param store
 * @param headers
 * @param isWassup
 */
function _createAxiosInstance (options, store, headers, isWassup) {
  // Setup axios instance adapter with axios-cache-adapter global instance.
  if (global.cache) {
    options.adapter = global.cache.adapter
  }

  const axiosInstance = axios.create(options)

  if (isWassup) {
    axiosInstance.interceptors.request.use(function (config) {
      const isAuthenticated = store.getters['core/getProfile'].isAuthenticated || _get(headers, 'x_wassup_pulo')
      if (isAuthenticated && store.getters['core/getTokenTvInit']) {
        const wassupHeaders = process.server ? headers : store.getters['core/getWassupHeaders']
        config.headers.tv_token = `Bearer ${store.getters['core/getTokenTvInit']}`
        config.headers = Object.assign(config.headers, wassupHeaders)
      }
      return config
    })
  }

  axiosInstance.interceptors.response.use((response) => {
    const maintenanceCode = _get(response, 'headers.x_vod_maintenance_code', null)
    if (maintenanceCode && store) {
      store.commit('errors/setMaintenanceCode', maintenanceCode)
    }
    return response
  }, (error) => {
      const originalRequest = error.config

      if (tokenIsEpired(error)) {
        return axios.get('/token')
          .then(response => store.commit('core/setToken', response.data))
          .then(() => {
            originalRequest.headers.tv_token = `Bearer ${store.getters['core/getTokenTvInit']}`
            return axios(originalRequest)
          })
          .catch((error) => {
            if (error) {
              store.commit('core/setErrorOnTokenTvInit', error)
            }
            return Promise.resolve()
          })
        }
      return Promise.reject(error)
    })

  return axiosInstance
}

export default ({ store, $config, req }, inject) => {
  const { host, ...headers } = _get(req, 'headers', {})

  inject('http', {
    internal: _getInternalApi({ $config, headers: {'user-agent': headers['user-agent']} }),
    magTv: _getMagTvApi({ $config, headers: {'user-agent': headers['user-agent']} }),
    gateway: {
      stream: {
        auth: _getGatewayAuthApi({ $config, store, headers }, 'stream')
      },
      npvr: {
        auth: _getGatewayAuthApi({ $config, store, headers }, 'npvr')
      },
      pds: {
        notAuth: _getGatewayNotAuthApi({ $config, headers: {'user-agent': headers['user-agent']} }, 'pds')
      },
      playHistory: {
        auth: _getGatewayAuthApi({ $config, store, headers }, 'playHistory')
      },
      wishlist: {
        auth: _getGatewayAuthApi({ $config, store, headers }, 'wishlist')
      },
      playback: {
        auth: _getGatewayAuthApi({ $config, store, headers }, 'playback'),
        notAuth: _getGatewayNotAuthApi({ $config, headers: {'user-agent': headers['user-agent']} }, 'playback')
      }
    },
    tvm: {
      notAuth: _getTvmNotAuthApi({ $config, headers: {'user-agent': headers['user-agent']} }),
      auth: _getTvmAuthApi({ $config, store, headers }),
      authErrorLogs: _getTvmAuthApiErrorLogs({ $config, store, headers })
    },
    user: {
      auth: _getUserAuthApi({ $config, store, headers })
    },
    erable: {
      instanceInfo: _getErableApi({ $config, store, headers })
    },
    maxymiser: _getMaxymiserApi({ $config, store, headers }),
    catchup: {
      notAuth: _getTvmCatchupNotAuthApi({ $config, store, headers }),
      auth: _getTvmCatchupAuthApi({ $config, store, headers })
    }
  })
}
