import _get from 'lodash/get'

/**
 * returns first season of serie that
 * @param {*} eligiblePromos
 * @param {*} articlesIds
 */
function getFirstEligibleSeasonId (eligiblePromos, articlesIds) {
  return eligiblePromos?.map((promo) => {
    return _get(promo, 'seriesDiscount.seasonIds')
      ?.find((seasonId) => {
        return !articlesIds?.includes(seasonId)
      })
  })[0]
}

// -----------------------------------------------------

export default getFirstEligibleSeasonId
