import { DateTime } from 'luxon'

export default function (timestamp) {
  if (!timestamp || timestamp < 0) {
    return ''
  }

  const date = DateTime.fromMillis(timestamp)
  return date.toFormat('HH\'h\'mm')
}
