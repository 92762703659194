import category from './category'
import corner from './corner'
import errors from './errors'
import filters from './filters'
import fip from './fip'
import general from './general'
import home from './home'
import modals from './modals'
import myaccount from './myaccount'
import myalerts from './myalerts'
import mybookmarks from './mybookmarks'
import myvideos from './myvideos'
import navik from './navik'
import pack from './pack'
import panelItem from './panelItem'
import payment from './payment'
import player from './player'
import season from './season'
import socialMetaTags from './socialMetaTags'
import sort from './sort'

export default {
  category,
  corner,
  errors,
  filters,
  fip,
  general,
  home,
  modals,
  myaccount,
  myalerts,
  mybookmarks,
  myvideos,
  navik,
  pack,
  panelItem,
  payment,
  player,
  season,
  socialMetaTags,
  sort
}
