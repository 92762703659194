import _get from 'lodash/get'
import urlJoin from 'url-join'

export default {
  /**
   * Fetch trailer url
   * @param trailerId
   * @param terminalModel
   * @returns {Promise<T | never>}
   */
  fetchTrailer (terminalModel, trailerId) {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.users'),
      _get(this.$config, 'confetti.public.api.tvm.paths.playableTrailers'),
      trailerId,
      `?terminalModel=${terminalModel}`
    )
    const method = 'api.playableTrailer.fetchTrailer()'

    return this.$http.tvm.auth.get(path)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  }
}
