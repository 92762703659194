import urlJoin from 'url-join'
import _get from 'lodash/get'

export default {
  /**
     * Get Campaign Variant for current user
     * @param visitorId (householdId)
     * @returns {Promise<T | never>}
     */
  getUserGeneration (visitorId) {
    const path = urlJoin(
      _get(this.$config, 'confetti.private.api.maxymiser.siteId'),
      _get(this.$config, 'confetti.private.api.maxymiser.paths.generations')
    )
    const timeout = parseInt(this.$config.confetti.public.api.maxymiser.timeout)
    const method = 'api.generations.getUserGeneration'
    const campaigns = [
      {
        name: _get(this.$config, 'confetti.public.abTesting.currentCampaign')
      }
    ]

    // set api options
    const options = {
      params: { visitorId },
      timeout
    }

    // set api proxy option
    const proxy = _get(this.$config, 'confetti.private.api.maxymiser.proxy', {})
    if (proxy.host && proxy.port) {
      options.proxy = proxy
    }

    return this.$http.maxymiser.post(path, { campaigns }, options)
      .then(response => Promise.resolve(response.data))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method, bypass: true }))
  }
}
