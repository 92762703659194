import deviceAvailability from './deviceAvailability/deviceAvailability'
import capacities from './capacities/capacities'
import user from './user/user'

/**
 * Main test to make auth requests
 * @param profile
 * @returns {boolean|boolean|*|(function(*): *)|(function(*): *)}
 */
export function profileHasAccess (profile) {
  return (
    profile.isAuthenticated &&
    profile.suspended === 'NA' &&
    !profile.isMobile &&
    profile.hasTvRights &&
    profile.isUe
  )
}

/**
 * Checkups leading to modal if not valid - various step based on options
 * @param store
 * @param profile
 * @param firstRender
 * @param requiresAuth
 * @param requiresCapabilities
 * @param route
 * @returns {Promise<SimpleCacheConfigurator.never | void>}
 */
export function accessChecks (store, profile, firstRender, requiresAuth, requiresCapabilities, route) {
  return user.isUe(profile)
    .then(() => user.isNotSuspended(profile))
    .then(() => user.hasTvRights(profile, firstRender))
    .then(() => user.hasErrorOnTokenTvInit(store))
    .then(() => user.isAuthenticated(requiresAuth, profile))
    .then(() => capacities.checkCanPlayCapacity(requiresCapabilities, store, route))
    .then(() => deviceAvailability.checkDeviceAvailability(requiresCapabilities, store, route))
    .catch(({ componentName, props }) => {
      store.commit('config/addModalToOpen', { componentName, props })
      return Promise.reject()
    })
}
