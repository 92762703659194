export default {
  setError: (state, err) => {
    state.errors.push(err)
  },
  setNbErrorsInSession: (state) => {
    state.nbErrorsInSession++
  },
  setMaintenanceCode (state, maintenanceCode) {
    state.maintenanceCode = maintenanceCode
  }
}
