import _get from 'lodash/get'

export default {
  /**
   * Retrieve trailer URL and store it
   * @param commit
   * @param dispatch
   * @param rootGetters
   * @param trailerId
   * @returns {Promise<T | never | never>}
   */
  fetchTrailer ({ commit, dispatch, rootGetters }, trailerId) {
    const instance = _get(this.$config, 'confetti.public.enablePlaybackForTrailer', false)
      ? this.$api.gateway.playback
      : this.$api.tvm.playableTrailers
    return instance.fetchTrailer(rootGetters['terminals/getTerminalModel'], trailerId)
      .then(response => commit('setTrailer', { url: response.streamUrl }))
      .catch((err) => {
        // We must separate trailer list error handling and trailer error handling
        // Check if there is a list of trailer, resolve it to bypass error handling
        // It allows to launch the next trailer of the list
        if (rootGetters['player/isTrailerList']) {
          // Changing the value of setEndOfTrailer allows to watch EndOfTrailer
          // and launch the next trailer even if the current trailer has failed
          commit('setEndOfTrailer', true)
          return Promise.resolve()
        } else {
          dispatch('errors/setAxiosError', { error: err }, { root: true })
          commit('setTrailer', null)
          return Promise.reject()
        }
      })
  },

  /**
   * Retrieve video URL and store it
   * @param commit
   * @param dispatch
   * @param rootGetters
   * @param playId
   * @param giftId
   * @param usage
   * @returns {Promise<T | never>}
   */
  fetchVideo ({ commit, dispatch, rootGetters }, { movieId, giftId, usage }) {
    const instance = _get(this.$config, 'confetti.public.enablePlaybackForMovies', false)
      ? this.$api.gateway.playback
      : this.$api.tvm.playableVideos
    return instance.fetchVideo(
      rootGetters['terminals/getTerminalModel'],
      rootGetters['user/getUniqueId'],
      movieId,
      giftId)
      .then(response => commit('setVideo', response))
      .catch((err) => {
        dispatch('errors/setAxiosError', { error: { ...err, movieId, usage } }, { root: true })
        commit('setVideo', null)
        return Promise.reject()
      })
  },
  /**
   * Retrieve safari and chrome certificates  data and store it
   * @param commit
   * @param dispatch
   * @returns {Promise<T>}
   */
  getCertificates ({ commit }) {
    return Promise.all([
      this.$api.external.certificate.getCertificate(true),
      this.$api.external.certificate.getCertificate(false)
    ]).then((certificates) => {
      commit('setCertificateSafari', { certificate: certificates[0] })
      commit('setCertificateChrome', { certificate: certificates[1] })
    }).catch(() => {
      commit('setCertificateSafari', { certificate: '' })
      commit('setCertificateChrome', { certificate: '' })
    })
  },
  /**
   * Update the video timecode
   * @param commit
   * @param articleId
   * @param playId
   * @param timecode
   * @returns {*}
   */
  updateTimecode ({ commit }, { articleId, timecode }) {
    return this.$api.gateway.playHistory.updateTimecode(articleId, timecode)
      .then(() => commit('user/setPlayHistoryTimecode', { articleId, timecode }, { root: true }))
  },
  /**
     * End the current session
     * @param commit
     * @param sessionId
     * @returns {Promise<any | never>}
     */
  endSession ({ commit }, sessionId) {
    const instance = _get(this.$config, 'confetti.public.enablePlaybackForMovies', false)
      ? this.$api.gateway.playback
      : this.$api.tvm.playableVideos
    return instance.endSession(sessionId)
  }
}
