import urlJoin from 'url-join'
import _get from 'lodash/get'

export default {
  getSeason (seasonId) {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.seasons'),
      seasonId)
    const method = 'api.season.getSeason'
    const options = {
      cache: {
        exclude: { query: false }
      }
    }

    return this.$http.tvm.notAuth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  }
}
