import _get from 'lodash/get'

export default {
  getAlert (state) {
    return _get(state, 'alert')
  },
  getAlerts (state) {
    return _get(state, 'alerts')
  },
  getAlertById: state => (itemId) => {
    return _get(state, 'alerts.articles.items', []).find(alert => alert.id === itemId)
  },
  getCounterNotification (state) {
    return _get(state, 'counter')
  }
}
