function onClickCgs (definition, item) {
  return {
    track_page: 'paiement',
    track_zone: 'cgv',
    track_nom: 'popin',
    track_type: 'affichage',
    track_cible: _getTrackCible(definition, item),
    track_detail: 'affichage-popin-cgv-paiement'
  }
}

function onClickCancel (definition, item, userQualification, isInMyAlerts, isInMyBookmarks) {
  return {
    track_page: 'paiement',
    track_zone: 'annuler',
    track_nom: definition.usage,
    track_type: _isInTopCategory(item.parentCategories) && userQualification ? 'clic-top' : isInMyAlerts ? 'clic-alerte' : isInMyBookmarks ? 'clic-favori' : 'clic',
    track_cible: _getTrackCible(definition, item),
    track_detail: `click-${definition.usage}-annuler-paiement`
  }
}

function onClickConfirm (definition, item, userQualification, isInMyAlerts, isInMyBookmarks) {
  return {
    track_page: 'paiement',
    track_zone: 'confirmer',
    track_nom: definition.usage,
    track_type: _isInTopCategory(item.parentCategories) && userQualification ? 'clic-top' : isInMyAlerts ? 'clic-alerte' : isInMyBookmarks ? 'clic-favori' : 'clic',
    track_cible: _getTrackCible(definition, item),
    track_detail: `clic-${definition.usage}-confirmer-paiement`
  }
}

function onConfirmSuccess (definition, item, id) {
  const videoType = item.videoType ? `/${item.videoType}` : ''
  const discount = definition.discountId ? `${definition.discountId}${definition.promoName}` : ''
  const trackCible = `${item.type}${videoType}-${item.title}-${discount}`

  return {
    product_brand: definition.discountId ? `${definition.discountId}/${definition.name}` : 'catalog',
    product_category: `${item.type}${videoType}`,
    product_id: id,
    product_name: item.title,
    product_price: definition.discountPrice ? definition.discountPrice.toString() : definition.price.toString(),
    product_variant: `${definition.usage}/${definition.quality}`,
    product_quantity: '1',
    tealium_event: 'purchase',
    track_cible: trackCible,
    page: 'paiement',
    zone: 'event_purchase',
    area: 'confirmer_paiement',
    type: 'clic',
    transaction_id: new Date().getTime() + '_' + id
  }
}

// ---------------------------------------------------------

/**
 * Get track cible for payment
 * @param definition
 * @param item
 * @returns {string}
 * @private
 */
function _getTrackCible (definition, item) {
  const discount = definition.discountId ? `${definition.discountId}-${definition.name}-` : ''
  const videoType = item.videoType ? `/${item.videoType}` : ''
  return `${discount}${item.type}${videoType}-${item.id}-${item.title}`
}

function _isInTopCategory (items) {
  return !!items?.find(cat => cat.isTop)
}

// ---------------------------------------------------------

export default {
  onClickCgs,
  onClickCancel,
  onClickConfirm,
  onConfirmSuccess
}
