import logger from '@wptv/logger'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isSafari: 'useragent/isSafari',
      isAndroid: 'useragent/isAndroid'
    })
  },
  mounted () {
    /**
     * check play capacities and show modal if needed
     * if user checked do not ask again
     */
    return this.getCanPlay()
      .then((capacity) => {
        if (!localStorage.getItem('DSAChecked') && !capacity?.canPlay) {
          this.$root.$emit('open-implicit-tests')
        }
        this.$store.commit('checkups/setConfigTests', { capacity })
      })
  },
  methods: {
    /**
     * Parse results from mse eme test
     * @returns {Promise<{code: null, canPlay: boolean}>}
     */
    getCanPlay () {
      const result = {
        canPlay: false,
        code: null
      }

      return this._checkMseEmeCapabilities().then((capabilities) => {
        if (!capabilities.hlsClear.canPlay && !capabilities.clear.canPlay) {
          result.code = 'BA_KO'
        } else if (!capabilities.hlsDrm.canPlay && !capabilities.drm.canPlay) {
          result.code = 'DRM_KO'
        } else {
          result.canPlay = true
        }

        return result
      })
    },
    /**
     * Load mse eme capabilities script and retrieve test result
     * @returns {Promise<{hlsClear: unknown, clear: unknown, drm: unknown, hlsDrm: unknown} | void>}
     * @private
     */
    _checkMseEmeCapabilities () {
      return import('mse-eme-capabilities.js')
        .then((mseemecapabilities) => {
          return Promise.all([
            mseemecapabilities.hasplayerCanPlay(false),
            mseemecapabilities.hasplayerCanPlay(true),
            mseemecapabilities.hasplayerCanPlay(false, 'HLS'),
            mseemecapabilities.hasplayerCanPlay(true, 'HLS')
          ])
        })
        .then(([clear, drm, hlsClear, hlsDrm]) => ({ clear, drm, hlsClear, hlsDrm }))
        .catch((error) => {
          // TODO Manage error
          logger.log(`[MSEEME] mse eme tests failed with error: ${error}`)
        })
    }
  }
}
