import _get from 'lodash/get'
import urlJoin from 'url-join'

export default {
  /**
   * Retrieve all the alerts
   * @returns {Promise<any | never>}
   */
  getAlerts () {
    const path = urlJoin(
      _get(this.$config, 'confetti.public.api.gateway.paths.alerts'),
      _get(this.$config, 'confetti.public.api.gateway.paths.catalogs'),
      _get(this.$config, 'confetti.public.api.gateway.universeId'))
    const method = 'api.alertsApi.getAlerts()'

    return this.$http.gateway.wishlist.auth.get(path)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method, bypass: true }))
  },
  /**
   * Retrieve a specific alert by its videoId
   * @param videoId
   * @returns {Promise<any | never>}
   */
  getAlertById (videoId) {
    const path = urlJoin(
      _get(this.$config, 'confetti.public.api.gateway.paths.alerts'),
      _get(this.$config, 'confetti.public.api.gateway.paths.catalogs'),
      _get(this.$config, 'confetti.public.api.gateway.universeId'),
      _get(this.$config, 'confetti.public.api.gateway.paths.articles'),
      videoId)
    const method = 'api.alertsApi.getAlertById()'

    return this.$http.gateway.wishlist.auth.get(path)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method, bypass: true }))
  },
  /**
   * set an alert by videoId
   * @param videoId
   * @returns {Promise<any | never>}
   */
  setAlert (videoId) {
    const path = urlJoin(
      _get(this.$config, 'confetti.public.api.gateway.paths.alerts'),
      _get(this.$config, 'confetti.public.api.gateway.paths.catalogs'),
      _get(this.$config, 'confetti.public.api.gateway.universeId'),
      _get(this.$config, 'confetti.public.api.gateway.paths.articles'))
    const method = 'api.alertsApi.setAlert()'

    return this.$http.gateway.wishlist.auth.post(path, { articleId: videoId })
      .then(() => Promise.resolve('success'))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method, bypass: true }))
  },
  /**
   * delete a specific alert by its videoId
   * @param videoId
   * @returns {Promise<any | never>}
   */
  deleteAlert (videoId) {
    const path = urlJoin(
      _get(this.$config, 'confetti.public.api.gateway.paths.alerts'),
      _get(this.$config, 'confetti.public.api.gateway.paths.catalogs'),
      _get(this.$config, 'confetti.public.api.gateway.universeId'),
      _get(this.$config, 'confetti.public.api.gateway.paths.articles'),
      videoId)
    const method = 'api.alertsApi.deleteAlert()'

    return this.$http.gateway.wishlist.auth.delete(path)
      .then(() => Promise.resolve('success'))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method, bypass: true }))
  },

  /**
   *
   * @returns {Promise<string | SimpleCacheConfigurator.never>}
   */
  deleteNotifications () {
    const path = urlJoin(
      _get(this.$config, 'confetti.public.api.gateway.paths.alerts'),
      _get(this.$config, 'confetti.public.api.gateway.paths.catalogs'),
      _get(this.$config, 'confetti.public.api.gateway.universeId'),
      _get(this.$config, 'confetti.public.api.gateway.paths.notifiedAlerts'))
    const method = 'api.alertsApi.deleteNotifications()'

    return this.$http.gateway.wishlist.auth.delete(path)
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method, bypass: true }))
  },

  /**
   *
   * @returns {Promise<* | SimpleCacheConfigurator.never>}
   */
  getCounterNotification () {
    const path = urlJoin(
      _get(this.$config, 'confetti.public.api.gateway.paths.alerts'),
      _get(this.$config, 'confetti.public.api.gateway.paths.catalogs'),
      _get(this.$config, 'confetti.public.api.gateway.universeId'),
      _get(this.$config, 'confetti.public.api.gateway.paths.notifiedAlerts'),
      _get(this.$config, 'confetti.public.api.gateway.paths.counter'))
    const method = 'api.alertsApi.getCounterNotification()'

    return this.$http.gateway.wishlist.auth.get(path)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method, bypass: true }))
  }
}
