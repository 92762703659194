export default function (duration) {
  let durationString = ''
  if (duration < 60) {
    durationString = duration + 'min'
  } else {
    const hours = Math.floor(duration / 60)
    const min = Math.round(duration - (hours * 60)) < 10 ? '0' + Math.round(duration - (hours * 60)) : Math.round(duration - (hours * 60))
    durationString = hours + 'h' + min + 'min'
  }
  return durationString
}
