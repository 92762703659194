var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],attrs:{"id":"vodSearchEngineModal"}},[_c('div',{attrs:{"id":"vodSearchEngine"}}),_vm._v(" "),_c('button',{directives:[{name:"tealium",rawName:"v-tealium:click",value:({
        track_page: 'toutes_pages',
        track_zone: 'recherche',
        track_nom: 'fermer_cartouche',
        track_type: 'clic',
        track_cible: '',
        track_detail: 'clic-fermer_cartouche-recherche-toutes_pages'
      }),expression:"{\n        track_page: 'toutes_pages',\n        track_zone: 'recherche',\n        track_nom: 'fermer_cartouche',\n        track_type: 'clic',\n        track_cible: '',\n        track_detail: 'clic-fermer_cartouche-recherche-toutes_pages'\n      }",arg:"click"}],attrs:{"id":"vodSearchEngineClose","title":_vm.$t('general.menu.searchCloseCrossLabel'),"data-wptv":"recherche-fermer_cartouche"},on:{"click":function($event){return _vm.toggle()}}},[_c('wptv-icon',{attrs:{"invert":true,"name":"close-cross","height":"22px","width":"22px"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }