import apiConstants from '../../api.constants'
import _get from 'lodash/get'
import formatCategoryUtils from '../shared/formatCategory.utils'
import filterOptions from '../../components/shared/sortAndFilter/filterOptions'
import sortOptions from '../../components/shared/sortAndFilter/sortOptions'

export default {
  setUser (state, user) {
    state.user = user
  },
  setMyVideos (state, myVideos) {
    // set default filters
    const defaultFilters = formatCategoryUtils.getFormattedOptions({
      options: [
        filterOptions.everyone,
        filterOptions.duration
      ],
      translate: _get(this.app, 'i18n')
    })

    // set default sorting criterias
    const defaultSortingCriterias = formatCategoryUtils.getFormattedOptions({
      options: [
        sortOptions.aToZ,
        sortOptions.zToA
      ],
      translate: _get(this.app, 'i18n')
    })
    state.myVideos = formatCategoryUtils.formatCategory(myVideos, defaultFilters, defaultSortingCriterias)
  },
  setMyVideosCategory (state, myVideosCategory) {
    // set default filters
    const defaultFilters = formatCategoryUtils.getFormattedOptions({
      options: [
        filterOptions.everyone,
        filterOptions.duration
      ],
      translate: _get(this.app, 'i18n')
    })

    // set default sorting criterias
    const defaultSortingCriterias = formatCategoryUtils.getFormattedOptions({
      options: [
        sortOptions.aToZ,
        sortOptions.zToA
      ],
      translate: _get(this.app, 'i18n')
    })

    // populate state with formatted videos category with filters and sorting criterias
    state.myVideosCategory = formatCategoryUtils.formatCategory(myVideosCategory, defaultFilters, defaultSortingCriterias)
  },
  setUserDiscounts (state, discounts) {
    state.userDiscounts = discounts
  },
  setPrepaidAccount (state, prepaidAccount) {
    state.prepaidAccount = prepaidAccount
  },
  setActivatedCoupon (state, discountId) {
    const discount = state.userDiscounts.find(userDiscount => userDiscount.id === discountId)
    if (discount) {
      discount.eligibility = apiConstants.PROMO.SATISFIED
    }
    state.activatedCoupon = discount || {}
  },
  setCouponCode (state, couponCode) { // /!\ used in payment request params
    state.couponCode = couponCode
  },
  resetActivatedCoupon (state, discountId) {
    const discount = state.userDiscounts.find(userDiscount => userDiscount.id === discountId)
    if (discount) {
      discount.eligibility = apiConstants.PROMO.CLIENT_RESPONSIBILITY
    }
    state.activatedCoupon = {}
  },
  setMyArticles (state, myArticles) {
    state.myArticles = myArticles

    const myArticlesIdsTemp = []
    myArticles.forEach((article) => {
      if (article.type === 'SEASON') {
        article.episodes.forEach((episode) => {
          myArticlesIdsTemp.push(episode.id)
        })
      }
      if (article.type === 'PACKAGE') {
        article.videos.forEach((video) => {
          myArticlesIdsTemp.push(video.id)
        })
      }
      myArticlesIdsTemp.push(article.id)
    })

    state.myArticlesIds = myArticlesIdsTemp
    // TODO Check if necessary (could directly use myArticles now?)
  },
  setPlayHistoryMap (state, playHistory) {
    if (playHistory && playHistory.length > 0) {
      const playHistoryTmp = []

      playHistory.forEach((item) => {
        if (item.type === 'SEASON') {
          item.episodes.forEach((episode) => {
            playHistoryTmp.push(episode)
          })
        }
        if (item.type === 'PACKAGE') {
          item.videos.forEach((video) => {
            playHistoryTmp.push(video)
          })
        }
        playHistoryTmp.push(item)
      })

      state.playHistory = playHistoryTmp
    }
  },
  updateSinglePlayHistory (state, playHistory) {
    const playHistoryToUpdate = state.playHistory.find(item => item.id === playHistory.id)

    if (playHistoryToUpdate) {
      Object.keys(playHistory).forEach((key) => {
        playHistoryToUpdate[key] = playHistory[key]
      })
    }
  },
  setPlayHistoryTimecode (state, { articleId, timecode }) {
    const playHistory = state.playHistory.find(item => item.id === articleId)

    if (playHistory) {
      playHistory.timecode = timecode
    }
  },
  setGeneration (state, generations) {
    state.generation = generations[0] || {}
  }
}
