import apiConstants from '../../api.constants'
import _get from 'lodash/get'

export default {
  getOsName (state) {
    return _get(state, 'userAgent.os.name')
  },
  isMac (state, getters) {
    return getters.getOsName === 'Mac OS'
  },
  isWindows (state, getters) {
    return getters.getOsName === 'Windows'
  },
  isWindowsPhone (state, getters) {
    return getters.getOsName === 'Windows Phone'
  },
  isAndroid (state, getters) {
    return getters.getOsName === 'Android'
  },
  isIOS (state, getters) {
    return getters.getOsName === 'iOS'
  },
  getDeviceType (state) {
    return state.userAgent.device ? state.userAgent.device.type : 'pc'
  },
  isPC (state, getters) {
    return getters.isMac || getters.isWindows
  },
  isCompatibleMobile (state, getters) {
    return getters.isAndroid || getters.isIOS
  },
  getBrowserName (state) {
    return state.userAgent.browser.name
  },
  getBrowserMajor (state) {
    return state.userAgent.browser.major
  },
  getOsVersion (state) {
    return state.userAgent.os.version
  },
  isSafari (state) {
    return state.userAgent.browser?.name.toLowerCase().includes('safari')
  },
  isIpad (state) {
    return state.userAgent.device.model === 'iPad'
  },
  isEdge (state) {
    return state.userAgent.browser.name.toLowerCase().includes('Edge')
  },
  isOsCompatibleForMLV (state) {
    return apiConstants.MLV.COMPATIBLE_OSES.includes(item => item === _get(state, 'userAgent.os.name'))
  },
  getUserAgent (state) {
    return state.userAgent
  }
}
