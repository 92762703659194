function onclicMosaicItem (item, page, isTop, isInMyAlerts, isInMyBookmarks, userQualification) {
  return {
    page,
    zone: page === 'mes_favoris' ? 'mosaique-favoris' : 'mosaique',
    area: 'video',
    cible: `${item.type}${_videoType(item)}-${item.id}-${item.title}`,
    type: isTop && userQualification ? 'clic-top' : isInMyAlerts ? 'clic-alerte' : isInMyBookmarks ? 'clic-favori' : 'clic'
  }
}

function _videoType (item) {
  return item.videoType ? `/${item.videoType}` : ''
}

export default {
  onclicMosaicItem
}
