/**
 * Check Play Capacity - set modal if cannot play
 * @param requiresCapabilities
 * @param store
 * @param route
 * @returns {Promise<never>|Promise<void>}
 */
function checkCanPlayCapacity (requiresCapabilities, store, route) {
  if (store.getters['checkups/getCapacity']?.canPlay || !requiresCapabilities || route?.params?.byPass) {
    return Promise.resolve()
  } else {
    return Promise.reject({
      componentName: 'CapabilitiesError',
      props: {
        error: {
          title: 'fip.errors.configIncompatible',
          text1: 'fip.errors.cantPlay',
          text2: 'fip.errors.continueOrderBox',
          appButton: false,
          stat: 'onConfigNotCompatible'
        },
        item: route?.params?.item,
        route
      }
    })
  }
}

export default {
  checkCanPlayCapacity
}
