import _filter from 'lodash/filter'
import _get from 'lodash/get'
import flexivod from '../flexivod'
import { DateTime } from 'luxon'
import filterOptions from './filterOptions'
import CONSTANTS from '../../../api.constants'

/**
 * Filter Items
 * @param itemsToFilter
 * @param filters
 * @param userDiscount
 * @param activatedCoupon
 * @returns {*|Array}
 */
function filtersItems (itemsToFilter, filters, userDiscount, activatedCoupon, articleIds, confettiConfig) {
  if (typeof filters === 'string') {
    filters = filters.split(',')
  }
  let filtersToApply = filters ? [...filters] : []
  let items = itemsToFilter
  // the specials filters (not genres) are supposed to be always before the genres
  // they are linked by a logical AND (ex everyone AND duration)
  const maxRentPrice = parseFloat(confettiConfig.rentMaxPrice.replace(',', '.'))
  const maxBuyPrice = parseFloat(confettiConfig.buyMaxPrice.replace(',', '.'))
  const userDiscountsIds = userDiscount ? userDiscount.map(e => e.eligibility === CONSTANTS.PROMO.SATISFIED ? e.id : '') : []
  if (filters?.length > 0) {
    filters.forEach((filter) => {
      const filterIndex = filtersToApply.indexOf(filter)
      switch (filter) {
        case filterOptions.everyone.value:
          items = _filter(items, item => item.csa === 1)
          filtersToApply = filtersToApply.slice(0, filterIndex).concat(filtersToApply.slice(filterIndex + 1))
          break

        case filterOptions.rentMaxPrice.value:
          items = _filter(items, (item) => {
            let minimalRentPrice = item.price.rentMinimalCatalogPrice >= 0 && item.price.rentMinimalCatalogPrice
            let discountPrice
            if (item?.price?.discounts) {
              for (const e of item.price.discounts) {
                if (userDiscountsIds.includes(e?.discountId)) {
                  discountPrice = e.minimalRentPrice
                  break
                }
              }
            }
            minimalRentPrice = discountPrice ? Math.min(discountPrice, minimalRentPrice) : minimalRentPrice
            return minimalRentPrice !== false && minimalRentPrice !== undefined && !isNaN(minimalRentPrice) && minimalRentPrice !== null && minimalRentPrice !== '' && minimalRentPrice > 0 && minimalRentPrice <= maxRentPrice
          })
          filtersToApply = filtersToApply.slice(0, filterIndex).concat(filtersToApply.slice(filterIndex + 1))
          break

        case filterOptions.buyMaxPrice.value:
          items = _filter(items, (item) => {
            let minimalBuyPrice = item.price.sellMinimalCatalogPrice >= 0 && item.price.sellMinimalCatalogPrice
            let discountPrice
            if (item?.price?.discounts) {
              for (const e of item.price.discounts) {
                if (userDiscountsIds.includes(e?.discountId)) {
                  discountPrice = e.minimalSellPrice
                  break
                }
              }
            }
            minimalBuyPrice = discountPrice ? Math.min(discountPrice, minimalBuyPrice) : minimalBuyPrice
            return minimalBuyPrice !== false && minimalBuyPrice !== undefined && !isNaN(minimalBuyPrice) && minimalBuyPrice !== null && minimalBuyPrice !== '' && minimalBuyPrice > 0 && minimalBuyPrice <= maxBuyPrice
          })
          filtersToApply = filtersToApply.slice(0, filterIndex).concat(filtersToApply.slice(filterIndex + 1))
          break

        case filterOptions.spectatorScore.value:
          items = _filter(items, item => _get(item, 'allocineRatings.user.rating') >= 3)
          filtersToApply = filtersToApply.slice(0, filterIndex).concat(filtersToApply.slice(filterIndex + 1))
          break

        case filterOptions.pressScore.value:
          items = _filter(items, item => _get(item, 'allocineRatings.press.rating') >= 3)
          filtersToApply = filtersToApply.slice(0, filterIndex).concat(filtersToApply.slice(filterIndex + 1))
          break

        case filterOptions.duration.value:
          items = _filter(items, item => _get(item, 'duration') <= 95)
          filtersToApply = filtersToApply.slice(0, filterIndex).concat(filtersToApply.slice(filterIndex + 1))
          break

        case filterOptions.promotion.value:
          items = flexivod.filterPromo(userDiscount, activatedCoupon, items, articleIds)
          filtersToApply = filtersToApply.slice(0, filterIndex).concat(filtersToApply.slice(filterIndex + 1))
          break
        default:
          break
      }
    })
    // the genres are linked with a logical OR (ex everyone OR action OR horror, with everyone as a special filter and the 2 others as genres)
    if (filtersToApply && filtersToApply.length > 0 && filtersToApply[0] !== 'all') {
      items = _filter(items, (item) => {
        let result = false
        filtersToApply.forEach((filter) => {
          if (item.superGenres?.length) {
            item.superGenres.forEach((superGenre) => {
              if (superGenre.value === filter) {
                result = true
              }
            })
          }
        })
        return result
      })
    }
  }
  return items
}

function sortItems (itemsToSort, sort) {
  return itemsToSort.slice(0)
    .sort((a, b) => _preSortItems(a, b, sort))
    .sort((a, b) => _sortItems(a, b, sort))
}

// -----------------------------------------------------

/**
 * PreSort items if selected sort is boxOffice
 * @param a
 * @param b
 * @param sort
 * @returns {*}
 */
function _preSortItems (a, b, sort) {
  if (sort === 'boxOffice') {
    return _sortItemsFromAtoZ(a, b)
  }
}
/**
 * Sort items based on the selected option
 * @param a
 * @param b
 * @param sort
 * @returns {*}
 */
function _sortItems (a, b, sort) {
  switch (sort) {
    case 'aToZ':
      return _sortItemsFromAtoZ(a, b)
    case 'zToA':
      return _sortItemsFromZtoA(a, b)
    case 'datesDecreasing':
      return _sortItemsByDatesDecreasing(a, b)
    case 'boxOffice':
      return _sortItemsByBoxOffice(a, b)
    case 'pressRating':
      return _sortItemsByPressRating(a, b)
    case 'spectatorRating':
      return _sortItemsByUserRating(a, b)
  }
}

/**
 * Sort alphabetically
 * @param a
 * @param b
 * @returns {number}
 */
function _sortItemsFromAtoZ (a, b) {
  return _parseTitle(a.title).localeCompare(_parseTitle(b.title))
}
/**
 * Sort alphabetically reversed
 * @param a
 * @param b
 * @returns {number}
 */
function _sortItemsFromZtoA (a, b) {
  return _parseTitle(b.title).localeCompare(_parseTitle(a.title))
}

function _sortItemsByBoxOffice (a, b) {
  if (a === b) { return 0 }
  if (!a.boxOffice) { return 1 }
  if (!b.boxOffice) { return -1 }

  return (a.boxOffice < b.boxOffice) ? 1 : -1
}
/**
 * Sort Items by date with newest first
 * @param a
 * @param b
 * @returns {number}
 */
function _sortItemsByDatesDecreasing (a, b) {
  return _getItemDate(a) > _getItemDate(b) ? -1 : 1
}
/**
 * Sort Items by user rating - if none, set to 0
 * @param a
 * @param b
 * @returns {number}
 */
function _sortItemsByUserRating (a, b) {
  const userRatingA = _get(a, 'allocineRatings.user.rating', 0)
  const userRatingB = _get(b, 'allocineRatings.user.rating', 0)

  return userRatingA > userRatingB ? -1 : 1
}
/**
 * Sort Items by user rating - if none, set to 0
 * @param a
 * @param b
 * @returns {number}
 */
function _sortItemsByPressRating (a, b) {
  const pressRatingA = _get(a, 'allocineRatings.press.rating', 0)
  const pressRatingB = _get(b, 'allocineRatings.press.rating', 0)

  return pressRatingA > pressRatingB ? -1 : 1
}

// -----------------------------------------------------

/**
 * Adapt title to be sorted
 * @param title
 * @returns {string}
 */
function _parseTitle (title) {
  return title.normalize('NFD').toLowerCase()
}

/**
 * Use release date if available, production date using first day of the year otherwise
 * @param item
 * @returns {DateTime}
 */
function _getItemDate (item) {
  let itemDate = null

  if (item.releaseDate && item.releaseDate !== '') {
    itemDate = item.releaseDate
  } else if (item.productionYear && item.productionYear !== '') {
    itemDate = item.productionYear + '0101'
  }

  return itemDate ? DateTime.fromFormat(itemDate, 'yyyyMMdd') : null
}

// -----------------------------------------------------

export default {
  filtersItems,
  sortItems
}
