export default {
  title: 'VOD - ',
  newSeoTitle: ' - Orange VOD',
  yes: 'Oui',
  no: 'Non',
  vodAccessibility: 'Vidéo à la demande, Orange',
  menu: {
    home: 'Accueil',
    title: 'Vidéo à la Demande',
    searchCloseCrossLabel: 'Fermer',
    searchLabel: 'Recherche',
    myVideos: 'Mes vidéos',
    myBookmarksAlerts: 'Ma liste',
    myAccount: 'Mon compte prépayé',
    appliTvOrange: 'Application TV d\'Orange',
    vodLinksTitle: 'Mon espace VOD',
    configuration: 'Tester ma configuration',
    lecteurVod: 'Installer Mon lecteur VOD',
    needHelp: 'Besoin d\'assistance',
    accessibility: {
      open: 'Ouvrir le menu',
      close: 'Fermer le menu',
      item: '{title}'
    }
  },
  scrollTop: 'Remonter en haut de page',
  csa: {
    5: 'Déconseillé aux moins de 18 ans',
    4: 'Déconseillé aux moins de 16 ans',
    3: 'Déconseillé aux moins de 12 ans',
    2: 'Déconseillé aux moins de 10 ans'
  },
  banner_market: {
    play: 'Lire'

  },
  meaCategorie: {
    toutAfficher: 'Tout afficher',
    toutAfficherSmall: 'Tout'
  },
  back: 'Retour',
  backTo: 'Retour vers {target}',
  item: {
    price: 'Dès {price} €',
    offert: 'Offert',
    rating: {
      srOnly: 'Note presse {rating}'
    }
  }
}
