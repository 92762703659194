export default {
  title: 'Mon compte prépayé',
  titleSrOnly: 'Mon compte prépayé, Vidéo à la demande, Orange',
  banner_market: {
    titre: 'Rechargez votre compte prépayé TV',
    text: 'Profitez de bonus offerts en rechargeant votre compte prépayé TV pour louer ou acheter des vidéos sur tous les écrans de la vidéo à la demande d\'Orange : ordinateur, TV et tablette / mobile*',
    footer: 'Service réservé aux abonnés Internet avec option TV d\'Orange.'
  },
  banner_account: {
    advantages: {
      title: 'Les avantages'
    },
    simple: {
      title: 'Simple',
      text: 'Un ticket prépayé à garder ou à offrir et toujours disponible pour vos coups de cœur vidéo.'
    },
    confidential: {
      title: 'Confidentiel',
      text: 'Aucune commande de vidéos ne figure sur votre relevé bancaire ou votre facture Orange Internet.'
    },
    accessibility: {
      title: 'Maîtrisé',
      text: "Le compte prépayé permet à vous et vos enfants de louer ou d'acheter des VOD en maîtrisant votre budget."
    },
    howUse: {
      title: 'Comment ça marche ?',
      text: 'Les tickets vidéo permettent de recharger le compte prépayé qui peut être utilisé pour louer ou acheter des VOD sur la TV d\'Orange sur tous vos écrans : TV, ordinateur, tablette, smartphone, clé TV.',
      text2: 'Réservé aux abonnés Internet TV téléphone Orange.',
      link: 'Pour en savoir plus,',
      clickHere: 'cliquez ici.'
    },
    whereBuy: {
      title: 'Où acheter le ticket vidéo ?',
      text: 'Sur Orange.fr, rubrique Vidéo à la Demande > mon compte prépayé.',
      text2: 'Dans les boutiques Orange.'
    },
    termsOfService: '> Conditions générales : compte prépayé et ticket VOD'
  },
  banner_balance: {
    text_part1: 'Votre compte prépayé est crédité de : ',
    text_part2: '{solde} €',
    text_part3: ', dont {bonus} € offert(s), à utiliser avant le {limitDate}',
    titleTicket: 'Activer votre ticket vidéo sur votre compte prépayé',
    textTicket: 'Le montant du ticket vidéo sera crédité sur votre compte prépayé dont l\'identifiant est ',
    learnMoreTicket: 'En savoir plus',
    enterTicket: 'Saisissez le code de votre ticket vidéo',
    validateTicket: 'Créditer',
    purchaseTitle: 'Acheter un ticket vidéo',
    pusrchaseBuy: 'Acheter',
    purchase10: '10€',
    purchase20: '20€ + 2€ offerts',
    purchase30: '30€ + 3€ offerts',
    purchase50: '50€ + 6€ offerts'
  }
}
