function onClickItem (category, item, page, userQualification) {
  const categoryInfo = category ? `${category.title}-${category.id}-` : ''
  const videoType = item.videoType ? `/${item.videoType}` : ''

  return {
    track_page: page,
    track_zone: 'herozone',
    track_nom: 'element_mea',
    track_type: _isInTopCategory(item.parentCategories) && userQualification ? 'clic-top' : 'clic',
    track_cible: `${categoryInfo}${item.type}${videoType}-${item.id}-${item.title}`,
    track_detail: 'clic-element_mea-herozone-' + page
  }
}
// ------------------------------------------
function _isInTopCategory (items) {
  return !!items?.find(cat => cat.isTop)
}
// ----------------------------------------

export default {
  onClickItem
}
