export default {
  rent: {
    disponibility: 'Visionnage pendant 48h depuis Mes vidéos sur tous vos écrans.'
  },
  sell: {
    disponibility1: 'Visionnage à volonté depuis Mes vidéos sur tous vos écrans.',
    disponibility2: 'Copie numérique PC/MAC téléchargeable depuis Mes vidéos sur orange.fr.'
  },
  onlyDecodeur: 'La qualité HD est disponible uniquement sur le décodeur TV d\'Orange.',
  allScreens: 'Disponible en qualité HD sur tous vos écrans compatibles et selon le débit de votre connexion (y compris sur votre TV).',
  prepaidCredit: 'Solde du compte prépayé',
  prepaidAmount: 'Le montant de {prepaidCharge} € sera débité sur votre compte prépayé.',
  postpaidAmount: 'Le montant de {postpaidCharge} € sera prélevé sur votre facture.',
  mixteAmount: 'Le montant de {prepaidCharge} € sera débité de votre compte prépayé et {postpaidCharge} € sera prélevé sur votre facture.',
  retract: 'J\'accepte de bénéficier du service immédiatement et reconnais que je perds le bénéfice du droit de rétractation.',
  cancel: 'Annuler',
  validate: 'Commander et payer',
  close: 'Fermer',
  continue: 'Continuer',
  cgsLink: 'Voir les conditions de la TV d\'Orange',
  error: {
    2366: {
      title: 'Nombre maximum de terminaux atteint',
      text: 'Vous avez atteint le nombre maximal d’équipements enregistrés. Vous ne pourrez pas regarder votre vidéo sur ce navigateur internet. Continuez votre commande pour la regarder sur votre décodeur TV d\'Orange ou sur un autre écran enregistré.'
    },
    2367: {
      title: 'Nombre maximum de terminaux atteint',
      text: 'Vous avez atteint le nombre maximal d’équipements enregistrés. Vous ne pourrez pas regarder votre vidéo sur ce navigateur internet. Continuez votre commande pour la regarder sur votre décodeur TV d\'Orange ou sur un autre écran enregistré.'
    },
    2307: {
      title: 'Pour acheter ou louer des vidéos',
      text: 'Le compte avec lequel vous êtes identifié ne vous permet pas d’acheter ou de louer de vidéos à la demande. <br>Seul le titulaire du contrat ou la personne qui vous a partagé son accès à la TV d’Orange peut réaliser l’achat ou la location d’une vidéo. Vous pourrez ensuite la retrouver dans « Mes vidéos » sur tous vos écrans.'
    },
    2346: {
      title: 'Vidéo déjà commandée',
      text: 'Vous disposez déjà de cette vidéo. Vous la retrouverez dans Mes Vidéos. Votre paiement n\'a pas été effectué.'
    },
    2365: {
      title: 'Erreur',
      text: 'Cette promotion n’est plus valide.'
    },
    2368: {
      title: 'Erreur',
      text: 'Ce code promo n’est plus valide.'
    },
    2363: {
      title: 'Erreur',
      text: 'Ce code promo n’est pas utilisable.'
    },
    2308: {
      title: 'Accès réservé',
      text: 'La TV d\'Orange sur tous les écrans est disponible depuis l\'Union Européenne. </br>La liste des pays ainsi que les restrictions sont disponibles sur <a href="assistance.orange.fr">assistance.orange.fr</a>'
    },
    retractNotChecked: 'Veuillez donner votre accord sur le renoncement à votre droit de rétractation',
    default: {
      title: 'Une erreur est survenue ({status}/{errorCode})'
    }
  }
}
