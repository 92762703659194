const middleware = {}

middleware['checkups/capacities/capacities'] = require('../src/middleware/checkups/capacities/capacities.js')
middleware['checkups/capacities/capacities'] = middleware['checkups/capacities/capacities'].default || middleware['checkups/capacities/capacities']

middleware['checkups/deviceAvailability/config'] = require('../src/middleware/checkups/deviceAvailability/config.js')
middleware['checkups/deviceAvailability/config'] = middleware['checkups/deviceAvailability/config'].default || middleware['checkups/deviceAvailability/config']

middleware['checkups/deviceAvailability/deviceAvailability'] = require('../src/middleware/checkups/deviceAvailability/deviceAvailability.js')
middleware['checkups/deviceAvailability/deviceAvailability'] = middleware['checkups/deviceAvailability/deviceAvailability'].default || middleware['checkups/deviceAvailability/deviceAvailability']

middleware['checkups/index'] = require('../src/middleware/checkups/index.js')
middleware['checkups/index'] = middleware['checkups/index'].default || middleware['checkups/index']

middleware['checkups/user/user'] = require('../src/middleware/checkups/user/user.js')
middleware['checkups/user/user'] = middleware['checkups/user/user'].default || middleware['checkups/user/user']

middleware['route'] = require('../src/middleware/route.js')
middleware['route'] = middleware['route'].default || middleware['route']

export default middleware
