import formatCategoryUtils from '../shared/formatCategory.utils'
import filterOptions from '../../components/shared/sortAndFilter/filterOptions'
import sortOptions from '../../components/shared/sortAndFilter/sortOptions'
import _get from 'lodash/get'

export default {
  setSelectedCategory (state, category) {
    const defaultFilters = formatCategoryUtils.getFormattedOptions({
      options: [
        filterOptions.rentMaxPrice,
        filterOptions.buyMaxPrice,
        filterOptions.promotion,
        filterOptions.pressScore,
        filterOptions.spectatorScore,
        filterOptions.everyone,
        filterOptions.duration
      ],
      translate: _get(this.app, 'i18n')
    })

    const defaultSortingCriterias = formatCategoryUtils.getFormattedOptions({
      options: [
        sortOptions.aToZ,
        sortOptions.zToA,
        sortOptions.datesDecreasing,
        sortOptions.pressRating,
        sortOptions.spectatorRating,
        sortOptions.boxOffice
      ],
      translate: _get(this.app, 'i18n')
    })

    state.selectedCategory = {
      isTop: category.id === this.$config.confetti.public.topCategory,
      ...formatCategoryUtils.formatCategory(category, defaultFilters, defaultSortingCriterias)
    }
  },
  /**
   * set selected categories
   * @param {*} state
   * @param {*} { categories, router, currenPath }
   */
  setSelectedCategories (state, { categories, router, route }) {
    if (router && route) {
      router.replace({
        query: Object.assign({}, route.query, { filtres: categories?.join(',') })
      })
    }
    state.selectedCategories = categories
  },
  /**
   * set available filters options
   * @param {*} state
   * @param {*} { categories, router, currenPath }
   */
  setFiltersOptionsAvailable (state, filterOptions) {
    state.availableFiltersOptions = filterOptions
  }
}
