export default {
  explicitTests: {
    title: 'Résultat du test de configuration',
    ba_title: 'Lecture des bandes annonces',
    ba_error: 'Votre navigateur ne supporte pas les extensions vidéo.',
    drm_title: 'Lecture des vidéos',
    drm_error: 'Votre navigateur ne supporte pas les contenus protégés.',
    mlv_title: 'Téléchargement des vidéos',
    mlv_summary:
      'Mon Lecteur VOD est utile uniquement si vous souhaitez télécharger des vidéos sur votre ordinateur.',
    cfg_OK: 'Votre configuration permet de regarder les vidéos à la demande.',
    cfg_KO:
      'Votre navigateur ne permet pas de regarder les vidéos à la demande en streaming.',
    reload_tests: 'Relancer le test',
    cfg_compatible: 'Configurations compatibles'
  },
  authentication: {
    title: "Demande d'identification",
    text: 'Pour continuer vous devez vous identifier. Merci de patienter quelques secondes.',
    redirectButton: 'Rediriger maintenant'
  },
  OutOfEu: {
    title: 'Accès réservé',
    text1:
      'La TV d’Orange sur tous les écrans est disponible depuis l’Union Européenne. La liste des pays ainsi que les restrictions sont disponibles sur',
    text2: 'assistance.orange.fr'
  },
  OutOfEuWithICloudPrivateRelayBlocked: {
    title: 'Accès réservé',
    text: 'La disponibilité des services de la TV d’Orange peut être limitée en fonction de votre position géographique.',
    listItem1: 'Vous pouvez profiter des programmes de la TV d’Orange depuis les pays de l’Union Européenne dont la liste et les restrictions sont disponibles sur',
    listItem2: 'Si vous utilisez un VPN pour accéder à la TV d’Orange, merci de le désactiver pour accéder à l’ensemble des services.',
    listItem3: 'Si vous utilisez un appareil sous iOS à partir de la version 15, iPad OS à partir de la version 15 ou MacOS à partir de la version Monterey et que vous êtes abonné au service iCloud+ : pour pouvoir accéder aux contenus veuillez désactiver la fonction Relais Privé. Pour plus d’information merci de consulter la FAQ à ce sujet sur',
    supportLinkText: 'assistance.orange.fr'
  },
  bandwidthModal: {
    title: 'Votre débit semble limité',
    text:
      'Vous ne pourrez peut-être pas lire votre vidéo dans de bonnes conditions'
  },
  implicitTests: {
    acknowledgeButton: 'OK',
    dsa: 'Ne plus me prévenir',
    button_mobile_telecharger_application: "Télécharger l'application",
    ANDROID_KO: {
      title: 'Configuration non compatible',
      content:
        "Votre navigateur ne permet pas la lecture des vidéos. </br> Sur Android, assurez-vous d'utiliser Chrome pour bénéficier pleinement du service.",
      link: {
        label: 'Voir les configurations compatibles',
        to: 'http://r.orange.fr/r/Owebtv_vod_config'
      }
    },
    BA_KO: {
      title: 'Configuration non compatible',
      content:
        'Votre navigateur ne vous permet pas de lire les bandes-annonces, ni vos vidéos louées ou achetées.',
      link: {
        label: 'Voir les configurations compatibles',
        to: 'http://r.orange.fr/r/Owebtv_vod_config'
      }
    },
    DRM_KO: {
      title: 'Configuration non compatible',
      content:
        'Vous pourrez lire les bandes-annonces, mais vous ne pourrez pas lire vos vidéos louées ou achetées dans votre navigateur.',
      link: {
        label: 'Voir les configurations compatibles',
        to: 'http://r.orange.fr/r/Owebtv_vod_config'
      }
    },
    INCOMPATIBLE_PC: {
      title: 'Avertissement',
      content:
        'Ce contenu n\'est pas disponible sur votre écran. Cliquez sur continuer pour le visualiser sur votre décodeur.',
      button: 'Continuer'
    }
  },
  blocage: {
    title: 'Information',
    content: {
      line2: 'Chère cliente, cher client,',
      line3:
        "Orange vous informe que son service de Vidéo à la Demande sur ordinateur est réservé aux clients Orange disposant d'une offre Internet avec",
      line3_bold: "TV d'Orange.",
      line4: 'Si vous souhaitez continuer à profiter de nos services, nous vous invitons à consulter notre',
      line4_link: " catalogue d'offres en ligne",
      line4_end: ' ou en boutique Orange.',
      line5: 'Orange vous remercie pour votre confiance et votre fidélité.',
      line6: 'Le service clients Orange'
    },
    downloadLVO: 'Installer le Lecteur VOD Orange'
  },
  lightBlocage: {
    title: 'Avertissement',
    text: 'Cette fonction est réservée aux clients Orange Internet avec Option TV',
    close: 'Fermer'
  },
  prepaidAccount: {
    close_modal: 'Fermer',
    title_2359: 'Code ticket déjà utilisé',
    text_2359:
      "Votre compte prépayé n'a pas pu être crédité (code ticket déjà utilisé)",
    title_2360: 'Code ticket expiré',
    text_2360:
      "Votre compte prépayé n'a pas pu être crédité (code ticket expiré)",
    title_2361: 'Code ticket invalide',
    text_2361:
      "Votre compte prépayé n'a pas pu être crédité (code ticket invalide)",
    title_2338: 'Seuil de rechargement atteint.',
    text_2338:
      "Vous ne pouvez plus recharger votre compte prépayé pour l'instant.Merci de réessayer plus tard.",
    title_2363: 'Code ticket invalide',
    text_2363:
      'Trop de codes erronés ont été soumis depuis votre ordinateur. Par mesure de sécurité, toute soumission de nouveau code sera temporairement impossible. Le paiement sur facture Orange reste néanmoins disponible.',
    title_technical: 'Erreur technique',
    text_technical:
      'Merci d\'essayer à nouveau. Si le problème persiste, veuillez contacter le support via le lien "nous contacter" en bas de page',
    title_noSelection: 'Pour continuer',
    text_noSelection: 'Vous devez sélectionner un montant pour l’achat de votre ticket vidéo.',
    title_success: 'Compte prépayé rechargé',
    text_success: ' Votre compte prépayé a été crédité de {amount} €'
  },
  tvInitError: {
    retry: 'Réessayer',
    close: 'Fermer',
    title: 'Désolé, une erreur technique est survenue.',
    content: 'Vous pouvez réessayer dans quelques instants.'
  },
  suspended: {
    SUSPENDED: {
      title: 'Votre offre TV est suspendue',
      text: 'Vous ne disposez plus des droits nécessaires pour accéder à ce service.'
    },
    SUSPENDED_GUEST: {
      title: 'L’offre TV qui vous a été partagée est suspendue',
      text: 'Vous ne disposez plus des droits nécessaires pour accéder à ce service.'
    },
    HOMECHECKSTATUS_INVALID: {
      title: 'Votre partage de la TV d’Orange est temporairement suspendu',
      text: 'Ce service est restreint aux membres du foyer. Pour le vérifier, il est nécessaire que vous l’utilisiez régulièrement en étant connecté à la Livebox de votre domicile en ethernet ou en wifi.\n<br><b>Pour réactiver ce partage dès maintenant, connectez-vous simplement au réseau de votre Livebox et rafraichissez cette page.</b><br>\nSi vous disposez d\'autres offres TV, vous pouvez choisir d\'utiliser l\'une d\'elles en attendant de pouvoir réactiver votre partage.'
    },
    changeAccount: 'Changer de compte',
    allOffers: 'Voir vos offres TV',
    close: 'Fermer'
  }
}
