import _get from 'lodash/get'
import urlJoin from 'url-join'

export default {
  /**
   * Retrieve catalogs
   * @param onlyCategory
   * @returns {Promise<T | never>}
   */
  getCatalogs (onlyCategory = false) {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.catalogs'),
      _get(this.$config, 'confetti.public.api.tvm.universeId'))
    const method = 'api.catalogs.getAll()'
    const options = {
      params: {
        view: onlyCategory ? 'tree' : 'preview'
      },
      cache: {
        exclude: { query: false }
      }
    }

    return this.$http.tvm.notAuth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  },

  /**
   * Retrieve HeroZone
   * @returns {Promise<T | never>}
   */
  getHerozone () {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.catalogs'),
      _get(this.$config, 'confetti.public.api.tvm.universeId'),
      _get(this.$config, 'confetti.public.api.tvm.paths.highlights'))
    const method = 'api.catalogs.getHerozone()'
    const options = {
      cache: {
        exclude: { query: false }
      }
    }

    return this.$http.tvm.notAuth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  }
}
