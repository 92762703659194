import _get from 'lodash/get'
import urlJoin from 'url-join'
import API from '../../../../api.constants'

export default {
  /**
   * Get Video
   * @param videoId
   * @returns {Promise<T | never>}
   */
  getVideo (videoId) {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.videos'),
      videoId)
    const method = 'api.videos.getVideo()'
    const options = {
      cache: {
        exclude: { query: false }
      }
    }

    return this.$http.tvm.notAuth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  },

  /**
   * Get Anonym Recommendations
   * @param videoId
   * @returns {Promise<any | never>}
   */
  getAnonymRecommendations (videoId) {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.videos'),
      videoId,
      _get(this.$config, 'confetti.public.api.tvm.paths.anonymRecommendations'))
    const method = 'api.videos.getAnonymRecommendations()'
    const options = {
      params: {
        limit: _get(this.$config, 'confetti.public.api.tvm.params.recommendationsLimit', API.RECOMMENDATIONS.DEFAULT_LIMIT)
      },
      cache: {
        exclude: { query: false }
      }
    }

    return this.$http.tvm.notAuth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, true))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method, bypass: true }))
  }
}
