import { mapGetters } from 'vuex'
import API from '../../../api.constants'
const PrepaidAccount = () => import('./prepaidAccount/PrepaidAccount')
const ErrorModal = () => import('./errorModal/ErrorModal')
const ErrorTvInitModal = () => import('./errorTvInitModal/ErrorTvInitModal')
const ImplicitTests = () => import('./implicitTests/ImplicitTests')
const Blocage = () => import('./blocage/Blocage')
const LightBlocage = () => import('./blocage/LightBlocage')
const DownloadModal = () => import('./downloadModal/DownloadModal')
const ExplicitTests = () => import('./explicitTests/ExplicitTests')
const Authentication = () => import('./authentication/Authentication')
const OutOfEu = () => import('./outOfEu/OutOfEu')
const Cgs = () => import('./cgs/Cgs')
const IncompatiblePc = () => import('./incompatiblePc/IncompatiblePc')
const Suspended = () => import('./suspended/Suspended')
const CapabilitiesError = () => import('./capabilitiesError/CapabilitiesError')

export default {
  computed: {
    ...mapGetters({
      lastError: 'errors/getLastError',
      showModal: 'config/getShowModal',
      firstModalToOpen: 'config/getFirstModalToOpen'
    })
  },

  data () {
    return {
      activeModal: null,
      activeModalProps: null,
      lastErrorWatcher: null,
      firstModalToOpenWatcher: null
    }
  },

  components: {
    PrepaidAccount,
    ErrorTvInitModal,
    ErrorModal,
    ImplicitTests,
    Blocage,
    DownloadModal,
    ExplicitTests,
    Authentication,
    OutOfEu,
    Cgs,
    IncompatiblePc,
    Suspended,
    LightBlocage,
    CapabilitiesError
  },

  mounted () {
    this.$root.$on('open-prepaid-account-modal', props => this.openModal('PrepaidAccount', props))
    this.$root.$on('download-app-modal/open', () => this.openModal('DownloadModal'))
    this.$root.$on('open-explicit-tests', () => this.openModal('ExplicitTests'))
    this.$root.$on('open-implicit-tests', () => this.openModal('ImplicitTests'))
    this.$root.$on('open-authentication-modal', () => this.openModal('Authentication'))
    this.$root.$on('open-out-of-eu-modal', () => this.openModal('OutOfEu'))
    this.$root.$on('open-cgs-modal', () => this.openModal('Cgs'))
    this.$root.$on('open-incompatible-pc-modal', props => this.openModal('IncompatiblePc', props))
    this.$root.$on('open-error-token-modal', () => this.openModal('ErrorTvInitModal'))
    this.$root.$on('open-suspended-modal', props => this.openModal('Suspended', props))
    this.$root.$on('open-blocage-modal', () => this.openModal('Blocage'))
    this.$root.$on('open-light-blocage-modal', () => this.openModal('LightBlocage'))
    this.$root.$on('open-capabilities-error-modal', () => this.openModal('CapabilitiesError'))

    this.lastErrorWatcher = this.$store.watch(() => this.lastError, this.onError)

    if (this.firstModalToOpen) {
      this.onFirstModalToOpen()
    }

    this.firstModalToOpenWatcher = this.$store.watch(() => this.firstModalToOpen, this.onFirstModalToOpen)
  },

  beforeDestroy () {
    this.$root.$off()

    this.lastErrorWatcher()
    this.firstModalToOpenWatcher()
  },
  methods: {
    /**
     * Open modal - load dynamically
     * @param componentName
     * @param props
     */
    openModal (componentName, props = null) {
      this.$store.commit('config/addModalToOpen', { props, componentName })
    },

    /**
     * Closing modal (will set v-if to false)
     */
    closeModal () {
      this.$store.commit('config/closeModal')
    },

    /**
     * Called when first modal to open changes and open modal if any
     */
    onFirstModalToOpen () {
      if (!!this.firstModalToOpen && !this.showModal) {
        this.activeModalProps = this.firstModalToOpen.props
        this.activeModal = this.firstModalToOpen.componentName
        this.$store.commit('config/showModal')
      }
    },

    /**
     * Open modal when error occured / throw error page if 404
     */
    onError () {
      const errorCode =
        Object.keys(API.ERRORS_CODES).find(code => API.ERRORS_CODES[code].includes(this.lastError.errorCode)) ||
        API.CONTENT_ERRORS[this.lastError.errorCode] ||
        'TVM_GENERIC'
      switch (errorCode) {
        case 'TVM_NO_CONTENT':
          this.$nuxt.error({ statusCode: 404 })
          break

        case 'TVM_NOT_AUTHENTIFIED':
          this.openModal('Authentication')
          break

        default:
          if (this.lastError.type === 'API' || this.lastError.type === 'CONTENT') {
            this.openModal('ErrorModal', { code: errorCode, error: this.lastError })
          }
      }
    }
  }
}
