import _get from 'lodash/get'
import logger from '@wptv/logger'
import axios from 'axios'

export default {
  /**
     * Get Certificate
     * @param isSafari
     * @returns {Promise<T | never>}
     */
  getCertificate (isSafari) {
    const certificateType = isSafari ? 'fairplayCertificateUrl' : 'widevineCertificateUrl'
    const path = _get(this.$config, `confetti.public.player.${certificateType}`)

    return axios.get(path, { responseType: 'arraybuffer' })
      .then(response => Promise.resolve(response.data))
      .catch((error) => {
        const message = _get(error, 'message', 'no message')
        const err = {
          error: {
            data: _get(error, 'response.data'),
            path
          },
          message
        }
        logger.error('Error::api.certificat.getCertificate():', message)
        logger.debug(error)
        return Promise.reject(err)
      })
  }
}
