import getDecoratedTitle from '../../plugins/filters/getDecoratedTitle/getDecoratedTitle.filter'

/**
 * Returns the category with its superGenres formatted
 * @param {Object} category
 * @param defaultFilters
 * @param sortOptions
 */
function formatCategory (category, defaultFilters = [], sortOptions = []) {
  const { articles = [], superGenres = [], ...categoryData } = category
  return {
    superGenres: _formatSuperGenres(superGenres.length > 1 ? superGenres : [], defaultFilters),
    articles: _formatArticles(articles),
    sortOptions,
    ...categoryData
  }
}

/**
 * format the options with wordings
 * @param {*} options
 * @param translate
 */
function getFormattedOptions ({ options, translate }) {
  return options.map((option) => {
    const { label, ...optionData } = option
    return {
      ...optionData,
      label: translate ? translate.t(label) : label
    }
  })
}

// -----------------------------------------------------

/**
 * get category superGenres and map it with a label and a value
 * the value is the formatted label without special characters
 * @param {Array} superGenres
 * @param defaultFilters
 */
function _formatSuperGenres (superGenres = [], defaultFilters = []) {
  return defaultFilters.concat(superGenres.map((superGenre) => {
    return {
      label: superGenre,
      value: getDecoratedTitle(superGenre)
    }
  }))
}

/**
 * get superGenres of each item of a category and map it with a label and a value
 * the value is the formatted label without special characters
 * @param {Object} articles
 */
function _formatArticles (articles) {
  const { items = [], ...articleData } = articles
  return {
    items: items.map(item => ({
      ...item,
      superGenres: _formatSuperGenres(item.superGenres)
    })),
    ...articleData
  }
}

export default {
  formatCategory,
  getFormattedOptions
}
