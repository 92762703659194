export default {
  rentMaxPrice: {
    value: 'rentMaxPrice'
  },
  buyMaxPrice: {
    value: 'buyMaxPrice'
  },
  pressScore: {
    value: 'pressScore'
  },
  spectatorScore: {
    value: 'spectatorScore'
  },
  promotion: {
    value: 'promotion'
  },
  everyone: {
    value: 'everyone'
  },
  duration: {
    value: 'duration'
  }
}
