export default {
  title: 'Fiche Saison',
  episodes: '1 épisode | {numbers} épisodes',
  episode: 'Episode',
  offert: 'Offert',
  dureemin: 'min',
  resumeToggle: 'Afficher les résumés',
  tri: 'Tri',
  episodesSort: 'Trier les épisodes',
  season: 'Saison',
  lettreE: ' E'
}
