import Vue from 'vue'

// export pour tester la méthode de manière unitaire
export function downloadMLV (binding) {
  if (binding.value.osName === 'Mac OS') {
    window.location.assign(binding.value.link.baseUrl + binding.value.link.name + '.dmg')
  } else if (binding.value.osName === 'Windows') {
    window.location.assign(binding.value.link.baseUrl + binding.value.link.name + '.exe')
  }
}
// On lance le MLV s'il est installé, sinon on le télécharge
function launchMLV (binding) {
  import('./fallBackCustomScheme').then((module) => {
    const FallbackCustomScheme = module.default

    const fsc = new FallbackCustomScheme({
      urlScheme: binding.value.link.schemeUrl,
      onFallback: downloadMLV.bind(null, binding)
    })

    fsc.launch()
  })
}
// On lance le téléchargement d'une vidéo si le player est installé,
// sinon on exécute ce qu'il y'a dans onFallback
function downloadMovie (binding) {
  import('./fallBackCustomScheme').then((module) => {
    const FallbackCustomScheme = module.default

    const fsc = new FallbackCustomScheme({
      urlScheme: binding.value.scheme + 'download:' + binding.value.id,
      onFallback: binding.value.fallback
    })

    fsc.launch()
  })
}

let launch
let download

function setActions (el, binding) {
  // Pour le téléchargement mlv
  if (binding.arg === 'download') {
    el.removeEventListener('click', launch)
    launch = () => launchMLV(binding)
    el.addEventListener('click', launch)
  }
  // Pour le téléchargement mlv sans check installation
  if (binding.arg === 'directDownload') {
    el.removeEventListener('click', launch)
    launch = () => downloadMLV(binding)
    el.addEventListener('click', launch)
  }
  // pour le téléchargement d'une vidéo
  if (binding.arg === 'movie') {
    el.removeEventListener('click', download)
    download = () => downloadMovie(binding)
    el.addEventListener('click', download)
  }
}

// ---------------------------------------------

Vue.directive('mlv', {
  values: {
    binding: null
  },
  update: setActions,
  bind: setActions
})
