import API from '../../api.constants'
import _get from 'lodash/get'

export default {
  /**
   * Fetch current user
   * @param commit
   * @param dispatch
   * @returns {Q.Promise<unknown>}
   */
  getUser ({ commit, dispatch }) {
    return this.$api.tvm.users.getUser()
      .then(user => commit('setUser', user))
      .catch((err) => {
        dispatch('errors/setAxiosError', { error: err, bypass: true }, { root: true })
        return commit('setUser', {})
      })
  },
  /**
   * Fetch my videos
   * @param commit
   * @param dispatch
   * @returns {Promise<T | never>}
   */
  getMyVideos ({ commit, dispatch }) {
    return this.$api.tvm.users.getMyVideos()
      .then((response) => {
        if (response.error) {
          dispatch('errors/setAxiosError', { error: response }, { root: true })
          return commit('setMyVideos', {})
        }
        return commit('setMyVideos', response)
      })
  },
  /**
   * Fetch my videos category
   * @param commit
   * @param dispatch
   * @param categoryId
   * @returns {Promise<T | never>}
   */
  getMyVideosCategory ({ commit, dispatch }, { categoryId }) {
    return this.$api.tvm.users.getMyVideosCategory(categoryId)
      .then((response) => {
        if (response.error) {
          dispatch('errors/setAxiosError', { error: response }, { root: true })
          commit('setMyVideosCategory', {})
          return Promise.reject()
        }
        return commit('setMyVideosCategory', response)
      })
  },
  /**
   * Get user discounts
   * @param commit
   * @param dispatch
   * @returns {*}
   */
  getUserDiscounts ({ commit, dispatch }) {
    return this.$api.tvm.users.getUserDiscounts()
      .then((response) => {
        if (response.error) {
          dispatch('errors/setAxiosError', { error: response }, { root: true })
          return commit('setUserDiscounts', [])
        }
        return commit('setUserDiscounts', response)
      })
  },
  /**
   * Fetch coupon discount
   * @param commit
   * @param dispatch
   * @param coupon
   * @returns {Promise<T|never>}
   */
  getCouponDiscount ({ commit, dispatch }, { coupon }) {
    return this.$api.tvm.users.getCouponDiscount(coupon)
      .then(res => commit('setActivatedCoupon', res.discountId))
      .catch((responseError) => {
        dispatch('errors/setAxiosError', { error: { ...responseError }, bypass: true }, { root: true })
        return Promise.reject(responseError)
      })
  },
  /**
   * Get prepaid account
   * @param commit
   * @param dispatch
   * @returns {Promise<T | never>}
   */
  getPrepaidAccount ({ commit, dispatch }) {
    return this.$api.tvm.users.getPrepaidAccount()
      .then(prepaidAccount => commit('setPrepaidAccount', prepaidAccount))
      .catch((responseError) => {
        dispatch('errors/setAxiosError', { error: { ...responseError } }, { root: true })
        return Promise.reject()
      })
  },
  /**
   * update prepaid account
   * @param commit
   * @param ticketCode
   * @returns {Promise<T|never>}
   */
  putPrepaidAccount ({ commit }, { ticketCode }) {
    return this.$api.tvm.users.putPrepaidAccount(ticketCode)
  },
  /**
   * Post error logs
   * @param commit
   * @param error
   * @returns {Promise<T|never>}
   */
  postErrorLogs ({ commit }, { error }) {
    return this.$api.tvm.users.postErrorLogs(error)
  },
  /**
   * Fetch payment infos
   * @param commit
   * @param dispatch
   * @param price
   * @returns {Promise<T | never>}
   */
  getPaymentInfos ({ commit, dispatch }, price) {
    return this.$api.tvm.users.getPaymentInfos(price)
      .catch((responseError) => {
        if (API.PAYMENT_INFO_ERRORS.includes(_get(responseError, 'error.data.code'))) {
          return Promise.reject(responseError)
        } else {
          dispatch('errors/setAxiosError', { error: { ...responseError } }, { root: true })
          return Promise.reject()
        }
      })
  },
  /**
   * Create an order
   * @param rootState
   * @param commit
   * @param dispatch
   * @param params
   * @returns {Promise<T>}
   */
  createOrder ({ rootState, commit, dispatch }, params) {
    const videoId = _get(rootState, 'videos.video.id', 'no video id')
    const seasonId = _get(rootState, 'seasons.season.id', 'no season id')
    const packageId = _get(rootState, 'packages.package.id', 'no package id')

    return this.$api.tvm.users.createOrder(params)
      .catch((responseError) => {
        const codeReturned = _get(responseError, 'error.data.code')
        if (API.PAYMENT_ERRORS.includes(codeReturned)) {
          return Promise.reject(responseError)
        } else {
          dispatch('errors/setAxiosError', { error: { ...responseError, videoId, packageId, seasonId } }, { root: true })
          return Promise.reject()
        }
      })
  },
  /**
   * Reset current coupon
   * @param commit
   */
  resetActivatedCoupon ({ commit }) {
    commit('resetActivatedCoupon', {})
  },
  /**
   * Fetch my articles
   * @param commit
   * @param dispatch
   * @returns {Promise<T | never>}
   */
  fetchMyArticles ({ commit, dispatch }) {
    return this.$api.tvm.users.fetchMyArticles()
      .then((response) => {
        if (response.error) {
          dispatch('errors/setAxiosError', { error: response }, { root: true })
          commit('setMyArticles', [])
        } else {
          commit('setMyArticles', response)
        }
      })
  },
  fetchAllPlayHistory ({ commit, dispatch }) {
    return this.$api.gateway.playHistory.getAllPlayHistory()
      .then((response) => {
        if (response.error) {
          dispatch('errors/setAxiosError', { error: response }, { root: true })
        } else {
          commit('setPlayHistoryMap', response)
        }
      })
  },
  fetchSinglePlayHistory ({ commit, dispatch }, { itemId }) {
    return this.$api.gateway.playHistory.getSinglePlayHistory(itemId)
      .then((response) => {
        if (response.error) {
          dispatch('errors/setAxiosError', { error: response, bypass: true }, { root: true })
        } else {
          commit('updateSinglePlayHistory', response)
        }
      })
  },
  getGeneration ({ commit, dispatch }, { householdId }) {
    return this.$api.maxymiser.generations.getUserGeneration(householdId)
      .then((response) => {
        if (response.error) {
          dispatch('errors/setAxiosError', { error: response }, { root: true })
        } else {
          commit('setGeneration', response.generations)
        }
      })
  }
}
