export default {
  getCornerById ({ commit, dispatch }, { cornerId }) {
    return this.$api.tvm.corners.getCornerById(cornerId)
      .then((response) => {
        if (response.error) {
          dispatch('errors/setAxiosError', { error: response, bypass: true }, { root: true })
        } else {
          return commit('setCachedCorner', { corner: response, config: this.$config })
        }
      })
  }
}
