function onClickDeleteAlertButton (item) {
  return {
    page: 'fip',
    zone: 'banniere',
    area: 'supp-alerte',
    type: 'clic',
    cible: _getTrackCible(item)
  }
}

function onClickActiveAlertButton (item) {
  return {
    page: 'fip',
    zone: 'banniere',
    area: 'activ-alerte',
    type: 'clic',
    cible: _getTrackCible(item)
  }
}

function onDeactivateDiscount (discount) {
  return {
    page: 'fip',
    zone: 'coupon',
    area: 'desactiver',
    cible: discount.id,
    type: 'clic'
  }
}

function onUserNotEligible (item) {
  return {
    page: 'fip',
    zone: 'user_not_eligible',
    area: 'popin',
    cible: _getTrackCible(item),
    type: 'affichage'
  }
}

function onConfigNotCompatible (item) {
  return {
    page: 'fip',
    zone: 'config_not_compatible',
    area: 'popin',
    cible: _getTrackCible(item),
    type: 'affichage'
  }
}

function onPcIncompatible (item) {
  return {
    page: 'fip',
    zone: 'PC_incompatible',
    area: 'popin',
    cible: _getTrackCible(item),
    type: 'affichage'
  }
}

function onHlsfpNotAvailable (item, platform) {
  return {
    page: 'fip',
    zone: `${platform}_hlsfp_non_dispo`,
    area: 'popin',
    cible: _getTrackCible(item),
    type: 'affichage'
  }
}

function onClickBackButton (previousRoute) {
  return {
    page: 'fip',
    zone: 'banniere',
    area: 'retour',
    cible: (previousRoute && previousRoute.path) ? previousRoute.path : '/',
    type: 'clic'
  }
}

function onValidateCoupon (code) {
  return {
    page: 'fip',
    zone: 'coupon',
    area: 'valider',
    cible: code,
    type: 'clic'
  }
}

function onClickNextOrPrevious (item, action) {
  return {
    track_page: 'fip',
    track_zone: 'banniere',
    track_nom: action,
    track_type: 'clic',
    track_cible: _getTrackCible(item)
  }
}

function onClickPreview (item) {
  return {
    track_page: 'fip',
    track_zone: 'banniere',
    track_nom: 'play_BA-icone',
    track_type: 'clic',
    track_cible: _getTrackCible(item),
    track_detail: 'clic-play_BA-icone-banniere-fip'
  }
}

function onClickBuyOrRent (item, definition, userQualification, isInMyAlerts, isInMyBookmarks) {
  return {
    page: 'fip',
    zone: 'banniere',
    area: definition.usage === 'RENT' ? 'louer' : 'acheter',
    cible: (definition.discountId ? definition.discountId + '-' + definition.name + '-' : '') + _getTrackCible(item),
    type: _isInTopCategory(item.parentCategories) && userQualification ? 'clic-top' : isInMyAlerts ? 'clic-alerte' : isInMyBookmarks ? 'clic-favori' : 'clic'
  }
}

function onSwitchQuality (quality) {
  return {
    track_page: 'fip',
    track_zone: 'banniere',
    track_nom: quality,
    track_type: 'clic',
    track_detail: `clic-${quality}-banniere-fip`
  }
}

function onSelectSeason (item) {
  return {
    track_page: 'fip',
    track_zone: 'banniere',
    track_nom: 'selecteur_saison',
    track_type: 'clic',
    track_cible: 'SEASON' + '-' + item.id + '-' + item.title,
    track_detail: 'clic-selecteur_saison-banniere-fip'
  }
}

function onPlayVideoIcon (item) {
  return {
    track_page: 'fip',
    track_zone: 'mosaique',
    track_nom: 'play_video-icone',
    track_type: 'clic',
    track_cible: _getTrackCible(item),
    track_detail: 'clic-play_video-icone-mosaique-fip'
  }
}

function onSelectVideo (item) {
  return {
    track_page: 'fip',
    track_zone: 'mosaique',
    track_nom: 'selection_video',
    track_type: 'clic',
    track_cible: _getTrackCible(item),
    track_detail: 'clic-selection_video-mosaique-fip'
  }
}

function onDownloadIcon (item) {
  return {
    track_page: 'fip',
    track_zone: 'cover',
    track_nom: 'telecharger-icone',
    track_type: 'clic',
    track_cible: _getTrackCible(item),
    track_detail: 'clic-telecharger-icone-cover-fip'
  }
}

function onWatchVideo (item, orderInfo) {
  return {
    track_page: 'fip',
    track_zone: 'banniere',
    track_nom: 'play_video-regarder',
    track_type: 'clic',
    track_cible: _getTrackCible(item),
    track_detail: 'clic-play_video-regarder-banniere-fip',
    selected_quality: orderInfo.quality
  }
}

function onClickDownload (item) {
  return {
    track_page: 'fip',
    track_zone: 'banniere',
    track_nom: 'telecharger',
    track_type: 'clic',
    track_cible: _getTrackCible(item),
    track_detail: 'clic-telecharger-banniere-fip'
  }
}

function onToggleResume (items) {
  return {
    page: 'fip',
    zone: 'mosaique',
    area: 'toggle_resume',
    cible: 'SEASON' + '-' + items[0].seasonInfo.id + '-' + items[0].seasonInfo.title,
    type: 'clic'
  }
}

function onSelectMosaicVideo (item, userQualification, isInMyAlerts, isInMyBookmarks) {
  return {
    track_page: 'fip',
    track_zone: 'mosaique',
    track_nom: 'video',
    track_type: _isInTopCategory(item.parentCategories) && userQualification ? 'clic-top' : isInMyAlerts ? 'clic-alerte' : isInMyBookmarks ? 'clic-favori' : 'clic',
    track_cible: _getTrackCible(item),
    track_detail: 'clic-selection_video-mosaique-fip'
  }
}

function onPlayVideoCover (item) {
  return {
    page: 'fip',
    zone: 'cover',
    area: 'play_video-cover',
    cible: _getTrackCible(item),
    type: 'clic'
  }
}

function onPlayPreview (item) {
  return {
    page: 'fip',
    zone: 'cover',
    area: 'play_bande_annonce',
    cible: _getTrackCible(item),
    type: 'clic'
  }
}

function onClickReco (item) {
  return {
    track_page: 'fip',
    track_zone: 'A voir aussi',
    track_nom: 'element_mea',
    track_type: 'click',
    track_cible: _getTrackCibleReco(item),
    track_detail: ''
  }
}

function onClickRecoPack (item) {
  return {
    track_page: 'fip',
    track_zone: 'Aussi en pack',
    track_nom: 'element_mea',
    track_type: 'click',
    track_cible: _getTrackCibleReco(item),
    track_detail: ''
  }
}

// -------------------------------------------------------

function _getTrackCibleReco (item) {
  return `A voir aussi - ${_getTrackCible(item)}`
}

function _getTrackCible (item) {
  return item.type + (item.videoType ? '/' + item.videoType : '') + '-' + item.id + '-' + item.title
}

function _isInTopCategory (items) {
  return !!items?.find(cat => cat.isTop)
}

// -------------------------------------------------------

export default {
  onClickDeleteAlertButton,
  onClickActiveAlertButton,
  onDeactivateDiscount,
  onUserNotEligible,
  onConfigNotCompatible,
  onPcIncompatible,
  onHlsfpNotAvailable,
  onClickBackButton,
  onValidateCoupon,
  onClickNextOrPrevious,
  onClickPreview,
  onClickBuyOrRent,
  onSwitchQuality,
  onSelectSeason,
  onPlayVideoIcon,
  onSelectVideo,
  onDownloadIcon,
  onWatchVideo,
  onClickDownload,
  onToggleResume,
  onSelectMosaicVideo,
  onPlayVideoCover,
  onPlayPreview,
  onClickReco,
  onClickRecoPack
}
