export default {
  /**
   * Show modal
   * @param state
   */
  showModal (state) {
    state.showModal = true
  },
  /**
   * Close modal - Remove last entry from modals to open when closing
   * @param state
   */
  closeModal (state) {
    state.showModal = false
    state.modalsToOpen.shift()
  },
  /**
   * Add modal to open
   * if modal already opende, closing/remove previous one before showing new one - else add modal
   * @param state
   * @param modalToOpen
   */
  addModalToOpen (state, modalToOpen) {
    state.modalsToOpen.push(modalToOpen)
  },
  /**
   * Clear all modals to open
   * @param state
   */
  clearModalsToOpen (state) {
    state.showModal = false
    state.modalsToOpen = []
  },

  /**
   *
   * @param state
   * @param maxymiserTest
   */
  setMaxymiserTest (state, maxymiserTest) {
    state.maxymiserTest = maxymiserTest
  }
}
