import flexivod from '../../components/shared/flexivod'

export default {
  getCategories (state) {
    return state.categories
  },
  getFilteredCategories (state, getters, rootState, rootGetters) {
    const userDiscounts = rootGetters['user/getUserDiscounts']
    const activatedCoupon = rootGetters['user/getActivatedCoupon']
    const categories = state.categories
    return flexivod
      .rejectNotEligiblePromoCategory(userDiscounts, activatedCoupon, categories)
      .filter(categorie => categorie.nature !== 'EMPTY')
  },
  getHerozone (state) {
    return state.herozone
  }
}
