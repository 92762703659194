<template>
  <wptv-carousel
    :responsive-rules="responsiveRules"
    :show-arrows="false"
    :with-margins="false"
    :show-scroll-bar="false"
    class="carousel-menu ob1-menu-page"
    theme="light">
    <div class="nav o-nav-light">
      <wptv-carousel-item
        :has-responsive-rules="false"
        :aria-label="$t('general.menu.home')"
        :class="['nav-item', { 'active': $route.name === RouteUtils.ROUTE.HOME }]">
        <router-link
          v-tealium:click="$stats.menu.onClickHome()"
          :to="{ name: RouteUtils.ROUTE.HOME }"
          class="nav-link px-0 mx-1"
          data-wptv="menu-item-home">
          {{ $t('general.menu.home') }}
        </router-link>
      </wptv-carousel-item>
      <wptv-carousel-item
        v-for="category in menu"
        :key="category.id"
        :has-responsive-rules="false"
        :aria-label="$t('general.menu.accessibility.item', { title: category.label })"
        :class="['nav-item', { 'active': currentItemSelected === category.id }]">
        <router-link
          v-tealium:click="$stats.menu.onClickCategory(category.id, false, userQualification)"
          :to="RouteUtils.createRouteFromConfigMenu(category)"
          :class="['nav-item', 'nav-link', 'px-0', 'mx-1', { 'active': currentItemSelected }]"
          data-wptv="menu-item-category">
          {{ category.label }}
        </router-link>
      </wptv-carousel-item>
      <wptv-carousel-item
        v-for="(item, index) in userMenu"
        :key="index"
        :has-responsive-rules="false"
        :aria-label="$t('general.menu.accessibility.item', { title: $t(`general.menu.${item.name}`) })"
        :class="['nav-item', { 'active': $route.name === item.name }]">
        <router-link
          v-tealium:click="item.stat"
          :to="{ name: item.name }"
          :data-wptv="`menu-${item.dataWtpv}`"
          class="nav-link px-0 mx-1">
          {{ $t(`general.menu.${item.name}`) }}
          <notification
            :show="item.name === RouteUtils.ROUTE.LIST && counter > 0"
            :notification-menu="true" />
        </router-link>
      </wptv-carousel-item>
      <wptv-carousel-item
        v-tealium:click="$stats.menu.onClickConfigTests()"
        :has-responsive-rules="false"
        :aria-label="$t(`general.menu.configuration`)"
        data-wptv="menu-configuration"
        class="nav-item"
        @click.native="$root.$emit('open-explicit-tests')">
        <span class="nav-link">{{ $t(`general.menu.configuration`) }}</span>
      </wptv-carousel-item>
    </div>
  </wptv-carousel>
</template>
<script>
import { WptvCarousel, WptvCarouselItem } from '@wptv/components/ui/carousel'
import Notification from '../../../shared/notification/notification'
import RouteUtils from '../../../../route.utils'
import { mapGetters } from 'vuex'

export default {
  name: 'SlidingMenu',
  components: {
    WptvCarousel,
    WptvCarouselItem,
    Notification
  },
  props: {
    menu: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      responsiveRules: { xsmall: 2, small: 3, medium: 3, large: 3, xlarge: 4, xxlarge: 4 },
      currentItemSelected: null,
      sticked: false,
      RouteUtils
    }
  },
  computed: {
    ...mapGetters({
      userQualification: 'user/getUserQualification',
      counter: 'alerts/getCounterNotification'
    }),
    userMenu () {
      return [
        {
          name: RouteUtils.ROUTE.VIDEOS,
          stat: this.$stats.menu.onClickMyVideos(),
          dataWtpv: 'mes_videos'
        },
        {
          name: RouteUtils.ROUTE.LIST,
          stat: this.$stats.menu.onClickMyBookmarksAlerts(),
          dataWtpv: 'mes_favoris'
        },
        {
          name: RouteUtils.ROUTE.ACCOUNT,
          stat: this.$stats.menu.onClickMyAccount(),
          dataWtpv: 'mon_compte'
        }
      ]
    }
  },
  watch: {
    '$route' () {
      this.currentItemSelected = this.$route.params.id
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel-menu {
  height: 100% !important;

  ::v-deep .scroll-container {
    margin-bottom: -1px; // need to stick the border, there is no other padding or margin
    .items-container {
      margin: 0;
      padding-left: 0.75rem;
    }
  }

  .scroll-container {
    .items-container {
      .o-nav-light {
        padding-left: 0;
      }
    }
  }
}
</style>
