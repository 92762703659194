export default {
  title: 'Tri :',
  sortOptions: {
    default: 'Notre sélection',
    altTitle: 'Trier la catégorie',
    aToZ: 'De A à Z',
    zToA: 'De Z à A',
    datesDecreasing: 'Dates décroissantes',
    boxOffice: 'Box-office',
    pressRating: 'Notes presse 5 à 1',
    spectatorRating: 'Notes spectateurs 5 à 1'
  },
  sortOptionsSeason: {
    increaseOrder: 'Croissant',
    decreaseOrder: 'Décroissant'
  },
  popupTitle: 'Plus de filtres',
  noResult: 'Aucun résultat ne correspond à vos critères de sélection'
}
