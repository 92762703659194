import { mapGetters } from 'vuex'

export default {
  head () {
    const script = []
    const meta = []

    if (this.pageConfig.maxymiserTest) {
      script.push({ innerHTML: this.maxymiserTest })
    }

    if (this.pageConfig.oCommon) {
      script.push({ innerHTML: this.pageConfig.oCommon })
    }

    if (this.pageConfig.idZone) {
      script.push({ src: this.pageConfig.idZone, defer: true, body: true })
    }

    if (this.pageConfig.oLoadUrl) {
      script.push({ src: this.pageConfig.oLoadUrl, defer: true, body: true })
    }

    if (!this.pageConfig.allowPageIndexing) {
      meta.push({ hid: 'robots', name: 'robots', content: 'noindex' })
    }

    return {
      meta,
      script,
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: this.$config.confetti.public.root.wassup + this.$route.path
        },
        ...this.pageConfig.preconnects
      ],
      __dangerouslyDisableSanitizers: ['script']
    }
  },
  computed: {
    ...mapGetters({
      pageConfig: 'core/getPageConfig',
      maxymiserTest: 'config/getMaxymiserTest'
    })
  }
}
