import _get from 'lodash/get'
import urlJoin from 'url-join'

export default {
  /**
   * Retrieve a specific category with a categoryId
   * @param categoryId
   * @returns {Promise<any | never>}
   */
  fetchCategoryById (categoryId) {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.catalogs'),
      _get(this.$config, 'confetti.public.api.tvm.universeId'),
      _get(this.$config, 'confetti.public.api.tvm.paths.categories'),
      categoryId)
    const method = 'api.categoriesApi.fetchCategoryById()'
    const options = {
      cache: {
        exclude: { query: false }
      }
    }

    return this.$http.tvm.notAuth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  }
}
