export default {
  getBookmarks ({ commit, dispatch }) {
    return this.$api.tvm.bookmarks.getBookmarks()
      .then((response) => {
        if (response.error) {
          dispatch('errors/setAxiosError', { error: response, bypass: true }, { root: true })
          return commit('setBookmarks', {})
        }
        return commit('setBookmarks', response)
      })
  },
  addBookmark ({ commit, dispatch }, article) {
    return this.$api.tvm.bookmarks.addBookmark(article)
      .catch(err => dispatch('errors/setAxiosError', { error: { ...err, videoId: article.id } }, { root: true }))
  },
  deleteBookmark ({ commit, dispatch }, { bookmarkId }) {
    return this.$api.tvm.bookmarks.deleteBookmark(bookmarkId)
      .catch(err => dispatch('errors/setAxiosError', { error: { ...err, videoId: bookmarkId } }, { root: true }))
  }
}
