export default {
  ABTESTING: {
    BOOKMARKS: {
      VARIANTS: {
        DEFAULT: 'default'
      }
    },
    DEFAULT: 'Default'
  },
  ALERT_STATUS: {
    PROGRAMMED: 'PROGRAMMED',
    PROSPECTED: 'PROSPECTED',
    PUBLISHED: 'PUBLISHED',
    REMOVED: 'REMOVED'
  },
  CHIP_ALL_POPUP_VALUE: 'filter-chip-popup-all',
  CHIP_ALL_VALUE: 'filter-chip-all',
  ERROR_TOKEN: {
    ECONNABORTED: 'ECONNABORTED',
    PREFIX: 'TOKEN_',
    TOKEN_TIMEOUT: 'TIMEOUT'
  },
  ERRORS_CODES: {
    TVM_BOOKMARKS: ['2330', '2362'],
    TVM_EU: ['2308'],
    TVM_MAX_TERMINALS: ['2366', '2367'],
    TVM_NO_CONTENT: ['60'],
    TVM_NOT_AUTHENTIFIED: ['40', '41'],
    TVM_NOT_ELIGIBLE: ['2306', '2332'],
    TVM_SIMULTANEOUS_REACH: ['2336'],
    TVM_TECHNICAL: ['1', '5', '61', '62', '68', '2300', '2301', '2302', '2303', '2304', '2305', '2331', '2333', '2335', '2337', '2339', '2341', '2342', '2343', '2344', '2347', '2348', '2349', '2350', '2353', '2355', '2356', '2357', '2373']
  },
  CONTENT_ERRORS: {
    CONTENT_PLAYID_NOT_FOUND: 'CONTENT_PLAYID_NOT_FOUND',
    CONTENT_SEASONNUMBER_NOT_FOUND: 'CONTENT_SEASONNUMBER_NOT_FOUND'
  },
  IMAGE_CONFIG: {
    CORNER: {
      TYPE_C: [
        {
          media: '(max-width:320px)',
          format: '130x'
        },
        {
          media: '(max-width:480px)',
          format: '256x'
        },
        {
          media: '(max-width:768px)',
          format: '256x'
        },
        {
          media: '(max-width:1024px)',
          format: '120x'
        },
        {
          media: '(max-width:1280px)',
          format: '160x'
        },
        {
          media: '(max-width:1440px)',
          format: '180x'
        }
      ],
      TYPE_B: [
        {
          media: '(max-width:320px)',
          format: '130x'
        },
        {
          media: '(max-width:480px)',
          format: '256x'
        },
        {
          media: '(max-width:768px)',
          format: '256x'
        },
        {
          media: '(max-width:1280px)',
          format: '160x'
        },
        {
          media: '(max-width:1440px)',
          format: '180x'
        }
      ],
      EVENT_2: [
        {
          media: '(max-width:320px)',
          format: '300x'
        },
        {
          media: '(max-width:480px)',
          format: '452x'
        },
        {
          media: '(max-width:768px)',
          format: '640x'
        },
        {
          media: '(max-width:1024px)',
          format: '452x'
        },
        {
          media: '(max-width:1280px)',
          format: '640x'
        },
        {
          media: '(max-width:1440px)',
          format: '651x'
        }
      ],
      TYPE_BARKER_COVER: [
        {
          media: '(max-width:320px)',
          format: '130x'
        },
        {
          media: '(max-width:480px)',
          format: '256x'
        },
        {
          media: '(max-width:768px)',
          format: '256x'
        },
        {
          media: '(max-width:1280px)',
          format: '160x'
        },
        {
          media: '(max-width:1440px)',
          format: '180x'
        }
      ],
      TYPE_BARKER_BANNER: [
        {
          media: '(max-width:480px)',
          format: '408x'
        },
        {
          media: '(max-width:736px)',
          format: '330x'
        },
        {
          media: '(max-width:960px)',
          format: '287x'
        },
        {
          media: '(max-width:1200px)',
          format: '360x'
        },
        {
          media: '(max-width:1440px)',
          format: '330x'
        },
        {
          media: '(min-width:1440px)',
          format: '720x'
        }
      ]
    },
    BANNER_1: [{
      media: '(max-width:480px)',
      format: 512
    }, {
      media: '(max-width:736px)',
      format: 651
    }, {
      media: '(max-width:960px)',
      format: 434
    }, {
      media: '(max-width:1200px)',
      format: 540
    }, {
      media: '(max-width:1440px)',
      format: 640
    }, {
      media: '(min-width:1440px)',
      format: 720
    }],
    COVER: [{
      media: '(max-width:480px)',
      format: 223
    }, {
      media: '(max-width:736px)',
      format: 352
    }, {
      media: '(max-width:960px)',
      format: 217
    }, {
      media: '(max-width:1200px)',
      format: 280
    }, {
      media: '(max-width:1440px)',
      format: 256
    }, {
      media: '(min-width:1440px)',
      format: 310
    }]
  },
  IMAGES_FORMAT: {
    BANNER: 'BANNER_1',
    COVER: 'COVER'
  },
  IMAGES_SOURCES: {
    COVERS: 'covers',
    IMAGES: 'images'
  },
  LANGUAGES: {
    HD: 'HD',
    HI: 'HI',
    QAD: 'QAD',
    VF: 'VF',
    VOST_VF: 'VOST/VF',
    VOST: 'VOST'
  },
  MLV: {
    COMPATIBLE_OSES: ['Windows', 'Mac OS']
  },
  NOTIFICATIONS: {
    NOTIFIED: 'NOTIFIED',
    VIEWED: 'VIEWED',
    WAITING: 'WAITING'
  },
  ORDER_ERRORS_CODES: {
    DISCOUNT_NOT_APPLICABLE: 2365,
    FORBIDDEN_SECONDARY_ACCOUNT_NOMAD: 2307,
    GEOBLOCKING: 2308,
    INVALID_COUPON: 2368,
    MAX_ATTEMPTS_REACHED: 2363,
    MAX_TERMINAL_EXCEEDED: 2367,
    MAX_TERMINAL_REMOVALS_EXCEEDED: 2366,
    TICKET_ALREADY_EXISTS: 2346
  },
  ORIGIN: 'VOD',
  PAYMENT_ERRORS: [2366, 2367, 2307, 2346, 2365, 2368, 2363],
  PAYMENT_INFO_ERRORS: [2307, 2308],
  PAYMENT_SITUATION: {
    MIXTE: 'MIXTE',
    POSTPAID: 'POSTPAID',
    PREPAID: 'PREPAID'
  },
  PROMO: {
    CLIENT_RESPONSIBILITY: 'CLIENT_RESPONSIBILITY',
    DISCOUNT_CATEGORY: 'DISCOUNT_CATEGORY',
    NOT_SATISFIED: 'NOT_SATISFIED',
    QUALITY_HD: 'HD',
    QUALITY_SD: 'SD',
    SATISFIED: 'SATISFIED',
    TYPE_PACKAGE: 'TPACKAGE',
    TYPE_TSEASON: 'TSEASON',
    TYPE_TVOD: 'TVOD',
    USAGE_RENT: 'RENT',
    USAGE_SELL: 'SELL'
  },
  QUALITIES: {
    UHD4K: '4K',
    HD: 'HD',
    SD: 'SD'
  },
  RECOMMENDATIONS: {
    DEFAULT_LIMIT: '5'
  },
  RESOURCE_TYPES: {
    HOME: 'HOME',
    CATEGORY: 'CATEGORY',
    CORNER: 'CORNER',
    DISCOUNT_CATEGORY: 'DISCOUNT_CATEGORY',
    EPISODE: 'EPISODE',
    LINK: 'LINK',
    MOVIE: 'MOVIE',
    PACKAGE: 'PACKAGE',
    SEASON: 'SEASON',
    SERIES: 'SERIES',
    TRAILER: 'TRAILER',
    VIDEO: 'VIDEO'
  },
  SUSPENDED_CODES: {
    INVALID: 'HOMECHECKSTATUS_INVALID',
    NA: 'NA',
    NOT_EXISTING: 'NOT_EXISTING',
    NOT_FOUND: 'OPTION_NOT_FOUND ',
    SUSPENDED_GUEST: 'SUSPENDED_GUEST',
    SUSPENDED: 'SUSPENDED'
  }
}
