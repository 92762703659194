function onClickDeleteAlert (alert) {
  return {
    page: 'mes_favoris',
    zone: 'mosaique-alerte',
    area: 'supprimer',
    cible: `${alert.type}-${alert.id}-${alert.title}`,
    type: 'clic'
  }
}

export default {
  onClickDeleteAlert
}
