import _last from 'lodash/last'
export default {
  getErrors (state) {
    return state.errors
  },
  getLastError (state) {
    return _last(state.errors)
  },
  getNbErrorsInSession (state) {
    return state.nbErrorsInSession
  },
  getMaintenanceCode (state) {
    return state.maintenanceCode
  }
}
