import _get from 'lodash/get'
import { minify } from '../shared/utils'

/**
 * Get OCommon
 * @param env
 * @param $config
 * @returns {*|string}
 * @private
 */
export default (env, $config) => {
    const oConfCommon = `
        var o_confCommon=${JSON.stringify(_get($config, 'confetti.private.commonElements.oConfCommon'))};

        o_confCommon.callbackHead = function () {
          var event = document.createEvent('Event');
          event.initEvent('resize', true, false);
          window.dispatchEvent(event);
          o_footer();
          o_refreshSession();
        };

        o_confCommon.callbackInfoCookie = function () {
          var event = document.createEvent('Event');
          event.initEvent('resize', true, false);
          window.dispatchEvent(event);
        };
    `
    const oData = `var o_data=${JSON.stringify(_get($config, 'confetti.private.commonElements.oData'))};`

    return !env.analyze ? minify(`${oConfCommon}${oData}`) : ''
}
