function onActivateFilter (filterName, activeFiltersList) {
  return {
    track_page: 'categorie',
    track_zone: 'filtre',
    track_nom: 'selection',
    track_cible: filterName,
    track_type: 'clic',
    track_detail: activeFiltersList
  }
}

function onApplyMultipleFilters (nbRes, activeFiltersList) {
  return {
    track_page: 'categorie',
    track_zone: 'filtre',
    track_nom: 'menu-afficher_resultat',
    track_cible: nbRes,
    track_type: 'clic',
    track_detail: activeFiltersList
  }
}

function onCloseModalFilters () {
  return {
    track_page: 'categorie',
    track_zone: 'filtre',
    track_nom: 'menu_fermer',
    track_type: 'clic'
  }
}

function onDisactivateFilter (filterName, activeFiltersList) {
  return {
    track_page: 'categorie',
    track_zone: 'filtre',
    track_nom: 'deselection',
    track_cible: filterName,
    track_type: 'clic',
    track_detail: activeFiltersList
  }
}

function onResetAllFilters () {
  return {
    track_page: 'categorie',
    track_zone: 'filtre',
    track_nom: 'menu-effacer_filtre',
    track_type: 'clic'
  }
}

function onShowModalFilters () {
  return {
    track_page: 'categorie',
    track_zone: 'filtre',
    track_nom: 'ouverture_menu',
    track_type: 'clic'
  }
}

export default {
  onActivateFilter,
  onApplyMultipleFilters,
  onCloseModalFilters,
  onDisactivateFilter,
  onResetAllFilters,
  onShowModalFilters
}
