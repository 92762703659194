import Vue from 'vue'
import capitalizeFLetter from './capitalizeFLetter/capitalizeFLetter.filter'
import conversionDateTimestamp from './conversionDateTimestamp/conversionDateTimestamp.filter'
import conversionHourTimestamp from './conversionHourTimestamp/conversionHourTimestamp.filter'
import formatDuration from './formatDuration/formatDuration.filter'
import formatPrice from './formatPrice/formatPrice.filter'
import getDecoratedTitle from './getDecoratedTitle/getDecoratedTitle.filter'
import convertDateYmdToDmy from './convertDateYmdToDmy/convertDateYmdToDmy.filter'
import getTwoDigitNumber from './getTwoDigitNumber/getTwoDigitNumber.filter'
import convertDotToComma from './convertDotToComma/convertDotToComma.filter'

Vue.filter('conversionDateTimestamp', conversionDateTimestamp)
Vue.filter('conversionHourTimestamp', conversionHourTimestamp)
Vue.filter('formatPrice', formatPrice)
Vue.filter('getDecoratedTitle', getDecoratedTitle)
Vue.filter('formatDuration', formatDuration)
Vue.filter('capitalizeFLetter', capitalizeFLetter)
Vue.filter('convertDateYmdToDmy', convertDateYmdToDmy)
Vue.filter('getTwoDigitNumber', getTwoDigitNumber)
Vue.filter('convertDotToComma', convertDotToComma)
