function onClickHome () {
  return {
    track_page: 'toutes_pages',
    track_zone: 'menu',
    track_nom: 'accueil',
    track_type: 'clic',
    track_cible: '',
    track_detail: 'clic-accueil-menu-toutes_pages'
  }
}

function onClickSearch () {
  return {
    track_page: 'toutes_pages',
    track_zone: 'recherche',
    track_nom: 'ouvrir_cartouche',
    track_type: 'clic',
    track_cible: '',
    track_detail: 'clic-ouvrir_cartouche-recherche-toutes_pages'
  }
}

function onClickAllCatalog () {
  return {
    track_page: 'toutes_pages',
    track_zone: 'menu',
    track_nom: 'tout_catalogue',
    track_type: 'clic',
    track_cible: '',
    track_detail: 'clic-tout_catalogue-menu-toutes_pages'
  }
}

function onClickCategory (categoryId, isTop, userQualification) {
  return {
    track_page: 'toutes_pages',
    track_zone: 'menu',
    track_nom: 'categorie',
    track_type: isTop && userQualification ? 'clic-top' : categoryId === 'MA_LISTE_CATEGORY_ID' ? 'clic-liste' : 'clic',
    track_cible: categoryId,
    track_detail: 'clic-categorie-menu-toutes_pages'
  }
}

function onClickMyVideos () {
  return {
    track_page: 'toutes_pages',
    track_zone: 'menu',
    track_nom: 'mes_videos',
    track_type: 'clic',
    track_cible: '',
    track_detail: 'clic-mes_videos-menu-toutes_pages'
  }
}

function onClickMyBookmarksAlerts () {
  return {
    track_page: 'toutes_pages',
    track_zone: 'menu',
    track_nom: 'mes_favoris',
    track_type: 'clic',
    track_cible: '',
    track_detail: 'clic-mes_favoris-menu-toutes_pages'
  }
}

function onClickMyAccount () {
  return {
    track_page: 'toutes_pages',
    track_zone: 'menu',
    track_nom: 'cpp',
    track_type: 'clic',
    track_cible: '',
    track_detail: 'clic-cpp-menu-toutes_pages'
  }
}

function onClickHelp () {
  return {
    track_page: 'toutes_pages',
    track_zone: 'menu',
    track_nom: 'mon_espace_vod',
    track_type: 'clic',
    track_cible: '',
    track_detail: 'clic-aide-menu-toutes_pages'
  }
}

function onClickConfigTests () {
  return {
    track_page: 'toutes_pages',
    track_zone: 'menu',
    track_nom: 'tester_config',
    track_type: 'clic',
    track_cible: '',
    track_detail: 'clic-tester_config-menu-toutes_pages'
  }
}

function onClickMlv () {
  return {
    track_page: 'toutes_pages',
    track_zone: 'menu',
    track_nom: 'mlv',
    track_type: 'clic',
    track_cible: '',
    track_detail: 'clic-mlv-menu-toutes_pages'
  }
}

function onClickAppliTv () {
  return {
    track_page: 'toutes_pages',
    track_zone: 'menu',
    track_nom: 'appli_tv_orange',
    track_type: 'clic',
    track_cible: '',
    track_detail: 'clic-appli_tv_orange-menu-toutes_pages'
  }
}

function onClickTutorial (config) {
  return {
    track_page: 'toutes_pages',
    track_zone: 'menu',
    track_nom: 'didacticiel',
    track_type: 'clic',
    track_cible: config.confetti.public.urls.linkMenuDidacticiel,
    track_detail: 'clic-didacticiel-menu-toutes_pages'
  }
}

function onClickAssistance (supportUrl) {
  return {
    track_page: 'toutes_pages',
    track_zone: 'menu',
    track_nom: 'assistance',
    track_type: 'clic',
    track_cible: supportUrl,
    track_detail: 'clic-assistance-menu-toutes_pages'
  }
}

function onClickContact (config) {
  return {
    track_page: 'toutes_pages',
    track_zone: 'menu',
    track_nom: 'contact',
    track_type: 'clic',
    track_cible: config.confetti.public.urls.linkMenuContact,
    track_detail: 'clic-contact-menu-toutes_pages'
  }
}

function onClickCgv () {
  return {
    track_page: 'toutes_pages',
    track_zone: 'menu',
    track_nom: 'cgv',
    track_type: 'clic',
    track_cible: '',
    track_detail: 'clic-cgv-menu-toutes_pages'
  }
}

export default {
  onClickHome,
  onClickSearch,
  onClickAllCatalog,
  onClickCategory,
  onClickMyVideos,
  onClickMyBookmarksAlerts,
  onClickMyAccount,
  onClickHelp,
  onClickConfigTests,
  onClickMlv,
  onClickAppliTv,
  onClickTutorial,
  onClickAssistance,
  onClickContact,
  onClickCgv
}
