import _get from 'lodash/get'
import urlJoin from 'url-join'

export default {
  /**
   * Get packages
   * @param packageId
   * @returns {Promise<T | never>}
   */
  getPackage (packageId) {
    const path = urlJoin(
      'applications',
      this.getApplication(),
      _get(this.$config, 'confetti.public.api.tvm.paths.packages'),
      packageId)
    const method = 'api.packages.getPackage()'
    const options = {
      cache: {
        exclude: { query: false }
      }
    }

    return this.$http.tvm.notAuth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  }
}
