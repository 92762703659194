export default {
  title: {
    episode: '{titleSeason} - {titleEpisode} - Orange',
    pack: 'Pack Série Films - {title} - Orange'
  },
  description: {
    single: 'Streaming - {title} - {synopsis} - Orange',
    season: 'Streaming - {title} - {synopsis} - Orange',
    episode: 'Streaming - {title} - {synopsis}',
    pack: 'Collection film série : {title} - {synopsis}'
  },
  buttons: {
    buyFree: 'Achat offert',
    rentFree: 'Location offerte',
    buyLabel: 'Acheter',
    rentLabel: 'Louer',
    watchLabel: 'Regarder',
    continueWatchLabel: 'Reprendre',
    infoLabel: 'Information',
    downloadLabel: 'Télécharger',
    ValidateCoupon: 'Valider',
    DeactivateCoupon: 'Désactiver',
    setAlert: 'M\'alerter de la disponibilité VOD',
    deleteAlert: 'Supprimer l\'alerte'
  },
  coupons: {
    error: {
      2363: 'Le nombre de tentatives maximum est atteint. Merci de réessayer ultérieurement.',
      2368: 'Ce code n\'est pas valide.',
      2369: 'Ce code promo a déjà été utilisé.',
      2370: 'Le nombre maximum d\'utilisations de ce code promo a été atteint.',
      2371: 'La promotion n\'a pas encore débuté.',
      2372: 'Ce code promo est expiré.',
      2375: 'Vous avez déjà utilisé ce code promo.',
      2376: 'Ce code n\'est pas valable pour ce contenu.',
      valide_not_applicable: 'Ce code n\'est pas valable pour ce contenu.\nChoisissez un autre contenu éligible et votre code sera pris en compte.',
      valide_not_prio: 'Ce code est valide mais non cumulable avec la promotion déjà en cours.\nChoisissez un autre contenu éligible et votre code sera pris en compte.'
    },
    addButton: 'Ajouter',
    cancelButton: 'Annuler',
    addPromotionalCodeLabel: 'Code promo',
    successLabel: 'Code promo activé'
  },
  download: {
    mlvModalContent: 'Pour pouvoir télécharger vos vidéos sur votre ordinateur, vous devez d\'abord installer l\'application Mon Lecteur Vod.',
    mlvModalTitle: 'Avertissement',
    mlvModalButton: 'Installer Mon Lecteur VOD',
    appModalContent_p1: 'Le téléchargement de VOD n\'est pas disponible sur navigateur web.',
    appModalContent_p2: 'Nous vous invitons à utiliser l\'application TV d\'Orange sur tablette/mobile ou Mon lecteur VOD sur votre ordinateur PC/MAC.',
    tvApp: 'Appli TV d\'Orange',
    windowsPhoneMOdalContent_p1: 'Le téléchargement de VOD n\'est pas possible sur cet écran.',
    windowsPhoneMOdalContent_p2: 'Nous vous invitons à utiliser Mon lecteur VOD sur votre ordinateur PC/MAC.'
  },
  offert: 'Offert',
  trailer: 'Regarder la bande-annonce',
  participantsList: {
    dirHeader: 'Réalisé par :',
    actHeader: 'Avec :',
    mesHeader: 'Mis en scène par :',
    autHeader: 'De :',
    preHeader: 'Présenté par :',
    proHeader: 'Produit par :',
    choHeader: 'Chorégraphie de :'
  },
  spectateurs: 'Spectateurs',
  presse: 'Presse',
  accessibilite_HD: 'Disponible en HD',
  accessibilite_sous_titre_malentendant_disponible: 'Sous titrage pour malentendant disponible',
  accessibilite_sous_titre_malentendant_non_disponible: 'Sous titrage pour malentendant non disponible',
  accessibilite_audio_description_disponible: 'Audio description disponible',
  accessibilite_pays_origine: 'Pays d\'origine ',
  accessibilite_annee_production: 'Produit en ',
  accessibilite_genre: 'Genre ',
  accessibilite_notes_allocine_spectateurs: 'Note Allociné spectateurs {rating} sur cinq',
  accessibilite_notes_allocine_presse: 'Note Allociné presse {rating} sur cinq',
  recommendations: {
    title: 'À voir aussi',
    pack_recommendations: '{videoCount} films'
  },
  errors: {
    title: 'Information',
    appliTV: 'Continuez votre commande pour la regarder sur l\'application TV d\'Orange ou sur un <a href="https://r.orange.fr/r/Owebtv_vod_config">autre écran compatible.</a>.',
    textNotPCAvailable: 'Pour des raisons de droits, cette vidéo ne peut être regardée sur votre écran. Continuez votre commande pour la regarder sur votre décodeur TV d\'Orange.',
    textPcMobileTabletNoFp: 'Cette vidéo ne peut temporairement pas être visionnée avec ce navigateur web sur cet écran.',
    continueOrder: 'Continuez votre commande pour la regarder sur un autre navigateur (Chrome, Firefox) ou sur un <a href="https://r.orange.fr/r/Owebtv_vod_config">autre écran compatible.</a>',
    continueOrderBox: 'Continuez votre commande pour la regarder sur votre décodeur TV d\'Orange ou sur un <a href="https://r.orange.fr/r/Owebtv_vod_config">autre écran compatible.</a>',
    configIncompatible: 'Configuration non compatible',
    cantPlay: 'Votre navigateur ne permet pas la lecture des vidéos.',
    tvApp: 'Appli TV d\'Orange',
    close: 'Fermer',
    continue: 'Continuer'
  },
  rentalSoonAvailable: 'disponible à la location le {date}',
  abTesting: {
    variantA: {
      addToListBookmarks: 'Ajouter à ma liste de favoris',
      removeFromListBookmarks: 'Enlever de ma liste de favoris'
    }
  },
  addToBookmarks: 'Ajouter à Mes favoris',
  removeFromBookmarks: 'Retirer de Mes favoris',
  visionnage: 'Visionnage à volonté',
  tempsRestantLocation: 'Disponible encore {time}{unit}',
  disponibilite: 'jusqu\'au {date} à {heure}',
  visionnageNOk: 'non visionnable sur votre écran',
  locationExpired: 'Location expirée',
  PACKAGE: 'pack',
  SEASON: 'saison',
  SERIES: 'série',
  VIDEO: 'vidéo',
  player: {
    closePlayer: 'Fermer la vidéo'
  },
  price: {
    Des: 'Dès {price}€'
  },
  moreInformation: 'En savoir plus',
  availableSoonForVod: 'Disponibilité VOD le {date}',
  releasedToTheaters: 'Sorti au cinéma le {date}',
  disponibility: 'Disponibilité (qualités vidéo sous réserve d\'éligibilité) : ',
  casting: {
    title: 'Acteurs / Réalisateur',
    author: 'Réalisation'
  },
  more: {
    title: 'Plus d\'informations'
  },
  accessibility: {
    watch: 'Regarder {title}',
    watchTrailer: 'Regarder la bande annonce de {title}',
    download: 'Télécharger {title}',
    buy: 'Acheter {title} à {price}€',
    buyEpisode: 'Commander dès {price} {title}',
    rent: 'Louer {title} à {price}€',
    free: '{title} offert',
    resume: 'Reprendre la lecture de {title}',
    next: 'Film suivant {title}',
    previous: 'Film précédent {title}',
    presse: 'Presse {note} sur cinq',
    spectateurs: 'Spectateurs {note} sur cinq',
    qualities: {
      hd: 'Haute définition',
      sd: 'définition standard'
    }
  }
}
