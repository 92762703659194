/**
 * On Scroll top
 * @param page
 * @returns {{track_page: string, track_zone: string, track_nom: string}}
 */
function onClickScrollTopButton (page) {
  return {
    track_page: 'toutes_pages',
    track_zone: 'menu',
    track_nom: 'retour_haut'
  }
}

/**
 * When Out of Eu modal is open
 * @returns {{area: string, cible: string, zone: string, page: string, type: string}}
 */
function onTriggerOutOfEu () {
  return {
    page: 'toutes_pages',
    zone: 'blocage_ue',
    area: 'popin',
    cible: '',
    type: 'affichage'
  }
}

function onClickOutOfEuHelp (supportUrl) {
  return {
    track_page: 'toutes_pages',
    track_zone: 'blocage_ue',
    track_nom: 'popin',
    track_type: 'clic-assist',
    track_cible: supportUrl,
    track_detail: 'clic-assist-popin-blocage_ue-toutes_pages'
  }
}

function onClickICloudPrivateRelayHelp (supportUrl) {
  return {
    track_page: 'toutes_pages',
    track_zone: 'blocage_ue',
    track_nom: 'popin',
    track_type: 'clic-relais_prive',
    track_cible: supportUrl,
    track_detail: 'clic-relais_prive-popin-blocage_ue-toutes_pages'
  }
}

function onTriggerNotAuthenticated () {
  return {
    page: 'toutes_pages',
    zone: 'non_authentifie',
    area: 'popin',
    cible: '',
    type: 'affichage'
  }
}

function onTriggerBuyRentStbOnly (item) {
  return {
    page: 'fip',
    zone: 'achat_loc-droitSTB_uniquement',
    area: 'popin',
    cible: `${item.type}-${item.id}-${item.title}`,
    type: 'affichage'
  }
}

function onClickBuyRentStbOnlyContinueButton (item) {
  return {
    page: 'fip',
    zone: 'achat_loc-droitSTB_uniquement',
    area: 'popin',
    cible: `${item.type}-${item.id}-${item.title}`,
    type: 'clic-continuer'
  }
}

function onOpenExplicitTest () {
  return {
    page: 'toutes_pages',
    zone: 'test_config',
    area: 'popin',
    type: 'affichage'
  }
}

function onExplicitRedirectHelp () {
  return {
    page: 'toutes_pages',
    zone: 'test_config',
    area: 'popin',
    type: 'clic-assist_mlv'
  }
}

function onClickExplicitDownload () {
  return {
    track_page: 'toutes_pages',
    track_zone: 'test_config',
    track_nom: 'popin',
    track_type: 'clic-installer_mlv',
    track_detail: 'clic-installer_mlv-popin-test_config-toutes_pages'
  }
}

// ---------------------------------------

export default {
  onClickScrollTopButton,
  onTriggerOutOfEu,
  onClickICloudPrivateRelayHelp,
  onClickOutOfEuHelp,
  onTriggerNotAuthenticated,
  onTriggerBuyRentStbOnly,
  onClickBuyRentStbOnlyContinueButton,
  onOpenExplicitTest,
  onExplicitRedirectHelp,
  onClickExplicitDownload
}
