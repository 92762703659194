export default {
  title: 'Catégorie : {category}',
  description: 'Retrouvez tous les films et séries dans notre catégorie {category} en streaming et téléchargement, sur tous vos écrans.',
  empty: 'Aucun résultat ne correspond à vos critères de sélection',
  results: '0 résultat | 1 résultat | {count} résultats',
  titleSrOnly: 'Catégorie : {categoryName}, Vidéo à la demande, Orange',
  strip: {
    srOnly: 'Catégorie : {categoryName}'
  },
  stripHP: {
    srOnly: 'Catégorie :'
  },
  herozone: {
    srOnly: 'Mise en avant {categoryName}'
  }
}
