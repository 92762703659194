<template>
  <div class="vod-header">
    <div class="title">
      <!-- TODO: insert new search here -->
      <vod-search
        ref="searchModal"
        :class="['vod-search', sticked ? 'sticked': '' ]" />
    </div>
    <desktop-menu
      :menu="menu"
      class="lol show-from-large" />
    <sliding-menu
      :menu="menu"
      class="hide-from-large" />
  </div>
</template>

<script>
import DesktopMenu from './desktopMenu/DesktopMenu'
import SlidingMenu from './slidingMenu/SlidingMenu'
import VodSearch from './search/Search'

export default {
  name: 'Menu',
  components: {
    SlidingMenu,
    DesktopMenu,
    VodSearch
  },
  data () {
    return {
      sticked: false
    }
  },
  computed: {
    menu () {
      return JSON.parse(this.$config.confetti.public.menu).list
    }
  },
  mounted () {
    // Generate search div
    if (window && window.o_generateSearchZone) {
      window.o_generateSearchZone('vodSearchEngine', 'vodSearchEngine')
    }
  },
  methods: {
    searchToggle () {
      this.$refs.searchModal.toggle()
    }
  }
}
</script>
