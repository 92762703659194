export default {
  getDSAChecked (state) {
    return state.DSAChecked || false
  },
  getConfigTests (state) {
    return state.configTests
  },
  getCapacity (state) {
    return state.configTests.capacity
  }
}
