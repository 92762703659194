export default {
  /**
   * Get trailer url
   * @param state
   * @returns {string}
   */
  getTrailer (state) {
    return state.trailer
  },
  /**
   *  get video url
   * @param state
   * @returns {string}
   */
  getVideo (state) {
    return state.video
  },
  /**
   * get video playlist
   * @param state
   * @returns {Playlist}
   */
  getPlaylist (state) {
    return state.playlist
  },
  /**
   * get CertificateSafari
   * @param state
   * @returns {string}
   */
  getCertificateSafari (state) {
    return state.certificateSafari
  },
  /**
   * get CertificateChrome
   * @param state
   * @returns {string}
   */
  getCertificateChrome (state) {
    return state.certificateChrome
  },
  /**
   * Check to avoid fetching certificates again
   * @param state
   * @returns {boolean|boolean}
   */
  isCertificateAvailable (state) {
    return state.certificateChrome !== '' && state.certificateSafari !== ''
  },
  /**
   * get EndOfTrailer
   * @param state
   * @returns {boolean}
   */
  getEndOfTrailer (state) {
    return state.endTrailer
  },
  /**
   * get isTrailerList boolean
   * @param state
   * @returns {boolean}
   */
  isTrailerList (state) {
    return state.isTrailerList
  }
}
