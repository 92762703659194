import _get from 'lodash/get'
import urlJoin from 'url-join'

export default {
  /**
   * fetch video trailer
   * @param videoId ID of the video
   * @param terminalModel user terminal type
   * @returns {Promise<any | never>}
   */
  fetchTrailer (terminalModel = 'BROWSER_WIN', videoId) {
    const path = urlJoin(
      _get(this.$config, 'confetti.public.api.gateway.paths.videos'),
      videoId,
      _get(this.$config, 'confetti.public.api.gateway.paths.trailer'),
      `?terminalModel=${terminalModel}`
    )
    const options = {
      cache: {
        exclude: { query: false }
      }
    }

    const method = 'api.playback.fetchTrailer()'
    return this.$http.gateway.playback.notAuth.get(path, options)
      .then((response) => {
        return this.$httpUtils.parseResponse(response, false)
      })
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  },
  /**
   * End a session with a specific id
   * @param sessionId
   * @returns {Promise<void | never>}
   */
  endSession (sessionId) {
    const path = urlJoin(
      _get(this.$config, 'confetti.public.api.tvm.paths.videos'),
      sessionId
    )
    const method = 'api.playback.endSession()'

    return this.$http.gateway.playback.notAuth.delete(path)
      .then(() => Promise.resolve())
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  },
  /**
   * fetch video trailer
   * @param videoId ID of the video
   * @param terminalModel user terminal type
   * @returns {Promise<any | never>}
   */
  fetchVideo (terminalModel = 'BROWSER_WIN', terminalId, videoId) {
    const path = urlJoin(
      _get(this.$config, 'confetti.public.api.gateway.paths.videos'),
      videoId,
      _get(this.$config, 'confetti.public.api.gateway.paths.movie')
    )

    const options = {
      params: {
        usage: 'STREAMING',
        terminalId,
        terminalModel
      }
    }
    const method = 'api.playback.fetchVideo()'

    return this.$http.gateway.playback.auth.get(path, options)
      .then(response => this.$httpUtils.parseResponse(response, false))
      .catch(responseError => this.$httpUtils.catchError({ responseError, path, method }))
  }
}
