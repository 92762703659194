import _get from 'lodash/get'

export default {
  /**
   * Fetch Catalog categories
   * @param commit
   * @param dispatch
   * @param $config
   * @returns {Promise<T | never | void>}
   */
  getCatalogCategories ({ commit, dispatch }) {
    return this.$api.tvm.catalogs.getCatalogs()
      .then(catalogs => commit('setCategories', catalogs))
      .catch((err) => {
        dispatch('errors/setAxiosError', { error: err }, { root: true })
        commit('setCategories', [])
      })
  },
  /**
    * Fetch Catalog categories for menu
    * @param dispatch
    * @param rootGetters
    * @returns {Promise<T | never>}
    */
  getCatalogCategoriesMenu ({ dispatch }) {
    return this.$api.tvm.catalogs.getCatalogs(true)
      .then(catalog => _get(catalog, 'categories', []))
      .catch(err => dispatch('errors/setAxiosError', { error: err }, { root: true }))
  },
  /**
    * Get Herozone
    * @param commit
    * @param dispatch
    * @param rootGetters
    * @returns {Promise<T | never | void>}
    */
  getHerozone ({ commit, dispatch }) {
    return this.$api.tvm.catalogs.getHerozone()
      .then(herozone => commit('setHerozone', herozone))
      .catch((err) => {
        dispatch('errors/setAxiosError', { error: err }, { root: true })
        commit('setHerozone', {})
      })
  }
}
