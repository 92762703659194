import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import API from '../../../api.constants'
import getDecoratedTitle from '../../../plugins/filters/getDecoratedTitle/getDecoratedTitle.filter'
import RouteUtils from '~/route.utils'

export default {
  FIP_TYPE: {
    MOVIE: 'MOVIE',
    SEASON: 'SEASON',
    PACKAGE: 'PACKAGE',
    EPISODE: 'EPISODE'
  },

  IMAGE_TYPE: {
    COVER: 'COVER',
    BANNER: 'BANNER_1'
  },

  /**
   *
   * @param program
   */
  hasOnePersonWithCover (program) {
    const hasCover = person => person.images ? person.images.find(image => !!image.url) : person.covers.find(cover => !!cover.url)
    const coverDirector = _get(program, 'directors', []).some(hasCover)
    const coverActor = _get(program, 'actors', []).some(hasCover)

    return coverActor || coverDirector
  },

  isProgramProspected (program) {
    return [API.ALERT_STATUS.PROGRAMMED, API.ALERT_STATUS.PROSPECTED].includes(_get(program, 'status'))
  },

  /**
   * Add history, filtering on episodes to push only episode on sale
   * Note: Only on direct access
   * @param episode
   * @param store
   * @returns {Q.Promise<void> | * | PromiseLike<void>|Promise<void>}
   */
  addSeasonHistory (episode, store) {
    if (process.server) {
      return store.dispatch('fip/fetchItem', {
        itemId: _get(episode, 'seasonInfo.id', null),
        type: this.FIP_TYPE.SEASON
      })
        .then((season) => {
          const episodes = _get(season, 'episodes', [])
          const episodesOnSale = episodes.filter(item => !item.orderInfo)

          store.commit('fip/pushHistory', episodesOnSale)
          store.commit('fip/setRedirectTo', {
            name: 'season',
            params: { id: season.id }
          })

          return Promise.resolve(episode)
        })
    } else {
      return Promise.resolve(episode)
    }
  },

  /**
   * Crop the description
   * @param {*} item
   */
  getSlicedDescription (item) {
    return item.slice(0, 157).replace(/\n|\r|(\n\r)/, '') + '...'
  },

  /**
   * Select correct recommendation API based on status
   * @param item
   * @param store
   * @param route
   * @returns {undefined|Promise<T>|Promise<any>|*}
   */
  getRecommendations (item, store, route) {
    const itemId = _get(route, 'params.id', null)
    return store.dispatch('recommendations/getAnonymRecommendations', { itemId }).then(recommendations => ({ item, recommendations }))
  },

  /**
   * Check if orderInfo exist to redirect if episode is already bought
   * @param episode
   * @param redirect
   * @returns {Promise<void>|any}
   */
  redirectIfEpisodeIsBought (episode, redirect) {
    const redirectUrl = {
      name: RouteUtils.ROUTE.FIP.SEASON,
      params: {
        id: _get(episode, 'seasonInfo.id'),
        itemTitle: getDecoratedTitle(_get(episode, 'seasonInfo.title'))
      }
    }

    return _isEmpty(episode.orderInfo) ? Promise.resolve(episode) : redirect(redirectUrl)
  },

  /**
   * Retrieve correct URL from cover/banner based on a specific format
   * @param item
   * @param format
   * @returns {null}
   */
  getCoverUrl (item, format) {
    const images = _get(item, 'images', [])
    const covers = _get(item, 'covers', [])
    const image = [...images, ...covers].find(item => item.format === format)
    return image ? image.url : null
  },

  isLocationExpired (store, itemId) {
    const isArticle = store.getters['user/isArticle'](itemId)
    const playHistory = store.getters['user/findPlayHistoryById'](itemId)
    return !isArticle && !!playHistory
  }
}
